import {RouteConfig} from 'vue-router';
import {klipIndex} from '@/router/router';
import {UserData} from '@/app/shared/state/UserDataModule';
import {PaymentTypes} from '@/api/klip-api.proxy';
import {ShoppingCart} from '@/app/shared/state/ShoppingCartModule';
import {PaymentStatus} from '@/app/shared/state/PaymentStatusModule';
import {mriShoppingCart} from '@/app/mri/mri-routes';
import {Prepaid} from '@/app/prepaid/prepaid-routes';

export const Checkout: RouteConfig = {
    path: '/checkout',
    name: 'checkout',
    component: () => import('./checkout.vue'),
    meta: {
        authorize: {roles: ['mri', 'mrr']},
        breadcrumb: {title: 'Afrekenen', parent: klipIndex},
    },
    beforeEnter: (to, from, next) => {
        if (UserData.user.paymentType !== PaymentTypes.Invoice) {
            if (ShoppingCart.hasInvalidItems) {
                next(mriShoppingCart);
            } else {
                next();
            }
        } else {
            next(false);
        }
    },
};

export const CheckoutPrepaid: RouteConfig = {
    path: '/checkoutprepaid',
    name: 'checkoutprepaid',
    component: () => import('./checkout.vue'),
    meta: {
        authorize: {roles: ['mri', 'mrr']},
        breadcrumb: {title: 'Afrekenen', parent: klipIndex},
    },
    beforeEnter: (to, from, next) => {
        if (UserData.user.paymentType !== PaymentTypes.Invoice) {
            if (PaymentStatus.hasPrepaidAmount) {
                next();
            } else {
                next(Prepaid);
            }
        } else {
            next(false);
        }
    },
};

export const ProcessingShoppingCart: RouteConfig = {
    path: '/processing/shoppingcart/:shoppingcartId',
    name: 'processingShoppingCart',
    component: () => import('./processing.vue'),
    meta: {
        authorize: {roles: ['mri']},
        breadcrumb: {title: 'Afrekenen', parent: klipIndex},
    },
};

export const ProcessingTopUp: RouteConfig = {
    path: '/processing/topup/',
    name: 'processingTopUp',
    component: () => import('./processing.vue'),
    meta: {
        authorize: {roles: ['mri']},
        breadcrumb: {title: 'Afrekenen', parent: klipIndex},
    },
};

export const Payment: RouteConfig = {
    path: '/payment',
    name: 'payment',
    component: () => import('./payment.vue'),
    meta: {
        authorize: {roles: ['mri']},
        breadcrumb: {title: 'payment', parent: klipIndex},
    },
};

export default [
    Checkout,
    CheckoutPrepaid,
    Payment,
    ProcessingShoppingCart,
    ProcessingTopUp,
] as RouteConfig[];
