import Vue from 'vue';
import { UserData } from '@/app/shared/state/UserDataModule';

export default {
    install(vue: typeof Vue): void {
        vue.prototype.$hasPermission = (roles: string[]): boolean => UserData.hasRole(roles);
    },
};

export function usePermission() {
    return {
        hasPermission: (roles: string[]): boolean => UserData.hasRole(roles),
    }
}
