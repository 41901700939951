import {defineComponent, toRefs, ref, computed, watch, onMounted} from "vue";
import {Mri} from "@/app/mri/shared/services/services";
import {escape} from "html-escaper";

export default defineComponent({
    props: {
        searchFields: {
            type: Object,
            required: false
        },
        defaultSearchInvoiceOption: {
            type: String,
            required: true
        },
        isCitizen: {
            type: Boolean,
            required: false,
            default: false
        },
        isOperationsMode: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    setup(props, ctx) {
        const {searchFields, defaultSearchInvoiceOption, isCitizen} =
            toRefs(props);

        const defaultSearchInvoiceYear = ref<number>(new Date().getFullYear());
        const searchInvoiceOption = ref<string>(null);
        const searchInvoiceYear = ref<number>(null);
        const searchInvoiceYears = ref<number[]>([]);

        const groupedSearchInvoiceOptions = computed(() => {
            return searchFields.value.groupedSearchInvoiceOptions;
        });

        const searchInvoiceOptions = computed(() => {
            return searchFields.value.searchInvoiceOptions;
        });

        const showSearchInvoiceOptions = computed((): boolean => {
            if (props.isOperationsMode) {
                return false;
            }

            if (isCitizen.value) {
                return false;
            }

            return true;
        });

        const watchFields = computed(() => {
            return [searchInvoiceOption.value, searchInvoiceYear.value];
        });

        const onFieldChange = () => {
            search();
        };

        const setDepth = (depth: number, name: string) => {
            let left = "";
            for (let index = 0; index < depth; index++) {
                left += "&nbsp; ";
            }
            return (left += escape(name));
        };

        const search = () => {
            const emitSearch: Mri.IInvoicesSearchFields = {
                selectedSearchInvoiceOption: searchInvoiceOption.value,
                groupedSearchInvoiceOptions:
                searchFields.value.groupedSearchInvoiceOptions,
                searchInvoiceOptions: searchFields.value.searchInvoiceOptions,
                date: {
                    from: new Date(searchInvoiceYear.value, 0, 1),
                    to: new Date(searchInvoiceYear.value, 11, 31),
                },
            };
            ctx.emit("search", emitSearch);
        };

        watch(watchFields, onFieldChange, {immediate: false, deep: true});

        onMounted(() => {
            searchInvoiceYears.value = [];

            for (let i = defaultSearchInvoiceYear.value; i > defaultSearchInvoiceYear.value - 10; i--) {
                searchInvoiceYears.value.push(i);
            }

            searchInvoiceOption.value = defaultSearchInvoiceOption.value;
            searchInvoiceYear.value = defaultSearchInvoiceYear.value;
        });

        return {
            defaultSearchInvoiceYear,
            searchInvoiceOption,
            searchInvoiceYear,
            searchInvoiceYears,
            groupedSearchInvoiceOptions,
            searchInvoiceOptions,
            showSearchInvoiceOptions,
            watchFields,
            onFieldChange,
            setDepth,
            search,
        };
    },
});