import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        title: {
            type: String,
            default: "",
            required: true
        },
        message: {
            type: String,
            default: "",
            required: true
        }
    },
    setup(props) {
        
        return {};
    }
});