import { computed, defineComponent, inject, onBeforeMount, onMounted, ref } from 'vue';
import * as KlipApi from '@/api/klip-api.proxy';
import { pdaIndex } from '@/app/pda/pda-routes';
import { IUnaMessage } from '../kl-maprequest-una-table/components/kl-una-message/kl-una-message';
import { KlRouteConfig } from '@/router/router-vue';
import { IMetaHandler } from '@/plugins/meta';
import { useRoute, useRouter } from '@/plugins/routes';
import QueryFilter from '../../helpers/queryFilter';

export default defineComponent({
    name: 'KlPdaZone',
    props: {
        organisationId: {
            type: String,
            default: null,
            required: false
        },
        parentOrganisationId: {
            type: String,
            default: null,
            required: true
        },
        maprequestDetailRoute: {
            type: Object as () => KlRouteConfig,
            default: null,
            required: true
        },
    },
    setup(props) {
        const meta = inject<IMetaHandler>('meta');
        const proxy = inject<KlipApi.IKlipApiProxy>('klipApiProxy');
        const route = useRoute();
        const router = useRouter();

        const zoneId = ref<string>('');
        const zone = ref<KlipApi.ZoneDetails>(null);

        const tabToLoad = ref<number>(-1);
        const unaZonesTabId = 'klb';
        const maprequestsTabId = 'maprequests'
        const activeTab = ref<string>(unaZonesTabId);
        const tabs = [unaZonesTabId, maprequestsTabId];

        const organisationIdValue = ref<string>(props.organisationId);
        const parentOrganisationIdValue = ref<string>(props.parentOrganisationId);

        const queryFilter = new QueryFilter();

        const zoneDetailView = ref<KlipApi.ZoneDetailView>(null);

        const routePdaIndex = computed((): KlRouteConfig => {
            return pdaIndex;
        });

        const routePdaMapRequest = computed((): KlRouteConfig => {
            return props.maprequestDetailRoute;
        });

        onBeforeMount(async () => {
            meta.setPageTitle('Zone detail');
        });

        onMounted(async () => {
            zoneId.value = route.params.zoneId;

            activeTab.value = unaZonesTabId;
            tabToLoad.value = tabs.indexOf(unaZonesTabId);

            fetchZone(zoneId.value);
        });

        const fetchZone = async (zoneId: string) => {
            const response = await proxy.pda_GetZoneDetailView(zoneId);

            if (!response.result) {
                return;
            }

            if (!response.result.zoneDetails || response.result.zoneDetails.isArchived) {
                await router.replace(routePdaIndex.value);
            } else {
                zone.value = response.result.zoneDetails;
                meta.setPageTitle(zone.value.name);

                zoneDetailView.value = response.result;
            }
        }

        const tabChange = (tab: { id?: string }) => {
            activeTab.value = tab.id;

            queryFilter.removeQuery(route);
        }

        const unaZonesLoaded = computed(() => {
            return activeTab.value == unaZonesTabId;
        });

        const maprequestsLoaded = computed(() => {
            return activeTab.value == maprequestsTabId
        });

        return {
            zone,
            zoneId,
            routePdaIndex,
            routePdaMapRequest,
            tabChange,
            tabToLoad,
            unaZonesTabId,
            maprequestsTabId,
            unaZonesLoaded,
            maprequestsLoaded,
            zoneDetailView,
            organisationIdValue,
            parentOrganisationIdValue
        };
    }
})
