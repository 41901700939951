import { defineComponent, inject, onMounted, ref, watch } from 'vue';
import * as KlipApi from '@/api/klip-api.proxy';
import KlSearchUsers, { UserSearchField } from "@/app/admin/components/kl-search-users/kl-search-users";
import QueryFilter from "@/app/shared/helpers/queryFilter";
import { useRoute, useRouter } from "@/plugins/routes";
import dayjs from "dayjs";
import { isEmpty } from "lodash-es";
import { AdminUserDetail } from "@/app/admin/admin-routes";

export default defineComponent({
    props: {
        organisation: {
            type: KlipApi.OrganisationDetail,
            default: null,
            required: true
        }
    },
    setup(props) {
        const proxy = inject<KlipApi.IKlipApiProxy>('klipApiProxy');
        const route = useRoute();
        const queryFilter = new QueryFilter();
        const searchUsers = ref<KlSearchUsers>(null);
        let initialMappingFromQuery: boolean = true;
        const cellParser = (row: IRow, column: IColumn) => {
            const routeParams = { organisationId: row.organisationId, userId: row.userId };
            switch (column.key) {
                case 'lastActiveAt':
                    const lastActiveAt = !!row[column.key] ? dayjs(row[column.key]).format('DD/MM/YYYY - HHumm') : '&nbsp;';
                    return {
                        template: `<kl-router-link :to='${JSON.stringify(AdminUserDetail)}' :params='${JSON.stringify(routeParams)}'>${lastActiveAt}</kl-router-link>`
                    };
                default:
                    const cellValue = !!row[column.key] ? row[column.key] : '&nbsp;';
                    return {
                        template: `<kl-router-link :to='${JSON.stringify(AdminUserDetail)}' :params='${JSON.stringify(routeParams)}'>${cellValue}</kl-router-link>`
                    };
            }
        };

        const usersColumns = ref<IColumn[]>([
            {
                key: 'firstName',
                label: 'Voornaam',
                sortable: true,
                isVisible: true,
                parser: cellParser
            },
            {
                key: 'lastName',
                label: 'Naam',
                sortable: true,
                isVisible: true,
                parser: cellParser
            },
            {
                key: 'types',
                label: 'Type(s)',
                width: 10,
                sortable: false,
                isVisible: true,
                parser: cellParser
            },
            {
                key: 'source',
                label: 'Source',
                width: 10,
                sortable: false,
                isVisible: true,
                parser: cellParser
            },
            {
                key: 'lastActiveAt',
                label: 'Laatst actief',
                width: 15,
                sortable: true,
                isVisible: true,
                parser: cellParser
            },
        ]);

        const organisationId = ref<string>('');
        const usersMeta = ref<{ totalRows: number, resultsPerPage: number, currentPage: number | undefined }>({ totalRows: 0, resultsPerPage: 50, currentPage: 1 });
        const usersFetching = ref<boolean>(true);
        const userSearchInput = ref<KlipApi.IGetUsersInput>(null);
        const users = ref<KlipApi.UserListItem[]>([]);
        const defaultFilter = ref<UserSearchField>(new UserSearchField());

        const search = (search: UserSearchField) => {
            if(!initialMappingFromQuery){
                userSearchInput.value = {
                    offset: search.offset > 0 ? search.offset : 0,
                    limit: usersMeta.value.resultsPerPage,
                    firstName: search.firstName,
                    lastName: search.lastName,
                    organisationId: organisationId.value,
                    inActiveUsers: search.inActiveUsers,
                    types: isEmpty(search.roles) ? [] : search.roles,
                    orderDirection: KlipApi.OrderDirection.Asc,
                    orderField: 'lastName'
                }
                if (search.offset > 0) {
                    usersMeta.value.currentPage = (search.offset / usersMeta.value.resultsPerPage) + 1;
                } else {
                    usersMeta.value.currentPage = 1;
                }
                mapFilterToQuerystring();
            }
            initialMappingFromQuery = false;
        };

        const clearSearch = () => {
            searchUsers.value.reset();
        }

        const onUserSearchInputChanged = async () => {
            if (!userSearchInput.value) {
                return;
            }
            usersFetching.value = true;
            await loadUsers();
            usersFetching.value = false;
        };

        const onOrganisationChanged = (organisation: KlipApi.OrganisationDetail) => {
            organisationId.value = organisation.organisationId;
            userSearchInput.value = {
                offset: 0,
                limit: usersMeta.value.resultsPerPage,
                firstName: '',
                lastName: '',
                organisationId: organisationId.value,
                inActiveUsers: false,
                types: [],
                orderDirection: KlipApi.OrderDirection.Asc,
                orderField: 'lastName'
            }
        };

        const loadUsers = async () => {
            const input = new KlipApi.GetUsersInput(userSearchInput.value);
            const countResponse = await proxy.operations_GetUsersCount(input);
            const searchResultResponse = await proxy.operations_GetUsers(input);
            usersMeta.value.totalRows = countResponse.result;
            users.value = searchResultResponse.result;
        };

        const pagerClicked = (page: number) => {
            userSearchInput.value.offset = usersMeta.value.resultsPerPage * (page - 1);
            mapFilterToQuerystring();
        };

        const columnClicked = (column: KlipApi.IColumn) => {
            userSearchInput.value.offset = 0;
            userSearchInput.value.orderField = column.key.toLowerCase();
            userSearchInput.value.orderDirection = column.direction;
            mapFilterToQuerystring();
        };

        const parseDefaultFilterFromQuerystring = () => {
            const queryTypeDefinition = {
                firstName: 'string | undefined',
                lastName: 'string | undefined',
                organisation: 'string | undefined',
                roles: 'string[] | undefined',
                inActiveUsers: 'boolean',
                offset: 'number',
            };

            const filterFromQuery = queryFilter.mapFilterFromQuery(route, defaultFilter.value, { inActiveUsers: false, offset: 0, organisationId: organisationId.value }, queryTypeDefinition) as UserSearchField;
            if (!!filterFromQuery) {
                defaultFilter.value = filterFromQuery;
            }
        };

        const mapFilterToQuerystring = () => {
            queryFilter.mapQuery(route, {
                firstName: userSearchInput.value.firstName,
                lastName: userSearchInput.value.lastName,
                organisationId: organisationId.value,
                types: userSearchInput.value.types,
                inActiveUsers: userSearchInput.value.inActiveUsers,
                offset: userSearchInput.value.offset,
            });
        };


        watch(userSearchInput, onUserSearchInputChanged, { immediate: true, deep: true });
        watch(props.organisation, onOrganisationChanged, { immediate: true, deep: true });

        onMounted(() => {
            parseDefaultFilterFromQuerystring();
        });

        return {
            usersColumns,
            usersMeta,
            users,
            usersFetching,
            searchUsers,
            defaultFilter,
            clearSearch,
            search,
            pagerClicked,
            columnClicked
        }
    }
});
