import {computed, defineComponent, getCurrentInstance, inject, onMounted, ref} from "vue";
import * as KlipApi from "@/api/klip-api.proxy";
import { IAlertNotification } from '@/api/klip-api.proxy';
import dayjs from 'dayjs';
import { IpproDatatable } from "@/app/shared/components/ippro-datatable/ippro-datatable-declare";
import DateUtil from '@/app/shared/helpers/date-util';

export interface IMessageTypeMetaData {
    type: KlipApi.MessageType;
    translation: string,
    icon: string,
    modType: string,
    isClosable: boolean
}

export default defineComponent({
    setup() {
        const root = getCurrentInstance().proxy;
        const proxy = inject<KlipApi.IKlipApiProxy>('klipApiProxy');
        const messageModalId = ref<string>('message-modal');

        const saving = ref<boolean>(false);
        const deleting = ref<boolean>(false);
        const isDataTableLoading = ref<boolean>(true);

        const messages = ref<KlipApi.MessageDto[]>(null);
        const message = ref<KlipApi.UpdateMessageRequest & {startDates: string[], endDates: string[]}>(null);
        const meta = ref<{ totalRows: number, resultsPerPage: number, currentPage: number | undefined }>({ totalRows: 1, resultsPerPage: 1, currentPage: 1 });
        const today = dayjs().format('DD/MM/YYYY');
        const activationMinDate = ref<string>(today);

        const typeMetadata: Record<KlipApi.MessageType, IMessageTypeMetaData> = {
            "Announcement": {
                type: KlipApi.MessageType.Announcement,
                translation: "Aankondiging",
                icon: "info-circle",
                modType: "mod-success",
                isClosable: true
            },
            "Warning":      {
                type: KlipApi.MessageType.Warning,
                translation: "Opgelet",
                icon: "warning",
                modType: "mod-warning",
                isClosable: false
            },
            "WhatsNew":     {
                type: KlipApi.MessageType.WhatsNew,
                translation: "Nieuw",
                icon: null,
                modType: null,
                isClosable: false
            }
        };

        const cellParser = (row: IRow, column: IColumn) => {
            let rowTemplate: string = `<div>${!!row[column.key] ? row[column.key] : ''}</div>`;
            switch (column.key) {
                case 'type': {
                    rowTemplate = `<div>${typeMetadata[row.type].translation}</div>`;
                    break;
                }
                case 'period': {
                    if (!!row.endTimestamp) {
                        rowTemplate = `<div>${DateUtil.formatDate(row.startTimestamp)} tot en met ${DateUtil.formatDate(row.endTimestamp)}</div>`;
                    } else {
                        rowTemplate = `<div>${DateUtil.formatDate(row.startTimestamp)}</div>`;
                    }

                    break;
                }
            }

            return {
                template: rowTemplate,
            };
        };

        const columns = ref<IColumn[]>([
            {
                key: 'type',
                label: 'Type',
                sortable: false,
                isVisible: true,
                width: 120,
                widthUnit: "px",
                parser: cellParser
            },
            {
                key: 'title',
                label: 'Titel',
                sortable: false,
                isVisible: true,
                width: 20,
                widthUnit: "em",
                parser: cellParser
            },
            {
                key: 'text',
                label: 'Inhoud',
                sortable: false,
                isVisible: true,
                width: 35,
                widthUnit: "em",
                parser: cellParser
            },
            {
                key: 'period',
                label: 'Periode',
                sortable: false,
                isVisible: true,
                parser: cellParser,
                width: 150,
                widthUnit: "px"
            }
        ]);

        const isNewMessage = computed(() => {
            return !message.value.messageId  || message.value.messageId <= 0;
        });

        const isWhatsNew = computed(() => {
            return message.value && message.value.type === KlipApi.MessageType.WhatsNew;
        });

        const isHeaderMessage = computed(() => {
            return message.value && (message.value.type === KlipApi.MessageType.Announcement || message.value.type === KlipApi.MessageType.Warning);
        });

        const headerMessages = computed(() => {
            if (!isHeaderMessage.value) return [];

            return [
                <IAlertNotification> {
                    key: message.value.messageId,
                    icon: typeMetadata[message.value.type].icon,
                    isClosable: typeMetadata[message.value.type].isClosable,
                    type: typeMetadata[message.value.type].modType,
                    title: message.value.title,
                    message: message.value.text
                }
            ];
        });

        const loadMessages = async () => {
            const response = await proxy.operations_Messages();
            if(response.isSuccessStatusCode){
                messages.value = response.result;

                meta.value.currentPage = 1;
                meta.value.resultsPerPage = response.result.length;
                meta.value.totalRows = response.result.length;

                isDataTableLoading.value = false;
            }
        };

        const rowClicked = async (row: IpproDatatable.IRow) => {
            message.value = <KlipApi.UpdateMessageRequest & {startDates: string[], endDates: string[]}> {
                messageId: row.messageId,
                type: row.type,
                startTimestamp: row.startTimestamp,
                startDates: [DateUtil.formatDate(row.startTimestamp)],
                endTimestamp: row.endTimestamp,
                endDates: !!row.endTimestamp ? [DateUtil.formatDate(row.endTimestamp)] : [],
                title: row.title,
                text: row.text
            };
            root.$vnode.context.$root.$emit('modal-toggle', messageModalId.value);
        };

        const newMessage = async () => {
            resetMessage();
            root.$vnode.context.$root.$emit('modal-toggle', messageModalId.value);
        };

        const onCloseMessageModal = () => {
            resetMessage();
        };

        const resetMessage = () => {
            message.value = <KlipApi.UpdateMessageRequest & {startDates: string[], endDates: string[]}> {
                messageId: null,
                type: KlipApi.MessageType.Announcement,
                startTimestamp: today,
                startDates: [today],
                endTimestamp: null,
                endDates: null,
                title: null,
                text: null
            }
        };

        const saveMessage = async () => {
            saving.value = true;
            message.value.startTimestamp = dayjs(message.value.startDates[0]).format('YYYY-MM-DD 00:00:00');
            message.value.endTimestamp = !!message.value.endDates && message.value.endDates.length > 0 ? dayjs(message.value.endDates[0]).format('YYYY-MM-DD 00:00:00') : null;
            await proxy.operations_UpdateMessage(message.value);
            saving.value = false;

            root.$vnode.context.$root.$emit('modal-toggle', messageModalId.value);

            loadMessages();
            resetMessage();
        };

        const deleteMessage = async () => {
            deleting.value = true;
            await proxy.operations_DeleteMessage(message.value.messageId);
            deleting.value = false;

            root.$vnode.context.$root.$emit('modal-toggle', messageModalId.value);

            loadMessages();
            resetMessage();
        };

        onMounted(async () => {
            resetMessage();
            await loadMessages();
        });

        return {
            isDataTableLoading,
            saving,
            cellParser,
            columns,
            meta,
            messages,
            message,
            activationMinDate,
            onCloseMessageModal,
            rowClicked,
            messageModalId,
            typeMetadata,
            newMessage,
            saveMessage,
            deleting,
            deleteMessage,
            isNewMessage,
            isWhatsNew,
            isHeaderMessage,
            headerMessages
        };
    }
});
