import dayjs from 'dayjs';
import 'dayjs/locale/nl-be';
import { CodeLists } from '@/app/shared/state/CodeListModule';

export default class DateUtil {
    private static dateFormat: string = 'DD/MM/YYYY';

    public static formatDate(date: any): string {
        return dayjs(date).format(DateUtil.dateFormat);
    }

    public static dateStringFromDatePicker(date: Date): string {
        return dayjs(date).format('YYYY-MM-DD 00:00:00');
    }

    public static periodStringFromDates(startDate: string, endDate: string): string {
        return `${startDate ? `van ${startDate}` : ''}
                ${startDate && endDate ? ' ' : ''}
                ${endDate ? `tot ${endDate}` : ''}`;
    }

    public static getWorkingDayByCountAndHolidays(startDate: dayjs.Dayjs, count: number) {
        if (CodeLists && CodeLists.holidays) {
            let businessDaysCount = 0;
            const holidays = CodeLists.holidays.map((holiday) => {
                return dayjs(holiday).format(DateUtil.dateFormat);
            });

            let date = startDate.clone();

            const holidaysList: string[] = [];

            while (businessDaysCount < count) {
                if (DateUtil.isBusinessDay(date, holidays)) {
                    businessDaysCount++;
                }
                if (DateUtil.isHolidayAndNotWeekendDay(date, holidays)) {
                    holidaysList.push(date.locale('nl-be').format('D MMMM'));
                }
                date = date.add(1, 'day');
            }

            return {
                workingDay: date,
                holidays: holidaysList,
             };
        }
        return null;
    }

    public static businessDaysBetween(startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) {
        if (CodeLists) {
            const holidays = CodeLists.holidays.map((holiday) => {
                return dayjs(holiday).format(DateUtil.dateFormat);
            });
            return DateUtil.businessAndHolidaysBetween(endDate, startDate, holidays);
        }
    }

    private static businessAndHolidaysBetween(endDate: dayjs.Dayjs, startDate: dayjs.Dayjs, holidays: string[]) {
        const clonedEndDate = endDate.clone();
        const clonedStartDate = startDate.clone();

        let start = clonedEndDate < clonedStartDate ? clonedEndDate : clonedStartDate;
        const end = clonedStartDate > clonedEndDate ? clonedStartDate : clonedEndDate;

        let daysBetween = 0;

        if (start.format(DateUtil.dateFormat) === end.format(DateUtil.dateFormat)) {
            return daysBetween;
        }

        while (start < end) {
            if (DateUtil.isBusinessDay(start, holidays)) {
                daysBetween++;
            }
            start = start.add(1, 'day');
        }

        return daysBetween;
    }

    private static isBusinessDay(date: dayjs.Dayjs, holidays: string[]) {
        const defaultWorkingWeekdays = [1, 2, 3, 4, 5];
        const isHoliday = holidays.indexOf(date.format(DateUtil.dateFormat)) >= 0;

        if (isHoliday) {
            return false;
        }

        return defaultWorkingWeekdays.indexOf(date.day()) >= 0;
    }

    private static isHolidayAndNotWeekendDay(date: dayjs.Dayjs, holidays: string[]) {
        const defaultWeekendDays = [0, 6];

        const isWeekendDay = defaultWeekendDays.indexOf(date.day()) >= 0;

        if (isWeekendDay) {
            return false;
        }

        return holidays.indexOf(date.format(DateUtil.dateFormat)) >= 0;
    }
}
