import {Component, Vue} from "vue-property-decorator";
import {UserData} from "../../state/UserDataModule";
import {ShoppingCart} from "../../state/ShoppingCartModule";
import {CodeLists} from "../../state/CodeListModule";
import {ProcessingTopUp, ProcessingShoppingCart} from "@/app/checkout/checkout-routes";
import {ErrorModule} from "@/app/shared/state/ErrorModule";

@Component
export default class FunctionalHeader extends Vue {
    get hasErrors(): boolean {
        return ErrorModule.hasErrors || (!!UserData.user && (UserData.user.roleError || !!UserData.user.validationError));
    }

    get isUpdating(): boolean {
        return UserData.isUpdating;
    }

    get loginLink(): string {
        const useAcmAuthentication =
            import.meta.env.VITE_ACM_AUTHENTICATION === "true";
        return useAcmAuthentication ? "auth/login" : "geosecure_auth/login";
    }

    get isLoggedOn(): boolean {
        return UserData.loggedOn;
    }

    get isAdmin(): boolean {
        return UserData.hasRole(["admin", "helpdesk"]);
    }

    get showAdminNavigation(): boolean {
        return this.isAdmin && this.$route.path.startsWith("/admin");
    }

    get numberItemsInCart(): number {
        if (!this.isLoggedOn) {
            return 0;
        }
        return ShoppingCart.numberOfItems;
    }

    get functionalHeaderClassName(): string {
        const classList = ["kl-functional-header"];

        if (CodeLists.isBeta) {
            classList.push("beta");
        }
        if (!this.showBreadcumbNavigation && !this.showAdminNavigation) {
            classList.push("kl-functional-header--right");
        }

        return classList.join(" ");
    }

    get shoppingCartActive(): boolean {
        if (this.hasErrors) {
            return false;
        }
        if (!this.isLoggedOn) {
            return false;
        }
        if (this.$route.name === ProcessingTopUp.name || this.$route.name === ProcessingShoppingCart.name) {
            return false;
        }
        return ShoppingCart.isShoppingCartActive;
    }

    get showBreadcumbNavigation(): boolean {
        if (this.hasErrors) {
            return false;
        }
        if (!this.isLoggedOn) {
            return true;
        }
        return !this.showAdminNavigation
    }

    public navigateToShoppingCart() {
        this.$router.push({name: "mrishoppingcart"});
    }
}
