
import { Component, Prop, Vue } from 'vue-property-decorator';
import { klipIndex } from '@/router/router';

@Component
export default class KlMiniCart extends Vue {
  @Prop({ type: Number, required: true, default: 0 })
  public items: number;

  get routeKlipIndex() {
    return klipIndex;
  }

  get classes() {
    return ['kl-mini-cart'];
  }

  get badgeClasses() {
    return ['kl-mini-cart__badge'];
  }

  get iconClasses() {
    return [
      'kl-mini-cart__icon', {
        'kl-mini-cart__icon--empty': this.items < 1,
      },
    ];
  }
}
