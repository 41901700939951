import IpproPagination from "./ippro-pagination/ippro-pagination.vue";
import {IpproDatatable} from "./ippro-datatable-declare";
import {computed, defineComponent, ref, watch} from 'vue';

const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base"
});

export default defineComponent({
    name: 'IpproDatatable',
    emits: ['pager-clicked', 'checkbox-change', 'row-clicked', 'column-clicked'],
    props: {
        dataset: {
            type: Array,
            required: true,
        },
        modNoResults: {
            type: Boolean,
        },
        columns: {
            type: Array as () => IpproDatatable.IColumn[],
            required: true,
        },
        meta: {
            type: Object as () => {
                totalRows: number,
                resultsPerPage: number,
                currentPage: number,
            },
            required: true,
        },
        fetching: {
            type: Boolean,
            default: true,
        },
        modZebra: {
            type: Boolean,
            default: false,
        },
        modClickableRows: {
            type: Boolean,
            default: false,
        },
        modCheckableRows: {
            type: Boolean,
            default: false,
        },
        modCheckableMulti: {
            type: Boolean,
            default: true,
        },
        modHideHeader: {
            type: Boolean,
            default: false,
        },
        modHidePagination: {
            type: Boolean,
            default: false,
        },
        modPaginationOnTop: {
            type: Boolean,
            default: false,
        },
        modSmall: {
            type: Boolean,
            default: false,
        },
        modSticky: {
            type: Boolean,
            default: false,
        },
        modStickyHeader: {
            type: Boolean,
            default: false,
        },
        columnOnRowClick: {
            type: Boolean,
            default: false,
        },
        locale: {
            type: String,
            default: 'nl',
            required: false,
        },
    },
    components: {
        IpproPagination
    },
    setup(props, {emit, slots}) {

        const currentPage = ref(1);
        const rows = ref<IpproDatatable.IRow[]>([]);
        const totalRows = ref(0);
        const totalPages = ref(1);
        const masterCheckbox = ref(false);

        // const records: any[] = []; // this.dataset;  // TODO!!!
        const records = ref([]);

        const classes = computed(() => {
            return [
                "ippro-datatable",
                {
                    "ippro-datatable--placeholder":
                        props.fetching || (rows.value.length == 0 && props.modNoResults),
                    "ippro-datatable--small": props.modSmall
                }
            ];
        });

        const showMasterCheckbox = computed(() => {
            return props.modCheckableRows && props.modCheckableMulti;
        });

        const cellClasses = (column: IpproDatatable.IColumn) => {
            if (props.modSticky && column.sticky) {
                return "vl-data-table__cell--sticky";
            }
            return null;
        };

        const cellStyle = (column: IpproDatatable.IColumn) => {
            return props.modHideHeader
                ? {
                    width: column.width
                        ? `${column.width}${column.widthUnit ? column.widthUnit : "%"}`
                        : null
                }
                : null;
        };

        const headerStyle = (column: IpproDatatable.IColumn) => {
            return {
                width: column.width
                    ? `${column.width}${column.widthUnit ? column.widthUnit : "%"}`
                    : null
            };
        };

        const headerClass = (column: IpproDatatable.IColumn) => {
            if (column.sortable && column.direction) {
                return "selected-label-header";
            }
            return null;
        };

        /**
         * Defines if sorting/paging is handled
         * clientside or should request data serverside
         */
        const serverSideData = computed(() => {
            return props.meta && props.meta.totalRows
                ? records.value.length !== props.meta.totalRows
                : false;
        });

        /**
         * How many empty rows are shown
         * in loading state
         */
        const placeholderRows = computed(() => {
            return rows.value.length ? rows.value.length : props.meta.resultsPerPage;
        });

        const placeholderColspan = computed(() => {
            return (
                (props.columns || []).filter(column => column.isVisible).length +
                Number(props.modCheckableRows)
            );
        });

        const spinnerPosition = computed(() => {
            return placeholderRows.value > 4 ? 4 : 1;
        });

        const hasActions = computed(() => {
            return !!slots.actions;
        });

        const hasTopActions = computed(() => {
            return !!slots.topactions;
        });

        const resultsPerPage = computed(() => {
            return props.meta.resultsPerPage || 999999999;
        });

        const placeholder = computed(() => {
            return props.fetching ? "vl-loader" : "";
        });

        const checkedRows = computed(() => {
            return props.modCheckableRows && rows.value
                ? rows.value.filter(row => row.checked)
                : null;
        });

        const cellClicked = (row: IpproDatatable.IRow, column: IpproDatatable.IColumn) => {
            if (props.modClickableRows) {
                emit('row-clicked', props.columnOnRowClick ? {row, column} : row);
            }
        }

        const rowClasses = (row: IpproDatatable.IRow) => {
            return [
                "ippro-datatable__row",
                {
                    "ippro-datatable__processing": row.type === "processing",
                    "ippro-datatable__row--clickable": props.modClickableRows,
                    "ippro-datatable__row--error": row.error,
                    "ippro-datatable__row--success": row.success,
                    "ippro-datatable__row--info": row.info
                },
                `${row.class ? row.class : ""}`
            ];
        }

        const disableCheckbox = (row: IpproDatatable.IRow) => {
            return row.type === "processing";
        }

        /**
         * Return a limited set of rows for
         * the requested page based on
         * the resultsPerPage setting
         * @param page
         * @param data
         */
        const _rowsForPage = (page: number, data: any[]) => {
            return data
                ? data.slice(page * resultsPerPage.value, (page + 1) * resultsPerPage.value)
                : [];
        }

        /**
         * Navigate to the requested page
         * @param page
         */
        const goToPage = (page: number) => {
            currentPage.value = page;
            emit('pager-clicked', page);
            if (!serverSideData.value) {
                rows.value = _rowsForPage(page - 1, records.value);
            }
        }

        /**
         * Get the icon according to the
         * actual sorting of type of a column
         * @param column
         */
        const sortIcon = (column: IpproDatatable.IColumn) => {
            if (column.direction) {
                return column.type === "Number"
                    ? "arrange-" + (column.direction === 1 ? "1-to-9" : "9-to-1")
                    : column.type === "String"
                        ? "arrange-" + (column.direction === 1 ? "a-to-z" : "z-to-a")
                        : column.direction === 1
                            ? "arrow-up"
                            : "arrow-down";
            }
            return "";
        }

        /**
         * Sort the datatable for a specified column
         * in the provided direction (asc, desc)
         * @param column
         */
        const sort = (column: IpproDatatable.IColumn) => {
            if (column.sortable) {
                // Update pager
                currentPage.value = 1;
                // Unset current sort order
                props.columns
                    .filter(obj => obj !== column)
                    .map(obj => (obj.direction = 0)); // TODO: we shouldn't modify properties (+ map should not be used for modifications)
                // Reverse sort order or set to asc if undefined
                // In case of dates we use desc as default sort order
                column.type === "Date"
                    ? column.direction === 2
                        ? (column.direction = 1)
                        : (column.direction = 2)
                    : column.direction === 1
                        ? (column.direction = 2)
                        : (column.direction = 1);
                // If sorting is handled clientside
                if (!serverSideData.value) {
                    // Sort by provided function, numerically or alphabetically
                    if (column.onSort instanceof Function) {
                        records.value = column.onSort(records.value, column);
                    } else {
                        records.value = records.value.sort((a, b) => {
                            return collator.compare(a[column.key], b[column.key]);
                        });
                        // Reverse in case of Desc
                        if (column.direction === 2) {
                            records.value = records.value.reverse();
                        }
                    }
                    // Limit results
                    rows.value = _rowsForPage(0, records.value);
                }
                // Emit event to hook into
                emit("column-clicked", column);
            }
        }

        /**
         * Emitted event from the pagination
         * component inside the datatable
         * @param pageNumber
         */
        const changePage = (pageNumber: number) => {
            goToPage(pageNumber);
        }

        const toggleCheckbox = (row: IpproDatatable.IRow) => {
            emit('checkbox-change', checkedRows.value, row);
        }

        const toggleCheckboxes = (checked: boolean) => {
            rows.value = rows.value.map(row => ({...row, checked: checked && row.type !== "processing"}));
            emit('checkbox-change', checkedRows.value);
        }

        /**
         * Pass the cell content to a custom
         * parser method in case it exists
         * @param row
         * @param column
         */
        const parse = (row: IpproDatatable.IRow, column: IpproDatatable.IColumn) => {
            return column.parser instanceof Function
                ? column.parser(row, column)
                : {
                    template: `<span>${row[column.key]}</span>`
                };
        }

        watch(
            checkedRows,
            (newCheckedRows) => {
                if (newCheckedRows) {
                    masterCheckbox.value = newCheckedRows.length === rows.value.length;
                }
            },
            {immediate: false, deep: false}
        );

        /**
         * Watch for changes on the dataset prop
         * @NOTE When there's less processing rows than
         * before, hide the present processing rows before
         * refreshing the rows
         * @param newDataset
         * @param oldDataset
         */
        watch(
            () => props.dataset,
            (newDataset: any[], oldDataset: any[]) => {
                const oldProcessingRows = (rows.value || []).filter(
                    item => item.type === "processing"
                ).length;
                const newRows = _rowsForPage(0, newDataset);
                const newProcessingRows = (newRows || []).filter(
                    item => item.type === "processing"
                ).length;
                const processingDiff = oldProcessingRows - newProcessingRows;
                if (processingDiff > 0) {
                    for (
                        let i = oldProcessingRows - processingDiff;
                        i < oldProcessingRows;
                        i++
                    ) {
                        rows.value[i].visible = false;
                    }
                    setTimeout(() => {
                        records.value = newDataset;
                        rows.value = newRows;
                    }, 500);
                } else {
                    records.value = newDataset;
                    rows.value = newRows;
                }
            },
            {immediate: true, deep: true}
        )

        /**
         * Watch for changes on the meta
         * prop to update pagination when the
         * record count is known
         * @param val
         */
        watch(
            () => props.meta,
            (newMeta: IpproDatatable.IMeta, oldMeta: IpproDatatable.IMeta) => {
                totalRows.value = newMeta.totalRows ? newMeta.totalRows : 0;
                totalPages.value = Math.ceil(totalRows.value / resultsPerPage.value);
                currentPage.value = props.meta.currentPage ? props.meta.currentPage : 1;
            },
            {immediate: true, deep: true}
        )

        return {
            currentPage,
            rows,
            totalRows,
            totalPages,
            masterCheckbox,
            classes,
            showMasterCheckbox,
            cellClasses,
            cellStyle,
            headerStyle,
            headerClass,
            serverSideData,
            placeholderRows,
            placeholderColspan,
            spinnerPosition,
            hasActions,
            hasTopActions,
            resultsPerPage,
            placeholder,
            checkedRows,
            cellClicked,
            rowClasses,
            disableCheckbox,
            goToPage,
            sortIcon,
            sort,
            changePage,
            toggleCheckbox,
            toggleCheckboxes,
            parse,
        }
    }
});
