import { CodeLists } from '@/app/shared/state/CodeListModule';
import {computed, defineComponent} from 'vue';

export default defineComponent({
    name: 'KlTestPaymentData',
    props: {},
    setup(props) {

        const isVisible = computed((): boolean => {
            return CodeLists && CodeLists.applicationInfo && (CodeLists.applicationInfo.environment === 'Test' || CodeLists.applicationInfo.environment === 'Beta');
        });

        return {
            isVisible,
        };
    }
})
