import KlUpload from '@/app/shared/components/kl-upload/kl-upload.vue';
import { IConfig } from '@/app/shared/components/kl-upload/config';
import {EEventType, useSignalrHub} from '@/plugins/signalr';
import { EnvelopeOfString, IGeometry, EnvelopeOfUploadZoneFile, IUploadZoneFile } from '@/api/klip-api.proxy';
import userLocalStorage from '@/storage/user-local-storage';
import {computed, defineComponent, onBeforeUnmount, onMounted, ref, watch} from 'vue';

export enum UploadStatus {
    success = 1,
    error = 2,
}

export interface IResponse {
    status: UploadStatus;
    errorMessages: string[];
    downloadUrls: { [key: string]: string };
}

export interface GeometryExtended extends IGeometry {
    isDeleted?: boolean;
}

export default defineComponent({
    name: 'KlUploadGeometry',
    components: { KlUpload },
    emits: ['input', 'revert', 'upload-status'],
    props: {
        uploadUrl: {
            type: String,
            required: true,
        },
        downloads: {
            type: Object as () => { [key: string]: string },
            default: undefined,
        },
        modBordered: {
            type: Boolean,
            default: false,
        },
        modRevertable: {
            type: Boolean,
            default: false,
        },
        config: {
            type: Object as () => IConfig,
            default: undefined,
        },
        response: {
            type: Object as () => IResponse,
            default: undefined,
        },
    },
    setup(props, { emit, attrs }) {

        const _downloadPreferenceLocalStorageKey: string = 'geometry_download_preference';
        const _geometrieUploadGuidelines = [
            {
                label: 'Aanvaarde bestandsformaten',
                value: 'GeoJSON, GML, Shapefile of WKT',
            }, {
                label: 'Maximale bestandsgrootte',
                value: '100 MB',
            }, {
                label: 'Projectie',
                value: 'Lambert 72',
            }, {
                value: 'Bevat maar 1 (multi)polygoon',
            },
        ];

        const geometryBlobName = ref<string>('');
        const geometrieUploadKey = ref<string>('');
        const isSuccess = ref<boolean>(false);
        const isError = ref<boolean>(false);
        const isProcessing = ref<boolean>(false);
        const errorMessages = ref<string[]>([]);
        const downloadUrls = ref<{ [key: string]: string }>(null);
        const downloadFiletype = ref<string>(userLocalStorage.getItem(_downloadPreferenceLocalStorageKey) || 'GeoJson');


        const processingMessage = computed(() => {
            return props.config && props.config.processingMessage ? props.config.processingMessage : 'De gegevens worden verwerkt. Even geduld, a.u.b.';
        });

        const mappedConfig = computed((): IConfig => {
            return props.config ? props.config : {
                url: _attributeMapper('url', props.uploadUrl),
                title: _attributeMapper('title'),
                uploadLabel: _attributeMapper('upload-label', 'Geometrie toevoegen'),
                uploadDragText: _attributeMapper('upload-drag-text', 'Sleep het bestand naar hier om toe te voegen'),
                guidelines: _attributeMapper('guidelines', _geometrieUploadGuidelines),
                files: {
                    amount: _attributeMapper('max-files', 1),
                    size: _attributeMapper('max-filesize', 100),
                    types: _attributeMapper('allowed-file-types', '.json,.gml,.shp,.wkt'),
                    message: _attributeMapper('max-filesize-msg', 'De grootte van het bestand mag maximaal 100 MB zijn.'),
                },
            };
        });

        const hasFile = computed(() => {
            return !!geometryBlobName.value;
        });

        const isRevertable = computed(() => {
            return !!(hasFile.value || props.modRevertable);
        });

        const downloadFileUri = computed(() => {
            return downloadUrls.value && downloadFiletype.value ? downloadUrls.value[downloadFiletype.value] : null;
        });


        watch(
            () => props.response,
            (newResponse: IResponse) => {
            if (!!newResponse) {
                switch (newResponse.status) {
                    case UploadStatus.success:
                        isSuccess.value = true;
                        isProcessing.value = false;
                        isError.value = false;
                        downloadUrls.value = newResponse.downloadUrls;
                        emit('input', geometryBlobName.value);
                        break;
                    case UploadStatus.error:
                        isSuccess.value = false;
                        isProcessing.value = false;
                        isError.value = true;
                        geometryBlobName.value = undefined;
                        errorMessages.value = newResponse.errorMessages || [];
                        break;
                    }
                }
            }
        )

        watch(
            () => attrs.value,
            () => {
                geometryBlobName.value = attrs.value as string;

                if (geometryBlobName.value) {
                    if (hasFile.value) {
                        _fetchFileStatus();
                    }
                }
            }
        )

        onMounted(() => {
            _subscribeToHub();
        });

        onBeforeUnmount(() => {
            useSignalrHub().unsubFromEvent(EEventType.ValidateAndConvertGeometryTask);
        });



        const _attributeMapper = (attributeName: string, defaultValue: any = null) => {
            return attrs[attributeName] ? attrs[attributeName] : defaultValue;
        }

        const _subscribeToHub = () => {
            useSignalrHub().subToEvent(EEventType.ValidateAndConvertGeometryTask, _processMessageResponse);
        }

        const _processMessageResponse = (message: IMessage) => {
            if (message.messageType === EEventType.ValidateAndConvertGeometryTask && message.info === geometryBlobName.value) {
                _fetchFileStatus();
            }
        }

        const reset = () => {
            isSuccess.value = false;
            isError.value = false;
            isProcessing.value = false;
            errorMessages.value = [];
            geometrieUploadKey.value = Date.now().toString();
            downloadUrls.value = null;

            emit('input', '');
            emit('revert');
        }

        const fileUploaded = () => {
            isProcessing.value = true;
        }

        const uploadSuccess = (file: object, response: EnvelopeOfUploadZoneFile) => {
            if (!response.result) {
                return;
            }
            const fileZoneResponse: IUploadZoneFile = { ...response.result };
            geometryBlobName.value = fileZoneResponse.geometryBlobName;
        }

        const uploadError = (file: object, message: EnvelopeOfString, xhr: object) => {
            if (xhr) {
                isProcessing.value = false;
                isError.value = true;
                if (message && message.messages) {
                    errorMessages.value = errorMessages.value.concat(message.messages);
                }
            }
        }

        /**
         * When the download geometry file select
         * changes, save filetype to the users preferences
         */
        const changeSelect = () => {
            userLocalStorage.setItem(_downloadPreferenceLocalStorageKey, downloadFiletype.value);
        }

        const deleteFile = () => {
            // console.log('Delete file', this.downloadFiletype);
        }

        const revert = () => {
            isSuccess.value = true; // back to state before 'replaceFile'
            emit('revert');
        }

        const replaceFile = () => {
            isSuccess.value = false; // isSuccess is supposed to be 'true', or we should not be able to press 'vervangen'
        }

        // Get File Upload Status
        const _fetchFileStatus = () => {
            emit('upload-status', geometryBlobName.value);
        }

        return {
            geometryBlobName,
            isSuccess,
            isError,
            isProcessing,
            errorMessages,
            downloadUrls,
            downloadFiletype,

            processingMessage,
            mappedConfig,
            hasFile,
            isRevertable,
            downloadFileUri,

            reset,
            fileUploaded,
            uploadSuccess,
            uploadError,
            changeSelect,
            deleteFile,
            revert,
            replaceFile,
        }
    }
})
