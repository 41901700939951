import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {defineComponent, onMounted, ref} from 'vue';

dayjs.extend(customParseFormat);

export default defineComponent({
    name: 'KlDatepicker',
    props: {
        placeholder: { type: String, default: 'dd/mm/jjjj' },
    },
    setup(props, { attrs }) {

        const flatpickr = ref(null);
        const VlDatepicker = ref(null);

        onMounted(() => {
            // fix for bug in WebComponents;
            // bug = defaultDate & v-model combination
            // => defaultDate only works when it is a string, but this will cause the v-model to be a string instead of date object when unchanged
            flatpickr.value = VlDatepicker.value.$data.flatpickr;
            flatpickr.value.setDate(attrs.value, true);
        });

        return {
            VlDatepicker,
        }
    }
})
