import DefaultKlipStore from '@/store';
import { VuexModule, Mutation, Action, Module, getModule } from 'vuex-module-decorators';

export interface IMapRequestArchive {
  id: string;
  status: string;
}

@Module({
  dynamic: true,
  store: DefaultKlipStore,
  name: MriStore.store.namespace,
  namespaced: true,
})
export default class MriStore extends VuexModule {
  static store = {
    namespace: 'MriStore',
    getters: {
      mapRequestArchives: 'mapRequestArchives',
    },
  };
  public items: IMapRequestArchive[] = [];

  @Mutation
  public MapRequestArchiveStatusMutation(mapRequestArchive: IMapRequestArchive) {
    if (this.items.filter((item) => item.id === mapRequestArchive.id).length) {
      this.items = this.items.map((item) => (item.id === mapRequestArchive.id) ? mapRequestArchive : item);
    } else {
      this.items.push(mapRequestArchive);
    }
  }

  get mapRequestArchives(): IMapRequestArchive[] {
    return this.items;
  }
}

export const MriDataService = getModule(MriStore);
