import { computed, defineComponent, inject, ref } from "vue";
import * as KlipApi from "@/api/klip-api.proxy";
import { IMailSettings } from "./mailsettings";
import { UserData } from "../../state/UserDataModule";
import { IZoneToMove } from "@/app/admin/organisation/components/kl-una-zone-move/kl-una-zone-move";
import { IAlertHandler } from '@/plugins/alert';
import { EAlertType } from '@/app/shared/state/AlertModule';
import { ErrorModule } from '@/app/shared/state/ErrorModule';

export default defineComponent({
    props: {
        zone: {
            type: KlipApi.UnaZoneEditView,
            default: null,
            required: true
        },
        mailSettings: {
            type: IMailSettings,
            default: null,
            required: true
        }
    },
    setup: function (props) {
        const unaZone = ref<KlipApi.UnaZoneEditView>(props.zone);
        const unaMailSettings = ref<IMailSettings>(props.mailSettings);
        const proxy = inject<KlipApi.IKlipApiProxy>('klipApiProxy');
        const alertHandler = inject<IAlertHandler>('alerts');

        const showMoveZone = computed((): boolean => {
            return UserData.hasRole(['admin']) && !unaZone.value.isArchived;
        });

        const onMoveZone = (zoneToMove: IZoneToMove) => {
            const request = new KlipApi.MoveZoneInput();
            request.mailTo = zoneToMove.mailTo;
            request.reason = zoneToMove.reason;
            request.targetKlipOrganisationId = zoneToMove.destinationKlipOrganisationId;
            request.zoneId = zoneToMove.zoneId;
            
            ErrorModule.setBypassError(['400', '404']);
            proxy.unaSettings_MoveZone(request)
            .then(() => {
                showSuccess();
            })
            .catch(() => {
                showFailure();
            })
            .finally(() => {
                ErrorModule.resetBypassError();
            });
        }

        const showSuccess = () => {
            const toasterId = `confirm-event-replay-toaster-${Math.random()
                .toString(36)
                .substring(7)}`;
            const message = 'De zone zal verplaatst worden.';                
            alertHandler.addAlert({
                type: EAlertType.Success,
                id: toasterId,
                title: 'Zone verplaatsen',
                message: message,
                icon: 'info-circle',
                autoClose: 5000,
                closable: true,
            });
        };

        const showFailure = () => {
            const toasterId = `confirm-event-replay-failed-toaster-${Math.random()
                .toString(36)
                .substring(7)}`;
            const message =
                'Er ging iets mis! De zone werd NIET in de queue gezet om te verplaatsen. Probeer je even opnieuw?';
            alertHandler.addAlert({
                type: EAlertType.Error,
                id: toasterId,
                title: 'Zone verplaatsen mislukt',
                message: message,
                icon: 'info-circle',
                autoClose: 5000,
                closable: true,
            });
        };

        return{
            unaZone,
            unaMailSettings,
            showMoveZone,
            onMoveZone
        }
    }
});