import { defineComponent, getCurrentInstance } from 'vue';
import { IAlertNotification } from '@/api/klip-api.proxy';

export default defineComponent({
    props: {
        messages: {
            type: Array<IAlertNotification>,
            default: "",
            required: true
        }
    },
    setup(props, ctx) {     
        const closeMessage = async (messageId: string) => {
            ctx.emit('message-closed', messageId);            
        };

        return {
            closeMessage
        };
    }
});