
import { UserDataModule } from '@/app/shared/state/UserDataModule';
import { KlRoute, KlRouteConfig } from '@/router/router-vue';
import { RawLocation, RouteConfig } from 'vue-router';
import { UserDataModuleMock } from '@/app/shared/state/__mocks__/UserDataModule';
import userLocalStorage from '@/storage/user-local-storage';

export async function auth(
    to: KlRoute | KlRouteConfig | RouteConfig,
    from: KlRoute,
    next: (to?: RawLocation) => void,
    userData: UserDataModule | UserDataModuleMock,
) {
    return Promise.resolve().then(() => {
        if (!!to.name && to.name === 'error') {
            next();
        }
        if (!userData.isAuthenticated) {
            return checkAuthentication(to, next, userData);
        }
        if (to.meta && to.meta.authorize) {
            checkAuthorization(to, next, userData);
        } else {
            next();
            if (!userData.loggedOn) {
                return userData.updateUserProfile().catch((error) => {
                    catchUpdateUserProfileError(error, next);
                });
            }
        }
    });
}

function checkAuthentication(
    to: KlRoute | RouteConfig,
    next: (to?: RawLocation) => void,
    userData: UserDataModule | UserDataModuleMock,
) {
    return userData.isUserAuthenticated().then((isAuthenticated) => {
        if (isAuthenticated.result) {
            userData.updateIsCheckingAuthentication(false);
            if (to.meta && to.meta.authorize) {
                checkAuthorization(to, next, userData);
            } else {
                next();
                if (!userData.loggedOn) {
                    return userData.updateUserProfile().catch((error) => {
                        catchUpdateUserProfileError(error, next);
                    });
                }
            }
        } else {
            const firstLogin = userLocalStorage.getItem('klip_visited');
            if (!firstLogin) {
                userData.updateIsCheckingAuthentication(false);
                next({ path: '/public', replace: true });
            } else {
                const useAcmAuthentication = import.meta.env.VITE_ACM_AUTHENTICATION === 'true';
                if (useAcmAuthentication) {
                    window.location.assign(`${window.location.origin}/auth/login?redirectUri=${to.path}`);
                } else {
                    window.location.assign(`${window.location.origin}/geosecure_auth/login?returnUrl=${to.path}`);
                }
            }
        }
    });
}

function checkAuthorization(
    to: KlRoute | RouteConfig,
    next: (to?: RawLocation) => void,
    userData: UserDataModule | UserDataModuleMock,
) {
    if (!userData.loggedOn) {
        return userData.updateUserProfile().then(() => {
            checkUserState(to, next, userData);
        }).catch((error) => {
            catchUpdateUserProfileError(error, next);
        });
    } else {
        checkUserState(to, next, userData);
    }
}

function catchUpdateUserProfileError(
    error: any,
    next: (to?: RawLocation) => void,
) {
    if (error && error.response && error.response.status === 400) {
        next({ name: '400' });
    } else {
        next({ name: 'error', replace: true });
    }
}

function checkUserState(
    to: KlRoute | RouteConfig,
    next: (to?: RawLocation) => void,
    userData: UserDataModule | UserDataModuleMock,
) {
    if (userData.loggedOn) {
        if (userData.hasRole(to.meta.authorize.roles)) {
            next();
        } else {
            next({ path: '/403', replace: true });
        }
    } else {
        const useAcmAuthentication = import.meta.env.VITE_ACM_AUTHENTICATION === 'true';
        if (window) {
            if (useAcmAuthentication) {
                window.location.assign(`${window.location.origin}/auth/login`);
            } else {
                window.location.assign(`${window.location.origin}/geosecure_auth/login?returnUrl=${to.path}`);
            }
        }
    }
}
