import store from '@/store';
import { Module, VuexModule, Mutation, Action, getModule, MutationAction } from 'vuex-module-decorators';
import * as KlipApi from '@/api/klip-api.proxy';
import { UserData } from './UserDataModule';
import { ErrorModule } from '@/app/shared/state/ErrorModule';
import router from './../../../router/router';
import {klipApiProxy} from '@/plugins/proxy-client';

@Module({
    dynamic: true,
    store,
    name: 'ShoppingCartModule',
    namespaced: true,
})
export class ShoppingCartModule extends VuexModule {

    private items: any = [];
    private totalAmount: number = 0;
    private totalPriceInclVatFormatted: string = '';
    private totalPriceExclVatFormatted: string = '';
    private totalVatFormatted: string = '';
    private vatPercentage?: number | undefined;
    private idToBeRemoved: string = '';
    private status = '';

    @Mutation
    setShoppingCartItems(value: any) {
        this.items = value ? value.sort((a: any, b: any) => Date.parse(a.dateReceived) - Date.parse(b.dateReceived)) : null;
    }

    @Mutation
    addExtraShoppingCartItems(value: KlipApi.ShoppingCartMapRequestExtra[]) {
        const createImklItem = (item: any, isIndependent: boolean) => {
             return {
                type: 'IMKL',
                id: item.id,
                delete: true,
                reference: `IMKL: ${item.reference}`,
                priceLabel: `€ ${UserData.applyVat ? item.priceFormatted : item.price}`,
                mapRequestId: item.mapRequestId,
                isIndependent,
                isAlreadyPaid: item.isAlreadyPaid,
                error: item.isAlreadyPaid,
                organisation: item.organisation,
            };
        };

        value.forEach(e => {
            const index = this.items.findIndex((m: any) => m.id === e.mapRequestId);
            if (index !== -1) {
                this.items.splice(index + 1, 0, createImklItem(e, false));
            } else {
                this.items.push(createImklItem(e, true));
            }
        });
    }

    @Mutation
    setTotalAmount(value: number) {
        this.totalAmount = value;
    }
    @Mutation
    setTotalPriceInclVatFormatted(value: string){
        this.totalPriceInclVatFormatted = value;
    }
    @Mutation
    setTotalPriceExclVatFormatted(value: string){
        this.totalPriceExclVatFormatted = value;
    }
    @Mutation
    setTotalVatFormatted(value: string){
        this.totalVatFormatted = value;
    }
    @Mutation
    setVatPercentage(value: number | undefined){
        this.vatPercentage = value;
    }
    @Mutation
    setIdToBeRemoved(value: string) {
        this.idToBeRemoved = value;
    }

    @Mutation
    updateShoppingCartMutation(status: string) {
        this.status = status;
    }

    public get numberOfItems(): number {
        return this.items.length;
    }

    public get shoppingCartItems(): any[] {
        return this.items;
    }

    public get shoppingCartTotalPriceExclVat(): string {
        return `€ ${this.totalPriceExclVatFormatted}`;
    }

    public get shoppingCartTotalPriceInclVat(): string {
        return `€ ${this.totalPriceInclVatFormatted}`;
    }

    public get shoppingCartTotalVat(): string {
        return `€ ${this.totalVatFormatted}`;
    }

    public get shoppingVatPercentage(): string {
        return `${this.vatPercentage}%`;
    }

    public get getTotalAmount(): number {
        return this.totalAmount;
    }

    public get isShoppingCartActive(): boolean {
        if (!!UserData.user && UserData.user.roles) {
            return UserData.user.roles.includes('mri') && UserData.user.isShoppingCartVisible;
        }
        return false;
    }

    public get shoppingCartStatus(): string {
        return this.status;
    }

    public get numberOfMapRequests(): number {
        return this.items.filter((item: any) => item.type === 'PA').length;
    }

    public get hasMapRequestExtras(): boolean {
        return this.items.filter((item: any) => item.isIndependent === true).length > 0;
    }

    public get hasMapRequestsWithImkl(): boolean {
        return this.items.filter((item: any) => item.isIndependent === false).length > 0;
    }

    public get hasInvalidItems(): boolean {
        return this.items.filter((item: any) => item.error === true).length > 0;
    }

    @Action
    public async updateShoppingCart() {
        if (this.isShoppingCartActive) {
            const shoppingCartStatusResponse = await klipApiProxy.payment_GetShoppingCartStatus();
            this.updateShoppingCartMutation(shoppingCartStatusResponse.result);
            if (shoppingCartStatusResponse.result === 'created' || shoppingCartStatusResponse.result === 'submitted') {
                try {
                    ErrorModule.setBypassError(['405']);
                    const shoppingCartViewResponse = await klipApiProxy.payment_GetShoppingCartView();
                    this.setShoppingCartItems(shoppingCartViewResponse.result.mapRequests.map(m => {
                        return {
                            type: 'PA',
                            dateReceived: m.dateReceived,
                            id: m.id,
                            isExpired: m.isExpired,
                            price: m.price,
                            reference: `Planaanvraag: ${m.reference}`,
                            priceLabel: `€ ${UserData.applyVat ? m.priceFormatted : m.price}`,
                            delete: true,
                            error: m.isExpired,
                            organisation: m.organisation,
                        };
                    }));

                    if (shoppingCartViewResponse.result.mapRequestExtras && shoppingCartViewResponse.result.mapRequestExtras.length > 0) {
                        this.addExtraShoppingCartItems(shoppingCartViewResponse.result.mapRequestExtras);
                    }
                    this.setTotalAmount(shoppingCartViewResponse.result.totalPriceExclVat);
                    this.setTotalPriceInclVatFormatted(shoppingCartViewResponse.result.totalPriceInclVatFormatted);
                    this.setTotalPriceExclVatFormatted(shoppingCartViewResponse.result.totalPriceExclVatFormatted);
                    this.setTotalVatFormatted(shoppingCartViewResponse.result.totalVatFormatted);
                    this.setVatPercentage(shoppingCartViewResponse.result.vatPercentage);
                } catch (error: any) {
                    if (error.response && error.response.status && error.response.status === 405) {
                        UserData.setShoppingCartInvisible();
                        this.setShoppingCartItems(null);
                        this.setTotalAmount(0);
                        ErrorModule.resetBypassError();
                    } else {
                        throw error;
                    }
                }
            }
        }
    }

    @Action
    public async removeItemFromShoppingCart() {
        if (this.idToBeRemoved && this.idToBeRemoved !== '') {
            const item = this.items.find((i: any) => i.id === this.idToBeRemoved);
            let response;
            if (item.type === 'PA') {
                response = await klipApiProxy.payment_DeleteShoppingCartMapRequest(this.idToBeRemoved);
            } else {
                response = await klipApiProxy.payment_DeleteShoppingCartMapRequestExtra(parseInt(this.idToBeRemoved));
            }
            if (response.statusCode === 200) {
                this.updateShoppingCart();
                this.setIdToBeRemoved('');

                if (!this.isShoppingCartActive) {
                    router.push({ name: 'mri' });
                }
            }
        }
    }

    @Action
    public async removeShoppingCart() {
        const response = await klipApiProxy.payment_DeleteShoppingCart();
        if (response.statusCode === 200) {
            this.updateShoppingCart();
            if (UserData.paymentType === KlipApi.PaymentTypes.Invoice) {
                UserData.setShoppingCartInvisible();
            }
            router.push({ name: 'mri' });
        }
    }
}

export const ShoppingCart = getModule(ShoppingCartModule);
