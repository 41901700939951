import DefaultKlipStore from '@/store';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import * as KlipApi from '@/api/klip-api.proxy';
import {klipApiProxy} from '@/plugins/proxy-client';

@Module({
    dynamic: true,
    store: DefaultKlipStore,
    name: SettingsStore.store.namespace,
    namespaced: true,
})
export default class SettingsStore extends VuexModule {
    static store = {
        namespace: 'SettingsStore',
        getters: {
            settingsView: 'settingsView',
            namespaceZones: 'namespaceZones',
        },
        actions: {
            fetchSettings: 'fetchSettings',
            loadNameSpaceZone: 'loadNameSpaceZone',
            deleteNamespaceZone: 'deleteNamespaceZone',
            resetNamespaceZones: 'resetNamespaceZones',
            updateNamespaceZone: 'updateNamespaceZone',
            createNamespaceZone: 'createNamespaceZone',
        },
    };
    public setting: KlipApi.ISettingsView | null = null;
    public namespaceZone: KlipApi.ZoneNamespace[] | null = null;

    private namespacesForOrganisation: Map<string, KlipApi.ZoneNamespace[]> = new Map<string, KlipApi.ZoneNamespace[]>();

    @Action
    public async fetchSettings(): Promise<void> {
        const settings = await klipApiProxy.settings_GetSettingsView();
        this.settingsViewLoaded(settings.result);
    }

    @Action
    public async loadNameSpaceZone(organisationId: string): Promise<void> {
        if (this.namespacesForOrganisation.has(organisationId)) {
            const namespaceZones = this.namespacesForOrganisation.get(organisationId);
            this.namespaceZoneLoaded(namespaceZones);
        } else {
            const zones = await klipApiProxy.organisation_LoadUnaZonesForOrganisation(organisationId, false);
            const namespaceZones = zones.result ? zones.result : [];
            this.namespacesForOrganisation.set(organisationId, namespaceZones);
            this.namespaceZoneLoaded(namespaceZones);
        }
    }

    @Action
    public async resetNamespaceZones(): Promise<void> {
        this.namespaceZoneLoaded([]);
    }

    @Action
    public async deleteNamespaceZone(deleteNamespace: { id: string, organisationId: string, unaId?: number }): Promise<void> {
        await klipApiProxy.unaSettings_DeleteKlipZoneById(deleteNamespace.id, deleteNamespace.unaId);
        this.namespaceZoneRemoved(deleteNamespace);
    }

    @Action
    public updateNamespaceZone(update: { id: string, organisationId: string, data: KlipApi.IUpdateKlimZoneInput | KlipApi.IUpdateKlipZoneInput }) {
        this.namespaceZoneUpdated(update);
    }

    @Action
    public createNamespaceZone(create: { id: string, organisationId: string, data: KlipApi.ICreateKlimZoneInput | KlipApi.ICreateKlipZoneInput, isKlim: boolean }) {
        this.namespaceZoneCreated(create);
    }

    @Mutation
    public settingsViewLoaded(settings: KlipApi.ISettingsView): void {
        this.setting = settings;
    }

    @Mutation
    public namespaceZoneLoaded(namespaces: KlipApi.ZoneNamespace[]) {
        this.namespaceZone = namespaces;
    }

    @Mutation
    public namespaceZoneRemoved(deleteNamespace: { id: string, organisationId: string }) {
        if (!this.namespaceZone) {
            return;
        }
        this.namespaceZone = this.namespaceZone.filter((item) => item.zoneId !== deleteNamespace.id);
        this.namespacesForOrganisation.set(deleteNamespace.organisationId, this.namespaceZone);

    }

    @Mutation
    public namespaceZoneUpdated(update: { id: string, organisationId: string, data: KlipApi.IUpdateKlimZoneInput | KlipApi.IUpdateKlipZoneInput }) {
        const index = this.namespaceZone.findIndex((n) => n.zoneId === update.id);
        if (index > -1) {
            this.namespaceZone[index].zoneName = update.data.name;
            this.namespaceZone[index].namespace = update.data.namespace;
            this.namespacesForOrganisation.set(update.organisationId, this.namespaceZone);

        }
    }

    @Mutation
    public namespaceZoneCreated(create: { id: string, organisationId: string, data: KlipApi.ICreateKlimZoneInput | KlipApi.ICreateKlipZoneInput, isKlim: boolean }) {
        const newNamespaceZone: KlipApi.IZoneNamespace = {
            zoneId: create.id,
            zoneName: create.data.name,
            namespace: create.data.namespace,
            isKlimZone: create.isKlim,
            unaId: -1 ,
            isArchived: false,
        };
        this.namespaceZone.push(new KlipApi.ZoneNamespace(newNamespaceZone));
        this.namespacesForOrganisation.set(create.organisationId, this.namespaceZone);

    }

    get settingsView(): KlipApi.ISettingsView {
        return this.setting;
    }

    get namespaceZones(): KlipApi.IZoneNamespace[] {
        return this.namespaceZone;
    }
}

export const SettingsDataService = getModule(SettingsStore);
