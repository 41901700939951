import { Prepaid } from '@/app/prepaid/prepaid-routes';
import { PaymentStatus } from '@/app/shared/state/PaymentStatusModule';
import {computed, defineComponent, onMounted, ref, watch} from 'vue';
import {useRouter} from '@/plugins/routes';

export default defineComponent({
    name: 'KlCheckoutPaymentMethod',
    emits: ['input'],
    props: {
        prepaidSaldo: { type: Number, required: true },
        prepaidTreshold: { type: Number, required: true },
        isPrepaidUploaded: { type: Boolean, required: true },
    },
    setup(props, { emit }) {

        const router = useRouter();

        const paymentMethod = ref<string>('');

        const prepaidDisabled = computed(() => {
            return !userHadPrepaidBefore.value || !isPrepaidSufficient.value;
        });

        const userHadPrepaidBefore = computed(() => {
            return props.isPrepaidUploaded;
        });

        const isPrepaidSufficient = computed(() => {
            return props.prepaidTreshold !== 0 && props.prepaidSaldo >= props.prepaidTreshold;
        });

        const prepaidDeficiency = computed(() => {
            if (!isPrepaidSufficient.value) {
                return Number((props.prepaidTreshold - props.prepaidSaldo).toFixed(2));
            }
            return undefined;
        });

        const routePrepaid = computed(() => {
            return Prepaid;
        });


        const addPrepaid = () => {
            PaymentStatus.setPrepaidAmount(prepaidDeficiency.value);
            router.push({ name: Prepaid.name });
        }

        watch(
            paymentMethod,
            (paymentMethod: string) => {
                emit('input', paymentMethod);
            },
            { immediate: false, deep: true });


        onMounted(() => {
            paymentMethod.value = isPrepaidSufficient.value ? (!paymentMethod.value ? 'prepaid' : paymentMethod.value) : 'card';
        });


        return {
            paymentMethod,
            prepaidDisabled,
            userHadPrepaidBefore,
            isPrepaidSufficient,
            prepaidDeficiency,
            routePrepaid,

            addPrepaid,
        }
    }
});
