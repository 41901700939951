import { HubConnectionBuilder, HubConnection } from '@microsoft/signalr';
import Vue, { getCurrentInstance } from 'vue';
import { EventEmitter } from 'events';
import { Logger, LogLevel, Impact } from '@/app/shared/service/logger';
import { retry } from 'ts-retry';
import {InvoicingLogMessage} from "@/api/klip-api.proxy";

export enum EEventType {
    CreatePdaZoneTask = 'CreatePdaZoneTask',
    UpdatePdaZoneTask = 'UpdatePdaZoneTask',
    ValidateAndConvertGeometryTask = 'ValidateAndConvertGeometryTask',
    MapRequestCreatedEvent = 'MapRequestCreatedEvent',
    ResponderRespondedNotInvolvedToMapRequestEvent = 'ResponderRespondedNotInvolvedToMapRequestEvent',
    ResponderRespondedWithImklToMapRequestEvent = 'ResponderRespondedWithImklToMapRequestEvent',
    MapRequestConfirmationReceivedEvent = 'MapRequestConfirmationReceivedEvent',
    CreateMapRequestForOverlayTask = 'CreateMapRequestForOverlayTask',
    Pmkl2PackageAvailableEvent = 'Pmkl2PackageAvailableEvent',
    ValidateImklUploadTask = 'ValidateImklUploadTask',
    ImklDataOfMapRequestOrderedEvent = 'ImklDataOfMapRequestOrderedEvent',
    ValidateTestImklUploadTask = 'imklTestStatus',
    Printstatus = 'printstatus',
    CreateMapRequestForOverlayTaskKlimResponded = 'CreateMapRequestForOverlayTaskKlimResponded',
    UtilityNetworkAuthorityRegisteredEvent = 'UtilityNetworkAuthorityRegisteredEvent'
}

export class SignalRHub {
    connection: string;
    startedPromise: any;
    signalrHub: HubConnection;
    eventEmitter: EventEmitter;
    connected: boolean = false;

    constructor(connection: string) {
        this.eventEmitter = new EventEmitter();
        this.connection = connection;
        this.startedPromise = null;
        this.signalrHub = new HubConnectionBuilder()
            .withUrl(connection)
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Error)
            .build();
    }

    start() {
        // possibility to disable signalr for local dev when using a mock server
        if (import.meta.env.VITE_DISABLE_SIGNALR) {
            console.log('SignalR disabled by VITE_DISABLE_SIGNALR');
            return;
        }
        this.startedPromise = this.signalrHub.start()
            .then(() => {
                this.connected = true;
                this.eventEmitter.emit('connected');
            }).catch(async () => {
                await this.retryConnectionStart();
            });

        return this.startedPromise;
    }

    async retryConnectionStart() {
        try {
            //soms loopt de eerste connectie met signalR mis, dus proberen we eerst nog eens opnieuw.
            await retry(
                () => {
                    this.start();
                },
                { delay: 2000, maxTry: 3 }
            );
        } catch (reason) {
            if (!!reason) return;
            Logger.send({
                logEventLevel: LogLevel.Error,
                logImpact: Impact.Medium,
                fileName: 'signalr.ts',
                functionName: 'start',
                id: '',
                context: 'SignalRHub',
                detailContext: 'Connection failed',
                error: reason,
            });
        }
    }

    stop(redirectUrl: string = null) {
        this.signalrHub.stop().then(() => {
            if (redirectUrl) {
                window.location.assign(redirectUrl);
            }
        });
    }

    subscribeToContactNotifications(group: string) {
        if (!!group && this.connected) {
            this.signalrHub.invoke('SubscribeToContactNotifications', group).catch((reason) => {
                if (!!reason) return;
                Logger.send({
                    logEventLevel: LogLevel.Error,
                    logImpact: Impact.Medium,
                    fileName: 'signalr.ts',
                    functionName: 'subscribeToContactNotifications',
                    id: `group: ${group}`,
                    context: 'SignalRHub',
                    detailContext: 'Failed to subscribe',
                    error: reason,
                });
            });
        }
    }

    subscribeToOrganisationNotifications(organisationId: string) {
        if (!!organisationId && this.connected) {
            this.signalrHub.invoke('subscribeToOrganisationNotifications', organisationId).catch((reason) => {
                if (!!reason) return;
                Logger.send({
                    logEventLevel: LogLevel.Error,
                    logImpact: Impact.Medium,
                    fileName: 'signalr.ts',
                    functionName: 'subscribeToOrganisationNotifications',
                    id: `organisationId: ${organisationId}`,
                    context: 'SignalRHub',
                    detailContext: 'Failed to subscribe',
                    error: reason,
                });
            });
        }
    }

    unSubscribeFromOrganisationNotifications(organisationId: string) {
        if (!!organisationId && this.connected) {
            this.signalrHub.invoke('unSubscribeFromOrganisationNotifications', organisationId).catch((reason) => {
                if (!!reason) return;
                Logger.send({
                    logEventLevel: LogLevel.Error,
                    logImpact: Impact.Medium,
                    fileName: 'signalr.ts',
                    functionName: 'unSubscribeFromOrganisationNotifications',
                    id: `organisationId: ${organisationId}`,
                    context: 'SignalRHub',
                    detailContext: 'Failed to unsubscribe',
                    error: reason,
                });
            });
        }
    }

    subscribeToMapRequestNotifications(mapRequestId: string) {
        if (!!mapRequestId && this.connected) {
            this.signalrHub.invoke('subscribeToMapRequestNotifications', mapRequestId.toLowerCase()).catch((reason) => {
                if (!!reason) return;
                Logger.send({
                    logEventLevel: LogLevel.Error,
                    logImpact: Impact.Medium,
                    fileName: 'signalr.ts',
                    functionName: 'subscribeToMapRequestNotifications',
                    id: `mapRequest: ${mapRequestId}`,
                    context: 'SignalRHub',
                    detailContext: 'Failed to subscribe',
                    error: reason,
                });
            });
        }
    }

    unSubscribeFromMapRequestNotifications(mapRequestId: string) {
        if (!!mapRequestId && this.connected) {
            this.signalrHub.invoke('unSubscribeFromMapRequestNotifications', mapRequestId).catch((reason) => {
                if (!!reason) return;
                Logger.send({
                    logEventLevel: LogLevel.Error,
                    logImpact: Impact.Medium,
                    fileName: 'signalr.ts',
                    functionName: 'unSubscribeFromMapRequestNotifications',
                    id: `mapRequest: ${mapRequestId}`,
                    context: 'SignalRHub',
                    detailContext: 'Failed to unsubscribe',
                    error: reason,
                });
            });
        }
    }

    subscribeToCustomIdNotifications(customId: string, customType: string) {
        if (!!customId && this.connected) {
            this.signalrHub.invoke('subscribeToCustomIdNotifications', customId.toLowerCase()).catch((reason) => {
                if (!!reason) return;
                Logger.send({
                    logEventLevel: LogLevel.Error,
                    logImpact: Impact.Medium,
                    fileName: 'signalr.ts',
                    functionName: `subscribeToCustomNotifications_${customType}`,
                    id: `customId: ${customId}_${customType}`,
                    context: 'SignalRHub',
                    detailContext: 'Failed to subscribe',
                    error: reason,
                });
            });
        }
    }

    unSubscribeFromCustomIdNotifications(customId: string, customType: string) {
        if (!!customId && this.connected) {
            this.signalrHub.invoke('unSubscribeFromCustomIdNotifications', customId).catch((reason) => {
                if (!!reason) return;
                Logger.send({
                    logEventLevel: LogLevel.Error,
                    logImpact: Impact.Medium,
                    fileName: 'signalr.ts',
                    functionName: `unSubscribeFromCustomNotifications_${customType}`,
                    id: `customId: ${customId}_${customType}`,
                    context: 'SignalRHub',
                    detailContext: 'Failed to unsubscribe',
                    error: reason,
                });
            });
        }
    }

    subToEvent(eventType: EEventType, callback: (message: IMessage) => void) {
        this.subscribeTo(eventType, callback);
    }

    unsubFromEvent(eventType: EEventType) {
        this.unSubscribeFrom(eventType);
    }

    subscribeTo(action: string, method: (message: IMessage) => void) {
        this.signalrHub
            .on(action, (message: IMessage) => {
                method(message);
            });
    }

    subscribeToInvoiceLogEvent(method: (message: InvoicingLogMessage) => void) {
        this.signalrHub
            .on('log', (message: InvoicingLogMessage) => {
                method(message);
            });
    }

    unSubscribeToInvoiceLogEvent() {
        this.signalrHub.off('log');
    }

    unSubscribeFrom(action: string) {
        this.signalrHub.off(action);
    }
}

export const signalrHub = new SignalRHub('/hub');

export default {
    install(vue: typeof Vue): void {
        Object.defineProperties(vue.prototype, {
            $signalrHub: {
                get() {
                    return signalrHub;
                },
            },
        });
    },
};

export function useSignalrHub(): SignalRHub {
    return signalrHub;
}
