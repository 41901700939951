import { RouteConfig } from 'vue-router';
import { organisationSettings } from '../../settings-routes';

import KlEditSubOrganisation from './kl-edit-suborganisation/kl-edit-suborganisation.vue';
import KlCreateSubOrganisation from './kl-create-suborganisation/kl-create-suborganisation.vue';
import KlUnaZone from './kl-edit-suborganisation/components/kl-organisation-una-zones/kl-una-zone/kl-una-zone.vue';
import KlUnaCreateEditZone from './kl-edit-suborganisation/components/kl-organisation-una-zones/kl-una-create-edit-zone/kl-una-create-edit-zone.vue';
import KlUnaCreateEditKlimZone from './kl-edit-suborganisation/components/kl-organisation-una-zones/kl-una-create-edit-klimzone/kl-una-create-edit-klimzone.vue';

export const NewSubOrganisation: RouteConfig = {
    path: '/settings/organisation/create',
    name: 'newsuborganisation',
    component: KlCreateSubOrganisation,
    meta: {
        authorize: { roles: ['org_manager'] },
        breadcrumb: { title: 'Nieuwe suborganisatie', parent: organisationSettings },
    },
};

export const EditSubOrganisation: RouteConfig = {
    path: '/settings/organisation/:organisationId',
    props: true,
    name: 'editsuborganisation',
    component: KlEditSubOrganisation,
    meta: {
        authorize: { roles: ['org_manager', 'una_manager', 'una_operator', 'una_reader', 'klim_admin', 'mri'] },
        breadcrumb: { title: 'Bewerk suborganisatie', parent: organisationSettings },
    },
};

export const UnaZone: RouteConfig = {
    path: '/settings/organisation/:organisationId/unazone/detail/:zoneId',
    props: true,
    name: 'unazone_readonly',
    component: KlUnaZone,
    meta: {
        authorize: { roles: ['una_manager', 'una_operator', 'una_reader', 'klim_admin'] },
        breadcrumb: { title: 'Zone detail', parent: EditSubOrganisation },
    },
};

export const NewUnaZone: RouteConfig = {
    path: `/settings/organisation/:organisationId/unazone/create`,
    props: true,
    name: 'newunazone',
    component: KlUnaCreateEditZone,
    meta: {
        authorize: { roles: ['una_manager'] },
        breadcrumb: { title: 'Nieuwe zone', parent: EditSubOrganisation },
    },
};

export const EditUnaZone: RouteConfig = {
    path: '/settings/organisation/:organisationId/unazone/:zoneId/edit',
    props: true,
    name: 'editunazone',
    component: KlUnaCreateEditZone,
    meta: {
        authorize: { roles: ['una_manager'] },
        breadcrumb: { title: 'Bewerk zone', parent: EditSubOrganisation },
    },
};

export const UnaKlimZone: RouteConfig = {
    path: '/settings/organisation/:organisationId/unazoneklim/:zoneId',
    props: true,
    name: 'unaklimzone_readonly',
    component: KlUnaZone,
    meta: {
        authorize: { roles: ['una_manager', 'una_operator', 'una_reader', 'klim_admin'] },
        breadcrumb: { title: 'Zone detail', parent: EditSubOrganisation },
    },
};

export const NewUnaKlimZone: RouteConfig = {
    path: '/settings/organisation/:organisationId/unazone/create',
    props: true,
    name: 'newunaklimzone',
    component: KlUnaCreateEditKlimZone,
    meta: {
        authorize: { roles: ['klim_admin'] },
        breadcrumb: { title: 'Nieuwe KLIM zone', parent: EditSubOrganisation },
    },
};

export const EditUnaKlimZone: RouteConfig = {
    path: '/settings/organisation/:organisationId/unazoneklim/:zoneId/edit',
    props: true,
    name: 'editunaklimzone',
    component: KlUnaCreateEditKlimZone,
    meta: {
        authorize: { roles: ['klim_admin'] },
        breadcrumb: { title: 'Bewerk KLIM zone', parent: EditSubOrganisation },
    },
};


export default [NewSubOrganisation, EditSubOrganisation, UnaZone, NewUnaZone, EditUnaZone, UnaKlimZone, NewUnaKlimZone, EditUnaKlimZone] as RouteConfig[];

