import GeoJSON from 'ol/format/GeoJSON.js';
import WKT from 'ol/format/WKT';
import GML2 from 'ol/format/GML2.js';
import Geometry from 'ol/geom/Geometry';
import GeometryCollection from 'ol/geom/GeometryCollection';
import Feature from 'ol/Feature';
import {filter, find, isEmpty, map} from 'lodash-es';
import { openShp } from 'shapefile';

export enum EGeometryImportServiceFileType {
    geojson = 'GeoJSON',
    wkt = 'wkt',
    shp = 'shape',
}

export default class GeometryImportService {

    public static isFileExtensionSupported(filename: string): boolean {
        const supported = [
          '.wkt', '.json', '.geojson', '.shp', '.shape'
        ];
        return !!find(supported, (extension: string) => filename.toLowerCase().endsWith(extension));
    }

    public static getFileExtension(filename: string): EGeometryImportServiceFileType {
        if (filename.toLowerCase().endsWith('.json') || filename.toLowerCase().endsWith('.geojson')) {
            return EGeometryImportServiceFileType.geojson;
        }
        if (filename.toLowerCase().endsWith('.wkt')) {
            return EGeometryImportServiceFileType.wkt;
        }
        if (filename.toLowerCase().endsWith('.shp')|| filename.toLowerCase().endsWith('.shape')) {
            return EGeometryImportServiceFileType.shp;
        }
        return null;
    }

    public static async import(file: File): Promise<string[]> {
        // console.log(file.name);
        // console.log(file.size);
        // console.log(await file.text());
        // console.log(await file.arrayBuffer());

        const fileExtension = GeometryImportService.getFileExtension(file.name);
        if (fileExtension === EGeometryImportServiceFileType.geojson) {
            return GeometryImportService.importGeoJson(await file.text());
        }
        if (fileExtension === EGeometryImportServiceFileType.wkt) {
            return GeometryImportService.importWKT(await file.text());
        }
        if (fileExtension === EGeometryImportServiceFileType.shp) {
            return GeometryImportService.importShapefile(await file.arrayBuffer());
        }

        throw new Error('GeometryImportService.import: file type not supported');
    }

    // output: array of WKT geometries
    public static importGeoJson(geoJson: string): string[] {

        const wkts =
            GeometryImportService._importGeoJsonGeometry(geoJson)
            || GeometryImportService._importGeoJsonFeatureCollection(geoJson);

        const polygons = filter(wkts, (wkt: string) => wkt.startsWith('POLYGON('));
        return polygons;
    }

    // output: array of WKT geometries
    public static importWKT(wkt: string): string[] {

        const wkts = GeometryImportService._importWktGeometry(wkt)

        const polygons = filter(wkts, (wkt: string) => wkt.startsWith('POLYGON('));
        return polygons;
    }

    // output: array of WKT geometries
    public static async importShapefile(data: ArrayBuffer): Promise<string[]> {

        const source = await openShp(data);
        let wkts: string[] = [];

        let currentRead: { done: boolean, value: any };
        do {
            currentRead = await source.read();
            if (!currentRead.done) {
                const geoJson = currentRead.value;
                const newWkts = GeometryImportService._importGeoJsonGeometry(JSON.stringify(geoJson));
                wkts = wkts.concat(newWkts);
            }
        }
        while (!currentRead.done)

        const polygons = filter(wkts, (wkt: string) => wkt.startsWith('POLYGON('));
        return polygons;
    }

    // output: array of WKT geometries
    // public static importGML2(gml: string): string[] {
    //
    //     const wkts = GeometryImportService._importGmlGeometry(gml)
    //
    //     const polygons = filter(wkts, (wkt: string) => wkt.startsWith('POLYGON('));
    //     return polygons;
    // }
    //
    // public static convertToGML(geoJson): string {
    //     console.log('geoJson', geoJson);
    //     const feature = new GeoJSON().readFeature(geoJson);
    //     console.log('feature', feature);
    //     console.log('---------------------------------------------------------------------------');
    //     // const geometry = feature.getGeometry();
    //     const gml: string = new GML2({
    //         // featureNS: 'http://www.opengis.net/gml',
    //         featureType: 'Polygon',
    //         srsName: 'http://www.opengis.net/def/crs/EPSG/0/31370',
    //     }).writeFeatures(feature);
    //     console.log('gml', gml);
    //     return gml;
    // }

    private static _importGeometry(geometry: Geometry | GeometryCollection): string[] {
        // GeometryCollection
        if (geometry.getGeometries) {
            const wkts = map(geometry.getGeometries(), (geometry: Geometry) => new WKT().writeGeometry(geometry));
            return wkts;
        }

        // Geometry
        const wkt: string = new WKT().writeGeometry(geometry);
        return [wkt];
    }

    private static _importWktGeometry(wktInput: string): string[] {
        try {
            const geometry = new WKT().readGeometry(wktInput);
            return GeometryImportService._importGeometry(geometry);
        }
        catch (e) {
            // console.error(e);
            return null;
        }
    }

    private static _importGeoJsonGeometry(geoJson: string): string[] {
        try {
            const geometry = new GeoJSON().readGeometry(geoJson);
            return GeometryImportService._importGeometry(geometry);
        }
        catch (e) {
            // console.error(e);
            return null;
        }
    }

    // private static _importGmlGeometry(gml: string): string[] {
    //     try {
    //         console.log('gml', gml);
    //         const feature = new GML2({
    //             featureType: 'Polygon',
    //         }).readFeatures(gml);
    //         console.log('feature', feature);
    //
    //         return null;
    //
    //         // const geometry = feature.getGeometry();
    //         // return GeometryImportService._importGeometry(geometry);
    //     }
    //     catch (e) {
    //         console.log(e);
    //         return null;
    //     }
    // }

    // private static _importGeoJsonFeature(geoJson: string): string[] {
    //     try {
    //     const feature = new GeoJSON().readFeature(geoJson);
    //     const geometry = feature.getGeometry();
    //     const wkt: string = new WKT().writeGeometry(geometry);
    //
    //     return [wkt];
    //     }
    //     catch (e) {
    //         // console.error(e);
    //         return null;
    //     }
    // }

    private static _importGeoJsonFeatureCollection(geoJson: string): string[] {
        try {
            const features = new GeoJSON().readFeatures(geoJson);
            const wkts = map(features, (feature: Feature) => {
                const geometry = feature.getGeometry();
                const wkt: string = new WKT().writeGeometry(geometry);
                return wkt;
            });

            return wkts;
        }
        catch (e) {
            // console.error(e);
            return null;
        }
    }
}
