import { KlRouteConfig } from '@/router/router-vue';
import { UserData } from '../../state/UserDataModule';
import { Location } from 'vue-router';
import {computed, defineComponent} from 'vue';

export default defineComponent({
    name: 'KlRouterLink',
    props: {
        protect: {
            type: Boolean,
            default: false,
        },
        to: {
            type: Object as () => KlRouteConfig,
            required: true,
        },
        params: {
            type: Object,
            default: undefined,
        },
    },
    setup(props, { emit }) {

        const location = computed((): Location => {
            return {
                path: props.to.path,
                name: props.to.name,
                hash: props.to.hash,
                params: props.params,
            };
        });

        const canShow = computed(() => {
            let result = true;
            if (props.protect && props.to.meta && props.to.meta.authorize && props.to.meta.authorize.roles) {
                result =  UserData.hasRole(props.to.meta.authorize.roles);
            }
            return result;
        });

        return { location, canShow }
    }
})
