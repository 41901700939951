
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class KlPagination extends Vue {

    @Prop(Number)
    pageSize: number;

    @Prop({ type: Number, default: 1 })
    currentPage: number;

    @Prop({ type: Number, required: true })
    total: number;

    @Prop({ type: Number, required: true })
    results: number;

    get pagePosition(): string {
        const pagerFrom = this.currentPage > 1 ? ((this.currentPage - 1) * this.pageSize) + 1 : 1;
        const pagerTo = Math.min(this.total ? this.total : this.results * this.currentPage,
            this.pageSize * this.currentPage);
        const total = this.total || (this.results < this.pageSize ? this.results : 0);
        return `<strong>${pagerFrom}&nbsp;-&nbsp;${pagerTo || '...'}</strong> van&nbsp;${total || '...'}`;
    }

    get pageCount(): number {
        return Math.ceil(this.total / (this.pageSize || 1));
    }

    get pagination(): string[] {
        const pageArray: string[] = [];

        if (this.pageCount > 1) {
            if (this.pageCount < 7) {
                for (let i = 1; i <= this.pageCount; i++) {
                    pageArray.push(i.toString());
                }
            } else {
                for (let i = 1; i <= 7; i++) {
                    switch (i) {
                        case 1:
                            pageArray.push('1');
                            break;
                        case 2:
                            if (this.currentPage <= 4) {
                                pageArray.push('2');
                            } else {
                                pageArray.push('...');
                            }
                            break;
                        case 3:
                            if (this.currentPage <= 4) {
                                pageArray.push('3');
                            } else if (this.pageCount - this.currentPage < 4) {
                                pageArray.push((this.pageCount - 4).toString());
                            } else {
                                pageArray.push((this.currentPage - 1).toString());
                            }
                            break;
                        case 4:
                            if (this.currentPage <= 4) {
                                pageArray.push('4');
                            } else if (this.pageCount - this.currentPage < 4) {
                                pageArray.push((this.pageCount - 3).toString());
                            } else {
                                pageArray.push(this.currentPage.toString());
                            }
                            break;
                        case 5:
                            if (this.currentPage <= 4) {
                                pageArray.push('5');
                            } else if (this.pageCount - this.currentPage < 4) {
                                pageArray.push((this.pageCount - 2).toString());
                            } else {
                                pageArray.push((this.currentPage + 1).toString());
                            }
                            break;
                        case 6:
                            if (this.pageCount - this.currentPage < 4) {
                                pageArray.push((this.pageCount - 1).toString());
                            } else {
                                pageArray.push('...');
                            }
                            break;
                        case 7:
                            pageArray.push(this.pageCount.toString());
                            break;
                    }
                }
            }
        }
        return pageArray;
    }

    public changePage(pageNumber: number) {
        this.$emit('page-change', pageNumber);
    }
}
