import Collection from 'ol/Collection';
import Feature from 'ol/Feature';
import {defineStore} from 'pinia';
import {ref, Ref} from 'vue';
import {Vector as SourceVector} from 'ol/source';

export const useDrawZoneStore = defineStore('kl-draw-zone-store', () => {

    const currentFeature: Ref<Feature> = ref(null);

    let _featureBackup: Feature = null;

    // const mapFeatures: Ref<Collection<Feature>> = ref(new Collection());
    const mapFeatures: Collection<Feature> = new Collection();

    const source = new SourceVector({ features: mapFeatures });
    const selectedFeatures: Collection<Feature> = new Collection();

    function addFeature(feature: Feature) {
        mapFeatures.push(feature);
    }

    function removeFeature(feature: Feature) {
        mapFeatures.remove(feature);
        selectedFeatures.remove(feature);
    }

    function findFeature(zoneId: number): Feature {
        let feature: Feature = null;
        mapFeatures.forEach((f: Feature) => {
            if (f.drawZoneId === zoneId) {
                feature = f;
            }
        });
        return feature;
    }

    // ? bijna hetzelfde als selectFeature ?
    // TODO: wat is het verschil? en kan ik dat wegwerken?
    function setCurrentFeature(feature: Feature) {
        currentFeature.value = feature;
    }

    function selectFeature(feature: Feature) {
        selectedFeatures.clear();
        if (feature) {
            selectedFeatures.push(feature);
            _featureBackup = feature.clone();
        }
    }

    function revertFeatureChanges() {
        if ((selectedFeatures.getLength() === 0) || !_featureBackup) {
            return;
        }
        selectedFeatures.item(0).setGeometry(_featureBackup.getGeometry());
    }

    function clear() {
        currentFeature.value = null;

        _featureBackup = null;

        mapFeatures.clear();
        selectedFeatures.clear();
    }

    return {
        currentFeature,
        setCurrentFeature,

        addFeature,
        removeFeature,
        findFeature,

        source,
        selectedFeatures,

        selectFeature,
        revertFeatureChanges,

        clear,
    }
});
