import { IConfig } from './config';
import { IGuideline } from './guideline';
import {computed, defineComponent, ref} from 'vue';

export default defineComponent({
    name: 'KlUpload',
    emits: ['revert', 'file-uploaded'],
    props: {
        modRevertable: { type: Boolean, required: false, default: false },
        modCollapsed: { type: Boolean, required: false, default: false },
        modHeader: { type: Boolean, required: false, default: true },
        config: {
            type: Object as () => IConfig,
            default: undefined,
        },
        guidelines: {
            type: Array as () => IGuideline[],
            default: undefined,
        },
        modDisabled: { type: Boolean, required: false, default: false },
        modHideGuideLines: { type: Boolean, required: false, default: false },
        maxFiles: { type: Number, required: false, default: 1 },
    },
    setup(props, { attrs, emit }) {

        const uploadButtonDisabled = ref<boolean>(true);
        const uploadedFilePercentage = ref<string>('00');
        const uploadedFileSize = ref<number>(0);
        const totalFileSize = ref<number>(0);
        const isSuccess = ref<boolean>(false);
        const successTitle = ref<string>(attrs['success-title'] as string || 'Uw bestand werd succesvol opgeladen');
        const successContent = ref<string>(attrs['success-content'] as string || '');
        const isError = ref<boolean>(false);
        const errorTitle = ref<string>(attrs['error-title'] as string || 'Uw bestand kon niet verwerkt worden');
        const errorContent = ref<string>(attrs['error-content'] as string || '');
        const isUploading = ref<boolean>(false);

        const vlUpload = ref(null);


        const attrsFromConfig = computed(() => {
            const normalAttrs = attrs;

            const attrsFromConfig = props.config ? {
                url: props.config.url,
                title: props.config.title,
                uploadLabel: props.config.uploadLabel,
                uploadDragText: props.config.uploadDragText,
                maxFiles: props.config.files.amount,
                maxFilesize: props.config.files.size,
                maxFilesizeMsg: props.config.files.message,
                allowedFileTypes: props.config.files.types,
            } : {};

            return { ...normalAttrs, ...attrsFromConfig };
        });

        const guidelinesFromConfig = computed(() => {
            return props.config ? props.config.guidelines : props.guidelines;
        });

        const uploadProgressMessage = computed(() => {
            return uploadedFilePercentage.value + '%<br>' + uploadedFileSize.value + 'MB van ' + totalFileSize.value + 'MB';
        });

        const dropZoneRef = computed((): any => {
            return (vlUpload.value as Vue).$refs.dropzoneRef;
        });

        const dropzone = computed(() => {
            return dropZoneRef.value.dropzone;
        });


        const _bytesToMB = (value: number): number => {
            return parseFloat((value / 1000000).toFixed(2));
        }

        const guidelineComponent = (guideline: IGuideline) => {
            return guideline.link ? 'kl-router-link' : 'span';
        }

        const _updateUploadButton = () => {
            const rejectedFiles = dropzone.value.getRejectedFiles();
            uploadButtonDisabled.value = !!(!dropzone.value.files.length ||
                dropzone.value.files.length > attrs['max-files'] ||
                rejectedFiles.length);
        }

        const fileAdded = (file: { size: number }) => {
            totalFileSize.value += _bytesToMB(file.size);
        }

        const filesAdded = (file: object) => {
            _updateUploadButton();
        }

        const success = (file: object, response: object) => {
            isSuccess.value = true;
        }

        const error = (file: object, message: string, xhr: object) => {
            isUploading.value = false;
        }

        const removedFile = (file: { size: number }, error: object, xhr: object) => {
            totalFileSize.value -= _bytesToMB(file.size);
            _updateUploadButton();
        }

        const sending = (file: object, xhr: object, formData: object) => {
            isUploading.value = true;
        }

        const uploadProgress = (file: object, progress: number, bytesSent: number) => {
            uploadedFilePercentage.value = progress.toFixed(2);
            uploadedFileSize.value = _bytesToMB(bytesSent);
            if (progress === 100) {
                emit('file-uploaded');
                isUploading.value = false;
            }
        }

        const startUpload = () => {
            isUploading.value = true;
            dropZoneRef.value.processQueue();
        }

        const revert = () => {
            emit('revert');
        }

        return {
            vlUpload,

            uploadButtonDisabled,
            uploadedFilePercentage,
            uploadedFileSize,
            totalFileSize,
            isSuccess,
            successTitle,
            successContent,
            isError,
            errorTitle,
            errorContent,
            isUploading,

            attrsFromConfig,
            guidelinesFromConfig,
            uploadProgressMessage,

            guidelineComponent,
            fileAdded,
            filesAdded,
            success,
            error,
            removedFile,
            sending,
            uploadProgress,
            startUpload,
            revert,
        }
    }
})
