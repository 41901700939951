import {Component, Ref, Vue, Watch} from 'vue-property-decorator';
import { IDrawZone } from '@/app/shared/components/kl-draw-zone-map/components/kl-draw-zone-sidebar/kl-draw-zone-sidebar';
import { first, every } from 'lodash-es';

@Component
export default class KlLocationpicker extends Vue {
    public location: IDrawZone = null;
    public zones: IDrawZone[] = null;
    public drawZoneActive: boolean = false;
    public isSearchValid: boolean = true;

    get applyButtonDisabled() {
        return !this.isSearchValid;
    }

    public onZonesChanged(zones: IDrawZone[]) {
        this.zones = zones;
        // no zone is also valid! (not required)
        this.isSearchValid = every(this.zones, (cz: IDrawZone) => cz.isValid);
    }

    public editLocation() {
        if (this.location) {
            this.toggleModal();
        }
    }

    toggleModal() {
        this.$vnode.context.$root.$emit('modal-toggle', 'location-picker-modal');
    }

    public removeLocation() {
        this.drawZoneActive = false;
        this.location = null;
        this.zones = null;
        this.$emit('input', null);
    }

    public applyZone() {
        this.location =first(this.zones);
        this.$emit('input', this.location);
        this.toggleModal();
    }

    public cancel() {
        this.toggleModal();
    }

    public setZoneFromVModel() {
        if (this.$attrs.value) {
            this.location = (this.$attrs.value as unknown as IDrawZone);
            this.zones = [this.location];
        }
    }

    public mounted() {
        this.setZoneFromVModel();
        this.$root.$on('opened', () => {
            this.drawZoneActive = true;
            this.setZoneFromVModel();
            Vue.nextTick(() => {
                if (this.$refs.map) {
                    const map = (this.$refs.map as any).$refs.map;
                    if (map) {
                        map.instance.updateSize();
                        const drawnFeature = (this.$refs.map as any).drawnFeature;
                        if (drawnFeature) {
                            map.zoomToFeature(drawnFeature, 1);
                        }
                    }
                }
            });
        });
        this.$root.$on('closed', () => {
            this.drawZoneActive = false;
        });
    }
}
