import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { EditSubOrganisation } from '@/app/settings/components/kl-organisation-settings/kl-organisation-routes';
import SettingsStore, {SettingsDataService} from '@/app/settings/settings-store';
import * as KlipApi from '@/api/klip-api.proxy';
import { ValidationObserver } from 'vee-validate';
import {computed, defineComponent, getCurrentInstance, onMounted, ref, watch} from 'vue';
import {useKlipApiProxy} from '@/plugins/proxy-client';
import {useRouter} from '@/plugins/routes';

export default defineComponent({
    name: 'KlUnaCreateEditZone',
    props: {
        zoneId: { type: String, default: '' },
        organisationId: { type: String, default: '' },
    },
    setup(props) {

        const validationObserver = ref<InstanceType<typeof ValidationObserver>>(null);

        const router = useRouter();

        const namespaceUrl: string = import.meta.env.VITE_LINKS_NAMESPACE;
        const overlayUrl: string = import.meta.env.VITE_LINKS_OVERLAY;
        const helpdeskMail: string = import.meta.env.VITE_EMAIL_HELPDESK;
        const zoneNameValidationRegex = /^[a-zA-Z0-9\-_ .+!()&ö/:,è|'@\s]*$/;
        const namespaceRegex = /^[0-9a-zA-Z-_]{5,50}$/;

        const name = ref<string>('');
        const initialName = ref<string>('');
        const mailDestination = ref<string>('');
        const namespace = ref<string>('');
        const contact = ref({
            name: '',
            phone: '',
            email: '',
            message: '',
            phoneEmergency: '',
        });

        const mailSettings = ref({
            confirmation: false,
            confirmationExampleUri: '',
            reminder1DayToRespond: false,
            reminder1DayToRespondExampleUri: '',
            uploadImklSuccess: false,
            uploadImklSuccessExampleUri: '',
            uploadImklFailed: false,
            uploadImklFailedExampleUri: '',
            respondedNotInvolved: false,
            respondedNotInvolvedExampleUri: ''
        });

        const loading = ref<boolean>(false);
        const isSending = ref<boolean>(false);
        const deletingZone = ref<boolean>(false);
        const deleteButtonFocussed = ref<boolean>(false);
        const deletingGeometry = ref<boolean>(false);
        const addingNewGeometry = ref<boolean>(false);
        const uploadUrl = ref<string>('');
        const unaDisplayName = ref<string>('');

        // @Action(SettingsStore.store.actions.deleteNamespaceZone, { namespace: SettingsStore.store.namespace })
        const _deleteNamespaceZone = (deleteNamespace: { id: string, organisationId: string }) => SettingsDataService.deleteNamespaceZone(deleteNamespace);

        // @Action(SettingsStore.store.actions.updateNamespaceZone, { namespace: SettingsStore.store.namespace })
        const _updateNamespaceZone = (update: { id: string, organisationId: string, data: KlipApi.ICreateKlipZoneInput }) => SettingsDataService.updateNamespaceZone(update);

        // @Action(SettingsStore.store.actions.createNamespaceZone, { namespace: SettingsStore.store.namespace })
        const _createNamespaceZone = (create: { id: string, organisationId: string, data: KlipApi.ICreateKlipZoneInput, isKlim: boolean }) => SettingsDataService.createNamespaceZone(create);


        const routeEditOrganinsation = computed(() => {
            return EditSubOrganisation;
        });

        const editMode = computed((): boolean => {
            return !!props.zoneId;
        });

        const createMode = computed((): boolean => {
            return !!!props.zoneId;
        });

        const canSubmit = computed(() => {
            return true;
        });

        const title = computed((): string => {
            if (!!props.zoneId) {
                return 'Bewerk KLIM zone';
            } else {
                return 'Nieuwe KLIM zone';
            }
        });

        const submitButtonLabel = computed((): string => {
            return editMode.value ? 'Bewaar veranderingen' : 'KLIM Zone toevoegen';
        });


        const submit = () => {
            if (!canSubmit.value) {
                return;
            }

            isSending.value = true;
            const data = {
                organisationId: props.organisationId,
                alarmCentralTelephone: contact.value.phoneEmergency,
                contactName: contact.value.name,
                contactEmail: contact.value.email,
                contactTelephone: contact.value.phone,
                extraInformation: contact.value.message,
                mapRequestEmail: mailDestination.value,
                name: name.value,
                namespace: namespace.value,
                sendConfirmation: mailSettings.value.confirmation,
                sendreminder1DayToRespond: mailSettings.value.reminder1DayToRespond,
                sendrespondedNotInvolved: mailSettings.value.respondedNotInvolved,
                senduploadImklFailed: mailSettings.value.uploadImklFailed,
                senduploadImklSuccess: mailSettings.value.uploadImklSuccess,
            } as KlipApi.IUpdateKlimZoneInput | KlipApi.ICreateKlimZoneInput;
            const saveOrUpdatePromise: Promise<any> = editMode.value ?
                _updateKlimZone(data) :
                _createKlimZone(data);

            saveOrUpdatePromise
                .then((response: KlipApi.EnvelopeOfKlipZoneCreated | KlipApi.EnvelopeOfKlimZoneCreated) => {
                    const id = response instanceof KlipApi.EnvelopeOfKlimZoneCreated ? response.result.id : props.zoneId;
                    if (editMode.value) {
                        _updateNamespaceZone({ id, organisationId: props.organisationId, data });
                    } else {
                        _createNamespaceZone({ id, organisationId: props.organisationId, data, isKlim: true });
                    }
                    router.replace({
                        name: routeEditOrganinsation.value.name,
                        params: {
                            organisationId: props.organisationId,
                        }
                    });
                })
                .finally(() => {
                    isSending.value = false;
                    addingNewGeometry.value = false;
                });
        }

        const _createKlimZone = async (createZoneInput: KlipApi.ICreateKlimZoneInput) => {
            return await useKlipApiProxy().unaSettings_CreateKlimZone(createZoneInput as KlipApi.CreateKlimZoneInput);
        }

        const _updateKlimZone = async (createZoneInput: KlipApi.IUpdateKlimZoneInput) => {
            const klimData = {
                ...createZoneInput,
                id: props.zoneId,
            } as KlipApi.UpdateKlimZoneInput;
            return await useKlipApiProxy().unaSettings_UpdateKlimZone(klimData);
        }


        const _onIdChange = (newZoneId: string) => {
            if (!!newZoneId) {
                loading.value = true;
                useKlipApiProxy().unaSettings_GetUnaZoneEditView(newZoneId, props.organisationId)
                    .then((response) => {
                        if (!response.result.id) {
                            return;
                        }

                        initialName.value = response.result.zoneName;
                        unaDisplayName.value = response.result.unaName;
                        name.value = response.result.zoneName;
                        mailDestination.value = response.result.mapRequestEmail;
                        namespace.value = response.result.namespace;
                        contact.value = {
                            name: response.result.contactName,
                            phone: response.result.contactTelephone,
                            email: response.result.contactEmail,
                            message: response.result.extraInformation,
                            phoneEmergency: response.result.alarmCentralTelephone,
                        };
                        mailSettings.value = {
                            confirmation: response.result.confirmation,
                            confirmationExampleUri: response.result.confirmationExampleUri,
                            reminder1DayToRespond: response.result.reminder1DayToRespond,
                            reminder1DayToRespondExampleUri: response.result.reminder1DayToRespondExampleUri,
                            uploadImklFailed: response.result.uploadImklFailed,
                            uploadImklFailedExampleUri: response.result.uploadImklFailedExampleUri,
                            uploadImklSuccess: response.result.uploadImklFailed,
                            uploadImklSuccessExampleUri: response.result.uploadImklSuccessExampleUri,
                            respondedNotInvolved: response.result.respondedNotInvolved,
                            respondedNotInvolvedExampleUri: response.result.respondedNotInvolvedExampleUri
                        };
                        loading.value = false;
                    }).finally(async () => {
                        const observer = validationObserver.value;
                        await observer.validate();
                    });
            } else {
                loading.value = true;
                useKlipApiProxy().unaSettings_GetUnaZoneCreateView()
                    .then((response) => {
                        uploadUrl.value = response.result.uploadZoneFileUrl;
                        mailSettings.value = {
                            confirmation: true,
                            confirmationExampleUri: response.result.confirmationExampleUri,
                            reminder1DayToRespond: false,
                            reminder1DayToRespondExampleUri: response.result.reminder1DayToRespondExampleUri,
                            uploadImklFailed: false,
                            uploadImklFailedExampleUri: response.result.uploadImklFailedExampleUri,
                            uploadImklSuccess: false,
                            uploadImklSuccessExampleUri: response.result.uploadImklSuccessExampleUri,
                            respondedNotInvolved: false,
                            respondedNotInvolvedExampleUri: response.result.respondedNotInvolvedExampleUri
                        };
                        loading.value = false;
                    });
            }
        }

        watch(
            () => props.zoneId,
            _onIdChange,
            { immediate: true });


        onMounted(() => {
            addingNewGeometry.value = !!createMode.value;
        });


        return {
            validationObserver,

            namespaceUrl,
            overlayUrl,
            helpdeskMail,
            zoneNameValidationRegex,
            namespaceRegex,

            name,
            initialName,
            mailDestination,
            namespace,
            contact,
            mailSettings,
            loading,
            isSending,
            deletingZone,
            deleteButtonFocussed,
            deletingGeometry,
            addingNewGeometry,
            uploadUrl,
            unaDisplayName,

            routeEditOrganinsation,
            editMode,
            createMode,
            canSubmit,
            title,
            submitButtonLabel,

            submit,
        }
    }
})
