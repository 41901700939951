import store from '@/store';
import { Module, VuexModule, Mutation, Action, getModule, MutationAction } from 'vuex-module-decorators';

export enum EAlertType {
    Error = 'mod-error',
    Warning = 'mod-warning',
    Success = 'mod-success',
}

export interface IAlertNotification {
    type: EAlertType;
    id: string;
    title: string;
    message: string;
    pageName?: string;
    icon?: 'cross' | 'warning' | 'info-circle';
    closable?: boolean;
    autoClose?: number;
}

@Module({
    dynamic: true,
    store,
    name: 'AlertModule',
    namespaced: true,
})
export class AlertVuexModule extends VuexModule {
    alerts: IAlertNotification[] = [];

    @Mutation
    addAlert(newAlert: IAlertNotification) {
        if (this.alerts.findIndex((alert) => alert.id === newAlert.id) < 0) {
            this.alerts.push(newAlert);
        }
    }

    @Mutation
    removeAlert(alertId: string) {
        this.alerts = this.alerts.filter((alert) => alert.id !== alertId);
    }

    get globalAlerts() {
        return this.alerts.filter((alert) => !alert.pageName);
    }

    get pageAlerts() {
        return (routeName: string) => this.alerts.filter((alert) => !!alert.pageName);
    }
}

export const AlertModule = getModule(AlertVuexModule);
