import { Component, Prop, Vue } from 'vue-property-decorator';
import { Geometry } from '@/api/klip-api.proxy';
import userLocalStorage from '@/storage/user-local-storage';
import DateUtil from '@/app/shared/helpers/date-util';
import { mixin as focusMixin } from 'vue-focus';

@Component({
    mixins: [focusMixin],
})
export default class KlGeometryDetails extends Vue {
    public deleteButtonFocussed: boolean = false;
    public downloadPreferenceLocalStorageKey: string = 'geometry_download_preference';
    public downloadFiletype: string = userLocalStorage.getItem(this.downloadPreferenceLocalStorageKey) || 'GeoJson';

    @Prop({ type: Object, required: true })
    public geometry: Geometry;

    @Prop({ type: Boolean, required: false, default: false })
    public showDeleteButton: boolean;

    get downloadFileUri() {
        return this.geometry.downloadUrls && this.downloadFiletype ?
            this.geometry.downloadUrls[this.downloadFiletype] : null;
    }

    get showDescriptionData() {
        return !!(this.geometry.version || this.geometry.lastModificationDate || this.geometry.precision);
    }

    public changeSelect() {
        userLocalStorage.setItem(this.downloadPreferenceLocalStorageKey, this.downloadFiletype);
    }

    get title() {
        return `${this.geometry.reference ? `'${this.geometry.reference}'` : ''}${this.geometry.isActive ?
            `${this.geometry.reference ? ` - ` : ``}actief sinds ${this.date(this.geometry.activationDate)}` :
            ` wordt geactiveerd op ${this.date(this.geometry.activationDate)}`}`;
    }

    public date(date: string) {
        return DateUtil.formatDate(date);
    }

    public onDelete() {
        this.$emit('geometry-before-delete', this.geometry);
    }

    public mounted() {
        this.$root.$on('opened', () => this.deleteButtonFocussed = true);
    }
}
