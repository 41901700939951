import {
    defineComponent,
    ref,
    computed,
    watch
  } from "vue";
  
  export default defineComponent({
    setup(props, ctx) {
      const onlySearchActiveZones = ref<boolean>(true);
 
      const watchFields = computed(() => {
        return [onlySearchActiveZones.value];
      });

      const onFieldChange = () => {
        search();
      };

      const search = () => {
        ctx.emit("search", onlySearchActiveZones.value);
      };

      watch(watchFields, onFieldChange, { immediate: false, deep: true });
 
      return {
        onlySearchActiveZones,
        onFieldChange,
        search,
      };
    },
  });