import {computed, defineComponent, inject, onMounted, ref} from "vue";
import { isEmpty } from 'lodash';
import {RouteConfig} from "vue-router";
import {AdminOrganisationDetail} from "@/app/admin/admin-routes";
import { IKlipApiProxy, UnpaidInvoice} from "@/api/klip-api.proxy";

export default defineComponent({
    setup() {
        const klipApiProxy = inject<IKlipApiProxy>('klipApiProxy');

        const companyWithUnpaidInvoices = ref<UnpaidInvoice[]>([])
        const loading = ref<Boolean>(true);

        const hasUnpaidInvoice = computed(() => {
            return !isEmpty(companyWithUnpaidInvoices.value);
        });

        const routeAdminOrganisationDetail = computed((): RouteConfig => {
            return AdminOrganisationDetail;
        });

        onMounted(async () => {
            const companiesWithUnpaidInvoicesEnvelope = await klipApiProxy.invoice_GetCompaniesWithUnpaidInvoices();

            if (companiesWithUnpaidInvoicesEnvelope.isSuccessStatusCode) {
                companyWithUnpaidInvoices.value = companiesWithUnpaidInvoicesEnvelope.result;
            }

            loading.value = false;
        });

        return {
            loading,
            companyWithUnpaidInvoices,
            hasUnpaidInvoice,
            routeAdminOrganisationDetail
        };
    }
});