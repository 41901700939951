import KlCreateGeometry from './components/kl-create-geometry/kl-create-geometry.vue';
import { IFlatGeometry } from './components/kl-create-geometry/kl-create-geometry';
import SettingsStore, {SettingsDataService} from '@/app/settings/settings-store';
import { Geometry, UpdateKlipZoneInput, CreateKlipZoneInput, ICreateKlipZoneInput, EnvelopeOfKlipZoneCreated, EnvelopeOfKlimZoneCreated, IUpdateKlipZoneInput } from '@/api/klip-api.proxy';
import { GeometryExtended } from '@/app/shared/components/kl-upload/kl-upload-geometry/kl-upload-geometry';
import dayjs from 'dayjs';
import { ValidationObserver } from 'vee-validate';
import { EditSubOrganisation } from '../../../../kl-organisation-routes';
import {first} from 'lodash-es';
import {computed, defineComponent, getCurrentInstance, onMounted, ref, watch} from 'vue';
import {useRouter} from '@/plugins/routes';
import {useKlipApiProxy} from '@/plugins/proxy-client';
import {useRoute} from 'vue2-helpers/vue-router';

export default defineComponent({
    name: 'KlUnaCreateEditZone',
    components: {
        KlCreateGeometry,
    },
    props: {
        zoneId: { type: String, default: '' },
        organisationId: { type: String, default: '' },
    },
    setup(props) {

        const router = useRouter();
        const root = getCurrentInstance().proxy.$root;

        const editZoneValidationObserver = ref<InstanceType<typeof ValidationObserver>>(null);

        const namespaceUrl: string = import.meta.env.VITE_LINKS_NAMESPACE;
        const overlayUrl: string = import.meta.env.VITE_LINKS_OVERLAY;
        const helpdeskMail: string = import.meta.env.VITE_EMAIL_HELPDESK;
        const zoneNameValidationRegex = /^[a-zA-Z0-9\-_ .+!()&ö/:,è|'@\s]*$/;
        const namespaceRegex = /^[0-9a-zA-Z-_]{5,50}$/;

        const isLoading = ref<boolean>(true);
        const name = ref<string>('');
        const initialName = ref<string>('');
        const mailDestination = ref<string>('');
        const namespace = ref<string>('');
        const contact = ref({
            name: '',
            phone: '',
            email: '',
            message: '',
            phoneEmergency: '',
        });
        const mailSettings = ref({
            confirmation: false,
            confirmationExampleUri: '',
            reminder1DayToRespond: false,
            reminder1DayToRespondExampleUri: '',
            uploadImklSuccess: false,
            uploadImklSuccessExampleUri: '',
            uploadImklFailed: false,
            uploadImklFailedExampleUri: '',
            respondedNotInvolved: false,
            respondedNotInvolvedExampleUri: ''
        });
        const isSending = ref<boolean>(false);
        const deletingZone = ref<boolean>(false);
        const deletingGeometry = ref<boolean>(false);
        const addingNewGeometry = ref<boolean>(false);
        const geometry = ref<IFlatGeometry>(null);
        const geometries = ref<GeometryExtended[]>([]);
        const geometryToBeDeleted = ref<Geometry>();
        const uploadUrl = ref<string>('');
        const unaDisplayName = ref<string>('');
        const geometryCount = ref<number>(0);
        const unaId = ref<number>();

        // @Action(SettingsStore.store.actions.deleteNamespaceZone, { namespace: SettingsStore.store.namespace })
        const _deleteNamespaceZone = (deleteNamespace: { id: string, organisationId: string, unaId: number }) => SettingsDataService.deleteNamespaceZone(deleteNamespace);

        // @Action(SettingsStore.store.actions.updateNamespaceZone, { namespace: SettingsStore.store.namespace })
        const _updateNamespaceZone = (update: { id: string, organisationId: string, data: ICreateKlipZoneInput }) => SettingsDataService.updateNamespaceZone(update);

        // @Action(SettingsStore.store.actions.createNamespaceZone, { namespace: SettingsStore.store.namespace })
        const _createNamespaceZone = (create: { id: string, organisationId: string, data: ICreateKlipZoneInput, isKlim: boolean }) => SettingsDataService.createNamespaceZone(create);


        const routeEditOrganinsation = computed(() => {
            return EditSubOrganisation;
        });

        const editMode = computed((): boolean => {
            return !!props.zoneId;
        });

        const createMode = computed((): boolean => {
            return !props.zoneId;
        });

        const emptyGeometry = computed((): boolean => {
            return !!geometries.value && geometries.value.filter((g) => g.isDeleted === false).length === 0;
        });

        const canAddGeometry = computed((): boolean => {
            return editMode.value && !addingNewGeometry.value;
        });

        const canSubmit = computed(() => {
            if (!editMode.value) {
                return !!geometry.value;
            } else {
                const numberOfGeometries = geometries.value.filter((g) => g.isDeleted === false).length;
                return numberOfGeometries > 0;
            }
        });

        const canRevert = computed((): boolean => {
            return !!geometries.value.length;
        });

        const title = computed((): string => {
            return editMode.value ? 'Bewerk zone' : 'Nieuwe zone';
        });

        const submitButtonLabel = computed((): string => {
            return editMode.value ? 'Bewaar veranderingen' : 'Zone toevoegen';
        });


        const geometryUpdate = (newGeometry: IFlatGeometry) => {
            geometry.value = newGeometry;
        }

        const geometryUploadFinished = (newGeometry: GeometryExtended) => {
            geometries.value.push(newGeometry);
            addingNewGeometry.value = false;
            _updateValidationOberserver();
        }

        const geometryBeforeDelete = (newGeometry: Geometry) => {
            geometryToBeDeleted.value = newGeometry;
        }

        const geometryDelete = () => {
            deletingGeometry.value = true;
            const index = geometries.value.findIndex((g) => g.blobName === geometryToBeDeleted.value.blobName);
            if (index > -1) {
                geometries.value.splice(index, 1);
            }
            deletingGeometry.value = false;
            _updateValidationOberserver();
        }

        const _updateValidationOberserver = () => {
            const observer = editZoneValidationObserver.value;
            // observer.refs["geometryCount"].setFlags({ pristine: false });
            first(observer.observers)?.refs['geometryCount']?.setFlags({ pristine: false });
        }


        const submit = () => {
            if (!canSubmit.value) {
                return;
            }
            isSending.value = true;
            const data = {
                organisationId: props.organisationId,
                alarmCentralTelephone: contact.value.phoneEmergency,
                contactName: contact.value.name,
                contactEmail: contact.value.email,
                contactTelephone: contact.value.phone,
                extraInformation: contact.value.message,
                mapRequestEmail: mailDestination.value,
                name: name.value,
                namespace: namespace.value,
                confirmation: mailSettings.value.confirmation,
                reminder1DayToRespond: mailSettings.value.reminder1DayToRespond,
                respondedNotInvolved: mailSettings.value.respondedNotInvolved,
                uploadImklFailed: mailSettings.value.uploadImklFailed,
                uploadImklSuccess: mailSettings.value.uploadImklSuccess
            } as UpdateKlipZoneInput | CreateKlipZoneInput;
            const saveOrUpdatePromise: Promise<any> = editMode.value ?
                _updateKlipZone(data) :
                _createKlipZone(data);

            saveOrUpdatePromise
                .then((response: EnvelopeOfKlipZoneCreated | EnvelopeOfKlimZoneCreated) => {
                    const id = response instanceof EnvelopeOfKlipZoneCreated ? response.result.id : props.zoneId;
                    if (editMode.value) {
                        _updateNamespaceZone({ id, organisationId: props.organisationId, data });
                    } else {
                        _createNamespaceZone({ id, organisationId: props.organisationId, data, isKlim: false });
                    }

                    router.replace({
                        name: routeEditOrganinsation.value.name,
                        params: {
                            organisationId: props.organisationId,
                        }
                    });
                })
                .finally(() => {
                    isSending.value = false;
                    addingNewGeometry.value = false;
                });
        }

        const cancelNewGeometry = () => {
            addingNewGeometry.value = false;
        }

        const addNewGeometry = () => {
            addingNewGeometry.value = true;
        }

        const deleteZone = () => {
            deletingZone.value = true;

            _deleteNamespaceZone({ id: props.zoneId, organisationId: props.organisationId, unaId: unaId.value }).then(async () => {
                root.$emit('modal-toggle', 'remove-zone');
                await router.replace({
                    name: routeEditOrganinsation.value.name,
                    params: {
                        organisationId: props.organisationId,
                        removedItem: props.zoneId
                    },
                });
            });
        }

        const _createKlipZone = async (createZoneInput: CreateKlipZoneInput) => {
            const klipData = {
                ...createZoneInput,
                geometryActivationDate:
                    geometry.value.activation.geometryActivationDate
                        ? dayjs(geometry.value.activation.geometryActivationDate[0]).format('YYYY-MM-DD 00:00:00')
                        : null,
                geometryBlobName: geometry.value.file.geometryBlobName,
                geometryLastModificationDate:
                    geometry.value.reference.geometryLastModificationDate
                        ? dayjs(geometry.value.reference.geometryLastModificationDate[0]).format('YYYY-MM-DD 00:00:00')
                        : null,
                geometryPrecision: geometry.value.reference.geometryPrecision,
                geometryReference: geometry.value.reference.geometryReference,
                geometryVersion: geometry.value.reference.geometryVersion,
            } as CreateKlipZoneInput;
            return await useKlipApiProxy().unaSettings_CreateKlipZone(klipData);
        }

        const _updateKlipZone = async (createZoneInput: UpdateKlipZoneInput) => {
            const klipData = {
                ...createZoneInput,
                id: props.zoneId,
                geometries: geometries.value.filter((g) => g.isDeleted === false).map((geometry) => {
                    return {
                        activationDate:
                            geometry.activationDate
                                ? dayjs(geometry.activationDate).format('YYYY-MM-DD 00:00:00')
                                : null,
                        blobName: geometry.blobName,
                        isActive: geometry.isActive,
                        lastModificationDate:
                            geometry.lastModificationDate
                                ? dayjs(geometry.lastModificationDate).format('YYYY-MM-DD 00:00:00')
                                : null,
                        precision: geometry.precision,
                        reference: geometry.reference,
                        version: geometry.version,
                    };
                }),
            } as UpdateKlipZoneInput;
            return await useKlipApiProxy().unaSettings_UpdateKlipZone(klipData);
        }

        onMounted(() => {
            addingNewGeometry.value = !!createMode.value;
        });

        const _onZoneIdChange = (newZoneId: string) => {
            if (!!newZoneId) {
                isLoading.value = true;
                useKlipApiProxy().unaSettings_GetUnaZoneEditView(newZoneId, props.organisationId)
                    .then((response) => {
                        initialName.value = response.result.zoneName;
                        name.value = response.result.zoneName;
                        unaDisplayName.value = response.result.unaName;
                        mailDestination.value = response.result.mapRequestEmail;
                        namespace.value = response.result.namespace;
                        geometries.value = response.result.geometries;
                        unaId.value = response.result.unaId;
                        contact.value = {
                            name: response.result.contactName,
                            phone: response.result.contactTelephone,
                            email: response.result.contactEmail,
                            message: response.result.extraInformation,
                            phoneEmergency: response.result.alarmCentralTelephone,
                        };
                        mailSettings.value = {
                            confirmation: response.result.confirmation,
                            confirmationExampleUri: response.result.confirmationExampleUri,
                            reminder1DayToRespond: response.result.reminder1DayToRespond,
                            reminder1DayToRespondExampleUri: response.result.reminder1DayToRespondExampleUri,
                            uploadImklFailed: response.result.uploadImklFailed,
                            uploadImklFailedExampleUri: response.result.uploadImklFailedExampleUri,
                            uploadImklSuccess: response.result.uploadImklSuccess,
                            uploadImklSuccessExampleUri: response.result.uploadImklSuccessExampleUri,
                            respondedNotInvolved: response.result.respondedNotInvolved,
                            respondedNotInvolvedExampleUri: response.result.respondedNotInvolvedExampleUri
                        };
                        geometries.value.forEach((g) => g.isDeleted = false);
                        uploadUrl.value = response.result.uploadZoneFileUrl;
                        isLoading.value = false;
                    });
            } else {
                isLoading.value = true;
                useKlipApiProxy().unaSettings_GetUnaZoneCreateView()
                    .then((response) => {
                        uploadUrl.value = response.result.uploadZoneFileUrl;
                        mailSettings.value = {
                            confirmation: true,
                            confirmationExampleUri: response.result.confirmationExampleUri,
                            reminder1DayToRespond: false,
                            reminder1DayToRespondExampleUri: response.result.reminder1DayToRespondExampleUri,
                            uploadImklFailed: false,
                            uploadImklFailedExampleUri: response.result.uploadImklFailedExampleUri,
                            uploadImklSuccess: false,
                            uploadImklSuccessExampleUri: response.result.uploadImklSuccessExampleUri,
                            respondedNotInvolved: false,
                            respondedNotInvolvedExampleUri: response.result.respondedNotInvolvedExampleUri
                        };
                        isLoading.value = false;
                    });
            }
        }

        watch(
            geometries,
            (newGeometries: GeometryExtended[]) => {
                if (!newGeometries.length) {
                    addingNewGeometry.value = true;
                }
                geometryCount.value = newGeometries.filter((g) => g.isDeleted === false).length;
            },
            { immediate: false, deep: true });

        watch(
            () => props.zoneId,
            _onZoneIdChange,
            { immediate: true });


        return {
            namespaceUrl,
            overlayUrl,
            helpdeskMail,
            zoneNameValidationRegex,
            namespaceRegex,

            editZoneValidationObserver,

            isLoading,
            name,
            initialName,
            mailDestination,
            namespace,
            contact,
            mailSettings,
            isSending,
            deletingZone,
            deletingGeometry,
            addingNewGeometry,
            geometry,
            geometries,
            geometryToBeDeleted,
            uploadUrl,
            unaDisplayName,
            geometryCount,
            unaId,

            routeEditOrganinsation,
            editMode,
            createMode,
            emptyGeometry,
            canAddGeometry,
            canSubmit,
            canRevert,
            title,
            submitButtonLabel,

            geometryUpdate,
            geometryUploadFinished,
            geometryBeforeDelete,
            geometryDelete,
            submit,
            cancelNewGeometry,
            addNewGeometry,
            deleteZone,
        }

    }
})
