import MriStore, { IMapRequestArchive } from '@/app/mri/mri-store';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component
export default class KlMriMaprequestArchiveDownload extends Vue {
  public confirmCheckbox: boolean = false;

  @Prop({ type: String, required: true })
  public maprequestId: string;

  @Prop({ type: String, required: true })
  public downloadUrl: string;

  @Prop({ type: Boolean, required: false, default: true })
  public showConfirmCheckbox: boolean;

  @Prop({ type: String, required: false, default: 'Download archiefpakket'})
  public downloadButtonText: string;

  @Getter(MriStore.store.getters.mapRequestArchives, { namespace: MriStore.store.namespace })
  private mapRequestArchives!: IMapRequestArchive[];

  @Watch('status', { immediate: true, deep: false })
  public onStatusChanged(status: string) {
    this.confirmCheckbox = !!status;
  }

  get fileUrl() {
    return !this.isDownloadButtonDisabled ? this.downloadUrl : null;
  }

  get downloadButtonClasses() {
    return [
      'vl-button', {
        'vl-button--disabled': this.isDownloadButtonDisabled,
      }];
  }

  get loading() {
    return this.status === 'loading';
  }

  get error() {
    return this.status === 'error';
  }

  get ready() {
    return this.status === 'ready';
  }

  get status() {
    return this.storedArchive ? this.storedArchive.status : null;
  }

  get storedArchive() {
    const mapRequestArchive = this.mapRequestArchives ? this.mapRequestArchives.filter((item) => item.id === this.maprequestId) : [];
    return mapRequestArchive.length ? mapRequestArchive[0] : null;
  }

  get isDownloadButtonDisabled() {
    return this.showConfirmCheckbox && !this.confirmCheckbox;
  }
}
