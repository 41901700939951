import { Component, Prop, Vue } from 'vue-property-decorator';
import { IApplicationMessages, IAlertNotification } from '@/api/klip-api.proxy';
import userLocalStorage from '@/storage/user-local-storage';
import { CodeLists } from '../../state/CodeListModule';
import { UserData } from '@/app/shared/state/UserDataModule';
import dayjs from 'dayjs';

@Component
export default class KlApplicationMessages extends Vue {
    public applicationMessages: IApplicationMessages = null;
    private isSessionTimeoutVisibleValue: boolean = false;
    private sessionTimeoutLocalStorageKey: string = 'sessiontimeout_redirect';

    get isBeta() {
        return CodeLists.isBeta;
    }

    get acmAuthentication(): boolean {
        return import.meta.env.VITE_ACM_AUTHENTICATION === 'true';
    }

    get betaMessage(): IAlertNotification {
        return {
            title: 'Let op! Dit is een test omgeving',
            message: 'Deze omgeving gebruik je enkel om te testen. Een echte planaanvraag doe je op <a href="https://klip.vlaanderen.be">klip.vlaanderen.be</a>',
            isClosable: false,
        };
    }

    get messages(): IAlertNotification[] {
        if (this.applicationMessages && this.applicationMessages.messages) {
            return this.applicationMessages.messages;
        }
        return [];
    }

    get whatsNew() {
        if (this.applicationMessages) {
            return this.applicationMessages.whatsNew;
        }
        return null;
    }

    get isSessionTimeoutVisible() {
        return this.isSessionTimeoutVisibleValue;
    }

    public closeMessage(id: string) {
        userLocalStorage.appendItemToArray('application_announcements', id);
        this.removeMessage(id);
    }

    public mounted() {
        this.GetapplicationMessages();
        this.initApplicationMessagePolling();
        this.initSessionTimeoutPolling();
        this.$root.$on('closed', () => {
            if (this.applicationMessages.whatsNew) {
                userLocalStorage.appendItemToArray('application_announcements', this.applicationMessages.whatsNew.id);
            }
        });
    }

    public login() {
        this.$signalrHub.stop(`${window.location.origin}/auth/login?returnUrl=${window.location.pathname}`);
    }

    private GetapplicationMessages() {
        this.$client.messages_GetApplicationMessages().then((response) => {
            this.applicationMessages = response.result;
            if (!this.applicationMessages.messages && !this.applicationMessages.whatsNew) {
                return;
            }

            const removedMessageIds: string[] = userLocalStorage.getItem('application_announcements', true) as string[];
            if (!removedMessageIds) {
                return;
            }

            if (this.applicationMessages.messages) {
                this.applicationMessages.messages = this.applicationMessages.messages.filter((message) => !removedMessageIds.includes(message.id));
            }

            if (this.applicationMessages.whatsNew && removedMessageIds.includes(this.applicationMessages.whatsNew.id)) {
                this.applicationMessages.whatsNew = null;
            }
        });
    }

    private initApplicationMessagePolling() {
        if (!this.isSessionTimeoutVisibleValue) {
            setTimeout(() => {
                this.GetapplicationMessages();
                this.initApplicationMessagePolling();
            }, 300000);
        }
    }

    private initSessionTimeoutPolling() {
        setTimeout(() => {
            if (dayjs(UserData.user.sessionTimeout) < dayjs()) {
                userLocalStorage.setItem(this.sessionTimeoutLocalStorageKey, this.$router.currentRoute.path);
                // Explicit call to disconnect from signalr
                this.$signalrHub.stop();
                this.isSessionTimeoutVisibleValue = true;
            } else {
                this.initSessionTimeoutPolling();
            }
        }, 10000);
    }

    private removeMessage(id: string) {
        this.applicationMessages.messages = this.applicationMessages.messages.filter((message) => message.id !== id);
    }
}
