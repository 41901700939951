import {defineComponent} from 'vue';

export default defineComponent({
    name: 'KlContactCard',
    props: {
        title: {
            type: String, required: true,
        },
        subtitle: {
            type: String, required: false, default: undefined,
        },
        titleTag: {
            type: String, required: false, default: 'span',
        },
        address: {
            type: String, required: false, default: undefined,
        },
        phone: {
            type: String, required: false, default: undefined,
        },
        modPhoneClickable: {
            type: Boolean, required: false, default: false,
        },
        email: {
            type: String, required: false, default: undefined,
        },
        modEmailClickable: {
            type: Boolean, required: false, default: false,
        },
        emailLink: {
            type: String, required: false, default: undefined,
        },
        message: {
            type: String, required: false, default: undefined,
        },
        emergencyPhone: {
            type: String, required: false, default: undefined,
        },
    },
    setup(props) {
    }
})
