import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

@Component
export default class KlDatatable extends Vue {
    public clickable: boolean;
    public rows: IRow[] = [];
    public totalRows: number = 0;
    public totalPages: number = 1;
    public pagerFrom: number = 1;
    public masterCheckbox: boolean = false;
    public currentPage: number;

    @Prop({ required: true })
    public dataset: [];

    @Prop({ type: Array, required: true })
    public columns: IColumn[];

    @Prop({ type: Object, required: true })
    public meta: { totalRows: number, resultsPerPage: number, currentPage?: number | undefined };

    @Prop({ type: Boolean, default: true })
    public fetching: boolean;

    @Prop({ type: Boolean, default: true })
    public headerVisible: boolean;

    @Prop({ type: Boolean, default: true })
    public pagerVisible: boolean;

    @Prop({ type: Boolean, default: false })
    public modZebra: boolean;

    @Prop({ type: Boolean, default: false })
    public modClickableRows: boolean;

    @Prop({ type: Boolean, default: false })
    public modCheckableRows: boolean;

    public records: any[] = null;

    get classes() {
        return [
            'kl-datatable', {
                'kl-datatable--loading': this.fetching,
            }];
    }

    /**
     * Defines if sorting/paging is handled
     * clientside or should request data serverside
     */
    get serverSideData() {
        return (this.meta && this.meta.totalRows ? this.records.length !== this.meta.totalRows : false);
    }

    /**
     * How many empty rows are shown
     * in loading state
     */
    get placeholderRows() {
        return this.rows.length ? this.rows.length : this.meta.resultsPerPage;
    }

    get placeholderColspan() {
        return (this.columns || []).filter((column) => column.isVisible).length + Number(this.modCheckableRows);
    }

    get spinnerPosition() {
        return this.placeholderRows > 4 ? 4 : 1;
    }

    get hasActions() {
        return !!this.$scopedSlots.actions;
    }

    get resultsPerPage() {
        return this.meta.resultsPerPage || 999999999;
    }

    get checkedRows() {
        return this.modCheckableRows && this.rows ? this.rows.filter((row) => row.checked) : null;
    }

    public mounted() {
        this.records = this.dataset;
    }

    @Watch('checkedRows', { immediate: false, deep: false })
    public onCheckedRowsChanged(checkedRows: IRow[]) {
        this.masterCheckbox = (checkedRows.length === this.rows.length);
    }

    public cellClicked(row: IRow) {
        if (this.modClickableRows) {
            this.$emit('row-clicked', row);
        }
    }

    public rowClasses(row: IRow) {
        return [
            'kl-datatable__row', {
                'kl-datatable__processing': row.type === 'processing',
                'kl-datatable__row--error': row.error,
            }];
    }

    /**
     * Watch for changes on the dataset prop
     * @NOTE When there's less processing rows than
     * before, hide the present processing rows before
     * refreshing the rows
     * @param newDataset
     * @param oldDataset
     */
    @Watch('dataset', { immediate: true, deep: true })
    public onDataSetChanged(newDataset: any[], oldDataset: any[]) {
        const rows = this.rowsForPage(0, newDataset);
        const oldProcessingRows = (this.rows || []).filter((item) => item.type === 'processing').length;
        const newProcessingRows = (rows || []).filter((item) => item.type === 'processing').length;
        const processingDiff = oldProcessingRows - newProcessingRows;
        if (processingDiff > 0) {
            for (let i = (oldProcessingRows - processingDiff); i < oldProcessingRows; i++) {
                this.rows[i].visible = false;
            }
            setTimeout(() => {
                this.records = newDataset;
                this.rows = rows;
            }, 500);
        } else {
            this.records = newDataset;
            this.rows = rows;
        }
    }

    /**
     * Watch for changes on the meta
     * prop to update pagination when the
     * record count is known
     * @param val
     */
    @Watch('meta', { immediate: true, deep: true })
    public onMetaChanged(val: { totalRows: number, resultsPerPage: number, currentPage?: number | undefined }) {
        this.totalRows = this.meta && this.meta.totalRows ? this.meta.totalRows : 0;
        this.totalPages = Math.ceil(this.totalRows / this.resultsPerPage);
        this.currentPage = this.meta.currentPage ? this.meta.currentPage : 1;
    }

    /**
     * Return a limited set of rows for
     * the requested page based on
     * the resultsPerPage setting
     * @param page
     * @param data
     */
    public rowsForPage(page: number, data: any[]) {
        return data ? data.slice(page * this.resultsPerPage,
            (page + 1) * this.resultsPerPage) : [];
    }

    /**
     * Navigate to the requested page
     * @param page
     */
    public goToPage(page: number) {
        this.currentPage = page;
        this.$emit('pager-clicked', page);
        if (!this.serverSideData) {
            this.rows = this.rowsForPage(page, this.records);
        }
    }

    /**
     * Get the icon according to the
     * actual sorting of type of a column
     * @param column
     */
    public sortIcon(column: IColumn) {
        if (column.sortable && column.direction) {
            return column.type === 'Number' ? 'arrange-' + (column.direction === 1 ?
                '1-to-9' : '9-to-1') : column.type === 'String' ? 'arrange-' + (column.direction === 1 ? 'a-to-z' : 'z-to-a') : (column.direction === 1 ?
                    'arrow-up' : 'arrow-down');
        }
        return '';
    }

    /**
     * Sort the datatable for a specified column
     * in the provided direction (asc, desc)
     * @param column
     * @param direction
     */
    public sort(column: IColumn) {
        if (column.sortable) {
            // Update pager
            this.currentPage = 1;
            // Unset current sort order
            this.columns.filter((obj) => obj !== column).map((obj) => obj.direction = 0);
            // Reverse sort order or set to asc if undefined
            // In case of dates we use desc as default sort order
            column.type === 'Date' ? column.direction === 2 ? column.direction = 1 :
                column.direction = 2 : column.direction === 1 ? column.direction = 2 : column.direction = 1;
            // If sorting is handled clientside
            if (!this.serverSideData) {
                // Sort by provided function, numerically or alphabetically
                if (column.sortable instanceof Function) {
                    column.sortable(this.records, column);
                } else {
                    this.records.sort((a, b) => {
                        return collator.compare(a[column.key], b[column.key]);
                    });
                    // Reverse in case of Desc
                    if (column.direction === 2) {
                        this.records.reverse();
                    }
                }
                // Limit results
                this.rows = this.rowsForPage(0, this.records);
            }
            // Emit event to hook into
            this.$emit('column-clicked', column);
        }
    }

    /**
     * Emitted event from the pagination
     * component inside the datatable
     * @param pageNumber
     */
    public changePage(pageNumber: number) {
        this.goToPage(pageNumber);
    }

    public toggleCheckbox() {
        this.$emit('checkbox-change', this.checkedRows);
    }

    public toggleCheckboxes(checked: boolean) {
        this.rows = this.rows.map((row) => ({ ...row, checked }));
        this.$emit('checkbox-change', this.checkedRows);
    }

    /**
     * Pass the cell content to a custom
     * parser method in case it exists
     * @param row
     * @param column
     */
    public parse(row: IRow, column: IColumn) {
        return column.parser instanceof Function ? column.parser(row, column) : {
            template: `<span>${row[column.key]}</span>`,
        };
    }
}
