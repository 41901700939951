import { KlRouteConfig } from '@/router/router-vue';
import { klipIndex } from '@/router/router';

export const UnaIndex: KlRouteConfig = {
    path: '/una',
    name: 'una',
    component: () => import(/* webpackChunkName: "una" */ './index.vue'),
    meta: {
        authorize: { roles: ['una_manager', 'una_operator', 'una_reader', 'klim_admin'] },
        breadcrumb: { title: '', parent: klipIndex },
    },
};

export const UnaMapRequest: KlRouteConfig = {
    path: '/una/maprequest/:id/:zoneId/:archived',
    name: 'unamaprequest',
    component: () => import(/* webpackChunkName: "una" */ './map-request.vue'),
    meta: {
        authorize: { roles: ['una_operator', 'una_reader'] },
        breadcrumb: { title: 'Planaanvraag', parent: UnaIndex },
    },
};

export const UnaViewer: KlRouteConfig = {
    path: '/una/viewer/:mapRequestId/:zoneId',
    name: 'unaviewer',
    component: () => import('./../viewer/index.vue'),
    meta: {
        authorize: { roles: ['una_operator', 'una_reader', 'helpdesk', 'admin'] },
        breadcrumb: { title: 'Leidingviewer', parent: UnaMapRequest },
    },
};

export default [
    UnaIndex,
    UnaMapRequest,
    UnaViewer,
];
