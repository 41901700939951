import store from '@/store';
import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators';
import { ShoppingCart } from './ShoppingCartModule';
import { UserData } from '@/app/shared/state/UserDataModule';
import { PaymentTypes } from '@/api/klip-api.proxy';

export interface IOnlinePaymentData {
    paymentType: string;
    numberOfMapRequests: number;
    hasMapRequestExtras: boolean;
    hasMapRequestsWithImkl: boolean;
    totalPrice: number;
}

@Module({
    dynamic: true,
    store,
    name: 'PaymentStatusModule',
    namespaced: true,
})
export default class PaymentStatusVuexModule extends VuexModule {
    public static readonly namespace = 'PaymentStatusModule';

    private hasError: boolean = false;
    private hasPrepaidError: boolean = false;
    private hasUncertainPrepaidError: boolean = false;
    private onlinePayment: IOnlinePaymentData = null;
    private mapRequestPrice: number = null;
    private prepaidAmount: number = null;
    private hasPrepaidPaymentMessage: boolean = false;

    @Mutation
    setPaymentErrorMutation(value: boolean) {
        this.hasError = value;
    }

    @Action
    setPaymentError(value: boolean) {
        this.setPaymentErrorMutation(value);
    }

    @Mutation
    setPrepaidPaymentErrorMutation(value: boolean) {
        this.hasPrepaidError = value;
    }

    @Action
    setPrepaidPaymentError(value: boolean) {
        this.setPrepaidPaymentErrorMutation(value);
    }

    @Mutation
    setHasUncertainPrepaidErrorMutation(value: boolean) {
        this.hasUncertainPrepaidError = value;
    }

    @Action
    setHasUncertainPrepaidError(value: boolean) {
        this.setHasUncertainPrepaidErrorMutation(value);
    }

    @Mutation
    setOnlinePaymentMutation(onlinePaymentData: IOnlinePaymentData) {
        this.onlinePayment = onlinePaymentData;
        if (this.mapRequestPrice !== null) {
            this.onlinePayment.totalPrice += this.mapRequestPrice;
        }
    }

    @Action
    setOnlinePayment(onlinePaymentData: IOnlinePaymentData) {
        this.setOnlinePaymentMutation(onlinePaymentData);
    }

    @Mutation
    clearOnlinePaymentMutation() {
        this.onlinePayment = null;
        this.mapRequestPrice = null;
    }

    @Action
    clearOnlinePayment() {
        this.clearOnlinePaymentMutation();
    }

    @Mutation
    clearPaymentMessagesMutation() {
        this.hasError = false;
        this.hasPrepaidError = false;
        this.hasUncertainPrepaidError = false;
        this.onlinePayment = null;
        this.mapRequestPrice = null;
    }

    @Action
    clearPaymentMessages() {
        this.clearPaymentMessagesMutation();
    }

    @Mutation
    setMapRequestPriceMutation(price: number) {
        this.mapRequestPrice = price;
    }

    @Action
    setMapRequestPrice(price: number) {
        this.setMapRequestPriceMutation(price);
    }

    @Mutation
    setPrepaidAmountMutation(prepaidAmount: number) {
        this.prepaidAmount = prepaidAmount;
    }

    @Action
    setPrepaidAmount(prepaidAmount: number) {
        this.setPrepaidAmountMutation(prepaidAmount);
    }

    @Mutation
    clearPrepaidAmountMutation() {
        this.prepaidAmount = null;
    }

    @Action
    clearPrepaidAmount() {
        this.clearPrepaidAmountMutation();
    }

    @Mutation
    setPrepaidPaymentMessageMutation() {
        this.hasPrepaidPaymentMessage = true;
    }

    @Action
    setPrepaidPaymentMessage() {
        this.setPrepaidPaymentMessageMutation();
    }

    @Mutation
    clearPrepaidPaymentMessageMutation() {
        this.hasPrepaidPaymentMessage = false;
    }

    @Action
    clearPrepaidPaymentMessage() {
        this.clearPrepaidPaymentMessageMutation();
    }

    public get isPaymentMessagesVisible(): boolean {
        return this.isShoppingCartPaymentVisible || this.hasError || this.hasOnlinePaymentData;
    }

    public get hasPaymentError() {
        return this.hasError;
    }

    public get hasPrepaidPaymentError() {
        return this.hasPrepaidError;
    }

    public get hasUncertainPrepaidPaymentError() {
        return this.hasUncertainPrepaidError;
    }

    public get isShoppingCartPaymentVisible(): boolean {
        return UserData.paymentType !== PaymentTypes.Invoice && ShoppingCart.isShoppingCartActive && ShoppingCart.numberOfItems > 0;
    }

    public get numberOfItemsInCart(): number {
        return ShoppingCart.numberOfItems;
    }

    public get hasOnlinePaymentData(): boolean {
        return this.onlinePayment != null;
    }

    public get onlinePaymentData(): IOnlinePaymentData {
        return this.onlinePayment;
    }

    public get hasPrepaidAmount() {
        return this.prepaidAmount !== null;
    }

    public get prepaidAmountData() {
        return this.prepaidAmount;
    }

    public get hasPrepaidPaymentMessageData() {
        return this.hasPrepaidPaymentMessage;
    }
}

export const PaymentStatus = getModule(PaymentStatusVuexModule);
