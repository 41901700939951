import { Component, Prop, Vue } from 'vue-property-decorator';
import KlUpload from '@/app/shared/components/kl-upload/kl-upload.vue';
import { EnvelopeOfString, EnvelopeOfDbGeometryText } from '@/api/klip-api.proxy';
import { IConfig } from '@/app/shared/components/kl-upload/config';
import {IGuideline} from '@/app/shared/components/kl-upload/guideline';


@Component({
    components: {
        KlUpload,
    },
})
export default class KlUploadZone extends Vue {
    public error: boolean = false;
    public errorMessages: string[] = [];

    @Prop({ type: Boolean, required: false, default: false })
    public forMaprequest: boolean;

    @Prop({ type: Boolean, required: false, default: false })
    public useClientSideProcessing: boolean;

    @Prop({ type: Boolean, required: false, default: false })
    public modHideGuideLines: boolean;

    @Prop({ type: Number, required: false, default: 1 })
    public maxFiles: number;

    @Prop({ type: String, required: false, default: null })
    public moreInfoLink: string;

    get enableMultiFiles(): boolean {
        return this.maxFiles > 1;
    }

    get config(): IConfig {

        const guidelines: IGuideline[] = [];
        guidelines.push({
            label: 'Aanvaarde bestandsformaten',
            value: 'GeoJSON, Shapefile of WKT',
        });
        if (this.forMaprequest) {
            guidelines.push({
                label: 'Maximale oppervlakte',
                value: '100.000m²',
            });
        }
        guidelines.push({
            label: 'Maximale bestandsgrootte',
            value: '64kB (als WKT, UTF-16 gecodeerd)',
        });
        guidelines.push({
            label: 'Projectie',
            value: 'Lambert 72',
        });
        if (this.enableMultiFiles) {
            guidelines.push({
                value: 'Bevat geen multipolygonen',
            });
        }
        else {
            guidelines.push({
                value: 'Bevat maar 1 polygoon',
            });
        }

        return {
            url:  import.meta.env.VITE_BASE_URI + '/bff/Mri/maprequest/zone/import',
            title: '',
            uploadLabel: this.enableMultiFiles ? 'Zone(s) toevoegen' : 'Zone toevoegen',
            uploadDragText: this.enableMultiFiles ? 'Sleep bestand(en) naar hier om toe te voegen.' : 'Sleep het bestand naar hier om toe te voegen.',
            guidelines: guidelines,
            files: {
                amount: 1,
                size: 0.064,
                message: 'De grootte van het bestand mag maximaal 64kB zijn.',
                types: '.json,.geojson,.shp,.shape,.wkt',
            },
        };
    }

    public async filesAdded(files: File[]) {
        if (!this.useClientSideProcessing) {
            return;
        }

        this._removeAllFiles();

        this.$emit('files-upload', files);
    }

    public onProcessing(e) {
        if (!this.useClientSideProcessing) {
            return;
        }
        this._removeAllFiles();
    }

    private _removeAllFiles() {
        // TODO: refactor into something less yukki
        // (i'm using the inner dropzone.js component)
        // remove files from further processing in the vl-upload component
        ((this.$refs['zone-upload'] as any).$refs['vlUpload'] as any).removeFiles();
    }

    public onUploadSuccess(file: object, response: EnvelopeOfDbGeometryText) {
        if (!response.result) {
            return;
        }
        this.$emit('upload-success', response.result.wkt);
    }

    public onUploadError(file: object, message: EnvelopeOfString, xhr: object) {
        if (xhr) {
            this.error = true;
            if (message && message.messages) {
                this.errorMessages = this.errorMessages.concat(message.messages);
            }
        }
    }

    public reset() {
        this.error = false;
        this.errorMessages = [];
    }
}
