import {SettingsDataService} from '@/app/settings/settings-store';
import { OrganisationUnaSettings } from '@/api/klip-api.proxy';
import { UserData } from '@/app/shared/state/UserDataModule';
import { escape } from 'html-escaper';
import { RouteConfig } from 'vue-router';
import { EditUnaZone, NewUnaZone } from '@/app/settings/components/kl-organisation-settings/kl-organisation-routes';
import { EditUnaKlimZone, NewUnaKlimZone, } from '@/app/settings/components/kl-organisation-settings/kl-organisation-routes';
import { UnaKlimZone, UnaZone } from '@/app/settings/components/kl-organisation-settings/kl-organisation-routes'
import * as KlipApi from '@/api/klip-api.proxy';
import {computed, defineComponent, onMounted, ref} from 'vue';
import {useRoute} from '@/plugins/routes';


export default defineComponent({
    name: 'KlOrganisationUnaZones',
    props: {
        value: {
            type: Object as () => OrganisationUnaSettings,
            default: () => ({}),
            required: true
        },
    },
    setup(props) {

        const route = useRoute();

        const zonesFetching = ref<boolean>(true);

        const displayName = computed((): string => props.value.displayName);

        const zonesColumns = computed( (): IColumn[] => [{
            key: 'zoneName',
            label: 'Zone',
            sortable: false,
            width: 55,
            isVisible: true,
            parser: _parseCell,
        }, {
            key: 'namespace',
            label: 'Namespace',
            sortable: false,
            isVisible: true,
            parser: _parseCell,
        }]);

        // @Getter(SettingsStore.store.getters.namespaceZones, { namespace: SettingsStore.store.namespace })
        const _namespaceZones = computed((): KlipApi.IZoneNamespace[] => SettingsDataService.namespaceZones);

        // @Action(SettingsStore.store.actions.loadNameSpaceZone, { namespace: SettingsStore.store.namespace })
        const _loadNameSpaceZone = (organisationId: string) => SettingsDataService.loadNameSpaceZone(organisationId);


        const namespaces = computed(() => {
            return _mapNamespaces(_namespaceZones.value);
        });

        const hasKlimZone = computed(() => {
            return _namespaceZones.value.filter(n => n.isKlimZone).length === 1;
        });

        const routeEditUnaZone = computed((): RouteConfig => {
            return EditUnaZone;
        });

        const routeUnaZoneCreate = computed(() => {
            return NewUnaZone;
        });

        const routeUnaZone = computed((): RouteConfig => {
            return UnaZone;
        });

        const routeUnaKlimZone = computed((): RouteConfig => {
            return UnaKlimZone;
        });

        const routeUnaKlimZoneCreate = computed(() => {
            return NewUnaKlimZone;
        });

        const routeEditUnaKlimZone = computed((): RouteConfig => {
            return EditUnaKlimZone;
        });

        const editable = computed((): boolean => {
            return UserData.hasRole(['una_manager', 'klim_admin']);
        });

        const isKlimAdmin = computed((): boolean => {
            return UserData.hasRole(['klim_admin']);
        });



        const _mapNamespaces = (namespaces: KlipApi.IZoneNamespace[]) => {
            if (!namespaces) {
                return [];
            }

            return namespaces
                .filter((item) => item.zoneId !== route.params.removedItem)
                .sort((a, b) => Number(b.isKlimZone) - Number(a.isKlimZone))
                .map((namespace) => ({
                    ...namespace,
                    link: {
                        route: namespace.isKlimZone ? _getRouteKlim() : _getRouteUna(),
                        params: {
                            organisationId: props.value.organisationId,
                            zoneId: namespace.zoneId,
                        },
                    },
                }));
        }

        const _getRouteKlim = (): RouteConfig => {
            if (editable.value && isKlimAdmin.value) {
                return routeEditUnaKlimZone.value;
            } else {
                return routeUnaKlimZone.value;
            }
        }

        const _getRouteUna = (): RouteConfig => {
            if (editable.value) {
                return routeEditUnaZone.value;
            } else {
                return routeUnaZone.value;
            }
        }

        const _parseCell = (row: IRow, column: IColumn) => {
            if (column.key === zonesColumns.value[0].key) {
                return {
                    template: `
                <kl-router-link :to='${JSON.stringify(row.link.route)}' :params='${JSON.stringify(row.link.params)}'>
                    <div class='zone-name'>
                        ${row.isKlimZone ? `<img src="/img/logo-klim.svg" class="klim-pictogram" alt="KLIM pictogram" />` : ''}
                        <span>${row.zoneName}</span>
                    </div>
                    <span class="vl-u-text--light">${escape(row.zoneId)}</span>
                </kl-router-link>`,
                };
            } else {
                return {
                    template: `<kl-router-link :to='${JSON.stringify(row.link.route)}' :params='${JSON.stringify(row.link.params)}'> ${escape(row[column.key])}</kl-router-link>`,
                };
            }
        }

        onMounted(() => {
            //displayName.value = props.value.displayName;
            _loadNameSpaceZone(props.value.organisationId).then(() => {
                zonesFetching.value = false;
            });
        });

        return {
            displayName,
            zonesFetching,
            zonesColumns,
            namespaces,
            hasKlimZone,
            routeEditUnaZone,
            routeUnaZoneCreate,
            routeUnaZone,
            routeUnaKlimZone,
            routeUnaKlimZoneCreate,
            routeEditUnaKlimZone,
            editable,
            isKlimAdmin,
        }
    }
})
