import {computed, defineComponent, getCurrentInstance, inject, onMounted, ref} from "vue";
import * as KlipApi from "@/api/klip-api.proxy";
import dayjs from "dayjs";

export default defineComponent({
    setup() {
        const root = getCurrentInstance().proxy;
        const proxy = inject<KlipApi.IKlipApiProxy>('klipApiProxy');
        const today = dayjs().format('DD/MM/YYYY');

        const activationDate = ref<string[]>([today]);
        const activationMinDate = ref<string>(today);

        const pmklVersion = ref<string>();
        const wfsVersion = ref<string>();
        const klipPackage = ref<string>();
        const minKlipPackage = ref<string>();
        const viewer = ref<string>();
        const minViewer = ref<string>();
        const releaseNotes = ref<string>();

        const savingVersions = ref<boolean>(false);
        const isVersionsDataTableLoading = ref<boolean>(true);
        const versions = ref<KlipApi.VersionDto[]>([]);
        const allVersions = ref<KlipApi.VersionDto[]>([]);
        const showAllVersions = ref<boolean>(false);
        const versionsMeta = ref<{ totalRows: number, resultsPerPage: number, currentPage: number | undefined }>({ totalRows: 1, resultsPerPage: 1, currentPage: 1 });

        const cellParser = (row: IRow, column: IColumn) => {
            if(row[column.key])
            {
                return {
                    template: `<div>${dayjs(row[column.key]).format('DD/MM/YYYY')}</div>`,
                };

            }
            return {
                template: `<div>&nbsp;</div>`
            };
        };

        const columns = ref<IColumn[]>([
            {
                key: 'activationDate',
                label: 'Activatie datum',
                sortable: false,
                width: 10,
                isVisible: true,
                parser: cellParser,
            },
            {
                key: 'pmklVersion',
                label: 'Pmkl',
                sortable: false,
                width: 5,
                isVisible: true
            },
            {
                key: 'wfsVersion',
                label: 'WFS',
                width: 5,
                sortable: false,
                isVisible: true,
            },
            {
                key: 'packageVersion',
                label: 'Package',
                sortable: false,
                width: 5,
                isVisible: true,
            },
            {
                key: 'minimumPackageVersion',
                label: 'MinPackage',
                sortable: false,
                width: 5,
                isVisible: true,
            },
            {
                key: 'viewerVersion',
                label: 'Viewer',
                sortable: false,
                width: 5,
                isVisible: true,
            },
            {
                key: 'minimumViewerVersion',
                label: 'MinViewer',
                sortable: false,
                width: 5,
                isVisible: true,
            },
            {
                key: 'releaseNotes',
                label: 'ReleaseNotes',
                sortable: false,
                width: 20,
                isVisible: true,
            }
        ]);

        const toggleHistoryButtonText = computed(() => {
           return showAllVersions.value ? 'Verberg historiek' : 'Toon historiek';
        });

        const toggleHistory = () => {
            const show = !showAllVersions.value
            if(show) {
                versions.value = allVersions.value;
            }else{
                versions.value = [allVersions.value[0]];
            }
            showAllVersions.value = show;
        };

        const addVersionToUi = () => {
            allVersions.value.unshift({
                activationDate: activationDate.value[0],
                packageVersion: klipPackage.value,
                pmklVersion: pmklVersion.value,
                wfsVersion: wfsVersion.value,
                minimumViewerVersion: minViewer.value,
                viewerVersion: viewer.value,
                minimumPackageVersion: minKlipPackage.value,
                releaseNotes: releaseNotes.value,
            } as KlipApi.VersionDto);
            versions.value = showAllVersions.value ? allVersions.value :  [allVersions.value[0]];
        }

        const saveVersion = async () => {
            savingVersions.value = true;
            const newVersion =
            {
                activationDate: dayjs(activationDate.value[0]).format('YYYY-MM-DD 00:00:00'),
                packageVersion: klipPackage.value,
                pmklVersion: pmklVersion.value,
                wfsVersion: wfsVersion.value,
                minimumViewerVersion: minViewer.value,
                viewerVersion: viewer.value,
                minimumPackageVersion: minKlipPackage.value,
                releaseNotes: releaseNotes.value,
            } as KlipApi.AddVersionRequest;
            await proxy.operations_AddVersion(newVersion);
            savingVersions.value = false;
            addVersionToUi();
            root.$vnode.context.$root.$emit('modal-toggle', 'new-version-modal');
        };

        const onCloseNewVersionModal = () => {
            savingVersions.value = false;
        };

        const loadVersions = async () => {
            const response = await proxy.operations_GetVersions();
            if(response.isSuccessStatusCode){
                const latestVersion =response.result[0];
                allVersions.value = response.result;
                versions.value = [latestVersion];
                versionsMeta.value.totalRows = response.result.length;
                versionsMeta.value.resultsPerPage = response.result.length;
                isVersionsDataTableLoading.value = false;

                pmklVersion.value = latestVersion.pmklVersion;
                wfsVersion.value = latestVersion.wfsVersion;
                klipPackage.value = latestVersion.packageVersion;
                minKlipPackage.value = latestVersion.minimumPackageVersion;
                minViewer.value = latestVersion.minimumPackageVersion;
                viewer.value = latestVersion.viewerVersion;
            }
        };

        onMounted(async () => {
            await loadVersions();
        });

        return {
            isVersionsDataTableLoading,
            savingVersions,
            showAllVersions,
            versions,
            activationDate,
            activationMinDate,
            pmklVersion,
            wfsVersion,
            klipPackage,
            minKlipPackage,
            viewer,
            minViewer,
            releaseNotes,
            columns,
            versionsMeta,
            toggleHistory,
            toggleHistoryButtonText,
            saveVersion,
            onCloseNewVersionModal
        };
    }
});
