import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Polygon from 'ol/geom/Polygon';
import Feature from 'ol/Feature';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Collection from 'ol/Collection';
import { Vector as LayerVector } from 'ol/layer';
import { Vector as SourceVector } from 'ol/source';

@Component
export default class KlMaprequestMap extends Vue {
    public map: any = null;

    @Prop({ required: false })
    public zoneGeometry: any;

    @Prop({ type: Object, required: false })
    public downloadUrls: { [key: string]: string };

    get maprequestZoneFeature(): Feature {
        const maprequestPolygon = new Polygon(this.geometry.coordinates);
        const maprequestFeature = new Feature(maprequestPolygon);
        maprequestFeature.setStyle(new Style({
            stroke: new Stroke({
                color: 'rgba(21, 155, 170, 0.8)',
                width: 1,
            }),
        }));
        return maprequestFeature;
    }

    get donutFeature() {
        const bounds = [20000, 246000, 259000, 150000];
        const donutPolygon = new Polygon([[
            [bounds[0], bounds[1]],
            [bounds[0], bounds[3]],
            [bounds[2], bounds[3]],
            [bounds[2], bounds[1]],
            [bounds[0], bounds[1]],
        ]]);
        const mapRequestRing = (this.maprequestZoneFeature.getGeometry() as Polygon).getLinearRing(0);
        donutPolygon.appendLinearRing(mapRequestRing);
        const donut = new Feature(donutPolygon);
        donut.setStyle(new Style({
            fill: new Fill({
                color: 'rgba(255, 255, 255, 0.5)',
            }),
        }));
        return donut;
    }

    get geometry(): { coordinates: number[][][] } {
        if (typeof this.zoneGeometry === 'string') {
            return JSON.parse(this.zoneGeometry);
        }
        return this.zoneGeometry;
    }

    public zoomToMapRequestZone() {
        Vue.nextTick(() => {
            (this.$refs.map as any).zoomToExtent(this.maprequestZoneFeature.getGeometry().getExtent(), 1);
        });
    }

    public drawZoneGeometry() {
        if (this.map && this.geometry && this.maprequestZoneFeature && this.donutFeature) {
            const maprequestZoneLayer = new LayerVector({
                source: new SourceVector({ features: new Collection() }),
            });

            maprequestZoneLayer.getSource().addFeature(this.maprequestZoneFeature);
            maprequestZoneLayer.getSource().addFeature(this.donutFeature);

            this.map.addLayer(
                maprequestZoneLayer,
            );

            this.zoomToMapRequestZone();
        }
    }

    public toggleSidebar() {
        (this.$refs.sidebar as any).toggle();
    }

    public mounted() {
        this.map = (this.$refs.map as any).instance;
        this.drawZoneGeometry();
    }
}
