import { Component, Vue, Watch } from 'vue-property-decorator';
import { UserData } from '../../state/UserDataModule';
import { ErrorModule } from '../../state/ErrorModule';

@Component
export default class KlApplicationErrors extends Vue {
    public isError401: boolean = false;
    public isError403: boolean = false;
    public isError404: boolean = false;
    public isError400Or405Or500: boolean = false;
    public isRoleError: boolean = false;
    public helpdeskMail: string = import.meta.env.VITE_EMAIL_HELPDESK;

    get statusCode() {
        return ErrorModule.getStatusCode;
    }

    get correlationId() {
        return ErrorModule.getCorrelationId;
    }

    get validationError() {
        return UserData.user.validationError;
    }

    get acmAuthentication(): boolean {
        return import.meta.env.VITE_ACM_AUTHENTICATION === 'true';
    }

    get username(): string {
        return UserData.fullName + (UserData.user.organisation ? ' - ' + UserData.user.organisation : '');
    }

    @Watch('statusCode', { immediate: false, deep: true })
    public onStatusCodeChanged() {
        this.checkErrorCode();
    }

    public returnToStart() {
        this.$router.push('/');
    }

    public tryAgain() {
        this.$router.go(0);
    }

    public logout() {
        if (this.acmAuthentication) {
            this.$signalrHub.stop(`${window.location.origin}/auth/logout`);
        } else {
            this.$signalrHub.stop(`${window.location.origin}/geosecure_auth/logout`);
        }

    }

    public checkErrorCode() {
        const errorCode = this.statusCode;
        if (UserData.user.roleError) {
            this.isRoleError = true;
        } else if (errorCode === '401') {
            this.isError401 = true;
        } else if (errorCode === '403') {
            this.isError403 = true;
        } else if (errorCode === '400' || errorCode === '405' || errorCode === '500') {
            this.isError400Or405Or500 = true;
        } else {
            this.isError404 = true;
        }
    }

    public mounted() {
        this.checkErrorCode();
    }

    public copyToClipBoard() {
        navigator.clipboard.writeText(ErrorModule.getCorrelationId);
    }
}
