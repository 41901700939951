import { VNode } from 'vue';
import { DirectiveBinding } from 'vue/types/options';
import { UserData } from '../state/UserDataModule';

export default {
    bind: (el: HTMLElement, binding: DirectiveBinding, vnode: VNode) => {
        if (!UserData.hasRole(Array.isArray(binding.value) ? binding.value : [binding.value])) {
            if (binding.modifiers.disable) {
                el.setAttribute('disabled', '');
            } else {
                commentNode(el, vnode);
            }
        }
    },
};

function commentNode(el: HTMLElement, vnode: VNode) {
    const comment = document.createComment(' ');

    Object.defineProperty(comment, 'setAttribute', {
        value: (): any => undefined,
    });

    vnode.text = ' ';
    vnode.elm = comment;
    vnode.isComment = true;
    vnode.context = undefined;
    vnode.tag = undefined;
    vnode.data.directives = undefined;

    if (vnode.componentInstance) {
        // @ts-ignore
        vnode.componentInstance.$el = comment;
    }

    if (el.parentNode) {
        el.parentNode.replaceChild(comment, el);
    }
}
