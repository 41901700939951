import {
    AdminArchivedMapRequestDetail,
    AdminEvents,
    AdminServiceDetail,
    AdminHome,
    AdminInvoice,
    AdminInvoiceGroups,
    AdminInvoiceGroup,
    AdminMapRequestDetail,
    AdminMapRequestDetailAsMri,
    AdminMapRequestDetailAsUna,
    AdminMapRequestOverview,
    AdminOrganisation,
    AdminOrganisationDetail,
    AdminSubOrganisationDetail,
    AdminSubOrganisationDetailUnaZone,
    AdminSubOrganisationDetailUnaKlimZone,
    AdminSettings,
    AdminUser,
    AdminUserDetail
} from '@/app/admin/admin-routes';
import { Component, Vue } from 'vue-property-decorator';
import { RouteConfig } from 'vue-router';
import { UserData } from '../../state/UserDataModule';

@Component
export default class AdminNavigation extends Vue {
    private adminMriRoutes = [
        AdminMapRequestOverview.name,
        AdminMapRequestDetail.name,
        AdminArchivedMapRequestDetail.name,
        AdminMapRequestDetailAsMri.name,
        AdminMapRequestDetailAsUna.name,
    ];

    private adminUserRoutes = [
        AdminUser.name,
        AdminUserDetail.name
    ]

    private adminInvoiceRoutes = [
        AdminInvoice.name,
        AdminInvoiceGroups.name,
        AdminInvoiceGroup.name
    ]

    private adminOrganisationRoutes = [
        AdminOrganisation.name,
        AdminOrganisationDetail.name,
        AdminSubOrganisationDetail.name,
        AdminSubOrganisationDetailUnaZone.name,
        AdminSubOrganisationDetailUnaKlimZone.name
    ]

    get routeAdminHome(): RouteConfig {
        return AdminHome;
    }

    get hasAdminRole(): boolean {
        return UserData.hasRole(['admin']);
    }

    get isAdminHomeRoute() {
        return this.$route.name == AdminHome.name || this.$route.name == AdminServiceDetail.name;
    }

    get routeAdminEvents(): RouteConfig {
        return AdminEvents;
    }

    get isAdminEventsRoute() {
        return this.$route.name == AdminEvents.name;
    }

    get routeAdminMri(): RouteConfig {
        return AdminMapRequestOverview;
    }

    get isAdminMriRoute(): boolean {
        return this.adminMriRoutes.includes(this.$route.name);
    }

    get routeAdminUser(): RouteConfig {
        return AdminUser;
    }

    get isAdminUserRoute(): boolean {
        return this.adminUserRoutes.includes(this.$route.name);
    }

    get routeAdminOrganisation(): RouteConfig {
        return AdminOrganisation;
    }

    get isAdminOrganisationRoute(): boolean {
        return this.adminOrganisationRoutes.includes(this.$route.name);
    }

    get routeAdminInvoice(): RouteConfig {
        return AdminInvoice;
    }

    get isAdminInvoiceRoute(): boolean {
        return this.adminInvoiceRoutes.includes(this.$route.name);
    }

    get routeAdminSettings(): RouteConfig {
        return AdminSettings;
    }

    get isAdminSettingsRoute(): boolean {
        return this.$route.name == AdminSettings.name;
    }
}
