import store from '@/store';
import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators';
import userLocalStorage from '@/storage/user-local-storage';

@Module({
    dynamic: true,
    store,
    name: 'ErrorModule',
    namespaced: true,
})
export default class ErrorVuexModule extends VuexModule {
    public static readonly namespace = 'ErrorModule';

    private globalError: boolean = false;
    private statusCode: string;
    private bypassErrors: string[] = null;

    @Action
    public setGlobalError(statusCode: string): void {
        if (!this.bypassErrors || this.bypassErrors.length === 0 || !this.bypassErrors.includes(statusCode)) {
            this.setGlobalErrorMutation(statusCode);
        }
    }

    @Action
    public setCorrelationId(correlationId: string): void {
        if(!!correlationId){
            userLocalStorage.setItem('lastErrorCorrelationId', correlationId);
        }
    }

    @Action
    public resetAll(): void {
        if (this.hasErrors) {
            this.resetGlobalErrorMutation();
            this.resetBypassErrorMutation();
        }
    }

    @Action
    public setBypassError(statusCodes: string[]): void {
        this.setBypassErrorMutation(statusCodes);
    }

    @Action
    public resetBypassError(): void {
        this.resetBypassErrorMutation();
    }

    @Mutation
    public setGlobalErrorMutation(statusCode: string): void {
        this.globalError = true;
        this.statusCode = statusCode;
    }

    @Mutation
    public resetGlobalErrorMutation(): void {
        this.globalError = false;
        this.statusCode = null;
    }

    @Mutation
    public setBypassErrorMutation(statusCodes: string[]): void {
        this.bypassErrors = statusCodes;
    }

    @Mutation
    public resetBypassErrorMutation(): void {
        this.bypassErrors = null;
    }

    get hasErrors(): boolean {
        return this.globalError;
    }

    get getStatusCode(): string {
        return this.statusCode;
    }

    get getCorrelationId(): string {
        return userLocalStorage.getItem('lastErrorCorrelationId');
    }
}

export const ErrorModule = getModule(ErrorVuexModule);
