import {     
    defineComponent,
    ref,
    getCurrentInstance } from "vue";

  export default defineComponent({
    props: {},
    setup(_props) {
      const root = getCurrentInstance().proxy;
      const userId = ref<string>(root.$route.params.userId);
      const organisationId = ref<string>(root.$route.params.organisationId);
      return {
        userId,
        organisationId
      };
    },
  });