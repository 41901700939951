import { RouteConfig } from 'vue-router';
import { klipIndex } from '@/router/router';
import { UserData } from '@/app/shared/state/UserDataModule';
import { PaymentTypes } from '@/api/klip-api.proxy';

export const Prepaid: RouteConfig = {
  path: '/prepaid',
  name: 'prepaid',
  component: () => import('./prepaid.vue'),
  meta: {
    authorize: { roles: ['mri', 'mrr'] }, // TODO: Check roles
    breadcrumb: { title: 'Prepaid saldo', parent: klipIndex },
  },
  beforeEnter: (to, from, next) => {
    if (UserData.user.paymentType !== PaymentTypes.Invoice && !UserData.user.isCitizen ) {
      next();
    } else {
      next({ name: 'mri'});
    }
  },
};

export default [
  Prepaid,
] as RouteConfig[];
