import {computed, defineComponent, ref, watch} from "vue";
import {QueueMessageCount} from '@/api/klip-api.proxy';
import {uniq} from "lodash";
import {AdminServiceDetail} from "@/app/admin/admin-routes";

export default defineComponent({
    props: {
        queue: {
            type: QueueMessageCount,
            default: [],
            required: true
        },
        modSimple: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    setup(props) {
        const previousActiveMessagesCounts = ref<number[]>([]);
        const previousDeadLettersCounts = ref<number[]>([]);
        const hasHighLoad = ref<boolean>(false);
        const hasAscendingDeadLetters = ref<boolean>(false);
        const queueInfo = ref<QueueMessageCount>(new QueueMessageCount());

        const showLink = computed<boolean>(() => {
            return !props.modSimple;
        });

        const isMessageCountIncreasing = (messageCount: number[]) => {
            const uniqueArray = uniq(messageCount);
            if (uniqueArray.length == 1) {
                return false;
            }
            for (let i = 0; i < uniqueArray.length - 1; i++) {
                if (uniqueArray[i] > uniqueArray[i + 1]) {
                    return false;
                }
            }
            return true; // All pairs are in increasing order
        }

        const routeAdminServiceDetail = computed(() => {
            return AdminServiceDetail
        });

        const messageCountChanged = (queueMessage: QueueMessageCount) => {
            //Keep track of the last 10 numbers, if this is increasing and the total count is more then 100 the load is high on the queue
            if (previousActiveMessagesCounts.value.length >= 10) {
                previousActiveMessagesCounts.value.shift();
            }

            previousActiveMessagesCounts.value.push(queueMessage.activeMessageCount)
            hasHighLoad.value = queueMessage.activeMessageCount > 100 && isMessageCountIncreasing(previousActiveMessagesCounts.value);

            if (previousDeadLettersCounts.value.length >= 10) {
                previousDeadLettersCounts.value.shift();
            }
            previousDeadLettersCounts.value.push(queueMessage.deadLetterMessageCount)
            hasAscendingDeadLetters.value = queueMessage.deadLetterMessageCount >= 10 || isMessageCountIncreasing(previousDeadLettersCounts.value);

            queueInfo.value = queueMessage;
        };

        const queueCssClass = (queueMessage: QueueMessageCount) => {
            if (hasAscendingDeadLetters.value) {
                return ['vl-alert', 'kl-performance-data', 'kl-performance-data--dead-letter']
            }

            if (hasHighLoad.value) {
                return ['vl-alert', 'kl-performance-data', 'kl-performance-data--high']
            }

            if (queueMessage.activeMessageCount > 0) {
                return ['vl-alert', 'kl-performance-data', 'kl-performance-data--normal']
            }

            return ['vl-alert', 'kl-performance-data', 'kl-performance-data--none'];
        }

        watch(() => props.queue, messageCountChanged, {immediate: true, deep: true});

        return {
            routeAdminServiceDetail,
            queueInfo,
            queueCssClass,
            showLink
        };
    }
});
