import Vue from 'vue';
import Router from 'vue-router';
import { auth } from './guards';
import { UserData } from '@/app/shared/state/UserDataModule';
import { KlRouteConfig } from '@/router/router-vue';
import { ErrorModule } from '@/app/shared/state/ErrorModule';
import { PaymentStatus } from '@/app/shared/state/PaymentStatusModule';


export const klipIndex: KlRouteConfig = {
    path: '/',
    name: 'klip',
    meta: { authorize: { roles: [] } },
    beforeEnter: async (to, from, next) => {
        // Redirect to / mri, /pda or /una
        // pages depending on the user role / scope
        const newRoute = UserData.scope;
        const isOrgManagerOnly = UserData.hasRole(['org_manager']) &&
            !UserData.hasRole(['una_operator']) &&
            !UserData.hasRole(['una_reader']) &&
            !UserData.hasRole(['una_manager']) &&
            !UserData.hasRole(['pda_manager']) &&
            !UserData.hasRole(['mri']) &&
            !UserData.hasRole(['mrr']);

        if (isOrgManagerOnly) {
            next({ path: `/settings` });
        }

        if (newRoute) {
            if (from.name === newRoute) {
                history.pushState(
                    {},
                    null,
                    from.path,
                );
                next({ path: `/${newRoute}` });
            } else {
                next({ path: `/${newRoute}` });
            }
        }
    },
};

const router = new Router({
    mode: 'history',
    base: import.meta.env.BASE_URL,
    routes: [klipIndex],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
});

router.beforeEach(async (to, from, next) => {
    Vue.prototype.$Progress?.start();
    // Support for old HashTagUrls (ignore hashes later in path)
    if (to.hash.substr(0, 2) === '#/') {
        const path = to.hash.substr(2);
        return next(path);
    }

    if (to.meta && to.meta.allowAnonymous) {
        next();
    } else {
        await auth(to, from, next, UserData);
        const scope = ['una', 'pda', 'mri'].find((item) => {
            return to.path.startsWith(`/${item}`);
        });
        if (scope) { UserData.setScope(scope); }
    }
});

router.afterEach((to, from) => {
    ErrorModule.resetAll();

    if ((from.name !== 'payment' && from.name !== 'checkout' && from.name !== 'mrimaprequestcreate') && PaymentStatus.isPaymentMessagesVisible) {
        PaymentStatus.clearPaymentMessages();
    }
    // The prepaid payment message needs to be cleared when coming from anywhere but the payment or prepaid view
    // But it doesn't need to be cleared when going from prepaid to checkoutprepaid
    if (((from.name !== 'payment' && from.name !== 'prepaid') || (from.name === 'prepaid' && to.name !== 'checkoutprepaid')) && (PaymentStatus.hasPrepaidAmount || PaymentStatus.hasPrepaidPaymentMessageData)) {
        if (from.name !== 'checkoutprepaid' && to.name !== 'prepaid') {
            PaymentStatus.clearPrepaidAmount();
        }
        PaymentStatus.clearPrepaidPaymentMessage();
    }

    Vue.prototype.$Progress?.finish();
});

Vue.use(Router);

export default router;
