import VueRouter, { Route, RouteConfig } from 'vue-router';
import Vue, { VueConstructor, getCurrentInstance } from 'vue';
import { useRoute as useVueRoute, useRouter as useVueRouter } from 'vue2-helpers/vue-router';

export default {
    install(vue: typeof Vue, { router, error }: { router: VueRouter, error: VueConstructor<Vue> }): void {
        const allroutes = this.getAllRoutes(error);
        allroutes.forEach(r => {
            router.addRoute(r);
        })

        Vue.prototype.$klRoutes = allroutes;
    },

    getModuleRoutes(): RouteConfig[] {
        const routeFiles = import.meta.glob(
            '@/app/**/*-routes.ts',
            { eager: true }
        );

        const routes: RouteConfig[] = [];
        Object.entries(routeFiles).forEach(([key, m]) => {
            (m as any).default.forEach((route: RouteConfig) => {
                routes.push(route);
            });
        });

        return routes;
    },

    getIgnoredRoutes(error: VueConstructor<Vue>): RouteConfig[] {
        if (error) {
            return [
                { path: '/400', name: '400', meta: { allowAnonymous: true }, component: () => import('@/app/error/error.vue') },
                { path: '/403', component: () => import('@/app/error/error.vue') },
                { path: '/404', component: () => import('@/app/error/error.vue') },
                { path: '/error', name: 'error', meta: { allowAnonymous: true }, component: () => import('@/app/error/error.vue') },
                { path: '/*', redirect: '/404' },
            ];
        }
        return [];
    },

    getAllRoutes(error: VueConstructor<Vue>): RouteConfig[] {
        return this.getModuleRoutes().concat(this.getIgnoredRoutes(error));
    },
};


export function useRoute(): Route {
    // Composition API migration:
    // 'getCurrentInstance()' is only available during setup & certain hooks
    // + this route would not be reactive

    // const { proxy } = getCurrentInstance()
    // const route = proxy.$route
    // return route
    return useVueRoute();
}

export function useRouter(): VueRouter {
    // Composition API migration:
    // 'getCurrentInstance()' is only available during setup & certain hooks

    // const { proxy } = getCurrentInstance()
    // const router = proxy.$router
    // return router
    return useVueRouter();
}
