import store from '@/store';
import { Module, VuexModule, Mutation, getModule, Action } from 'vuex-module-decorators';
import * as KlipApi from '@/api/klip-api.proxy';
import { UserData } from './UserDataModule';
import {klipApiProxy} from '@/plugins/proxy-client';

@Module({
    dynamic: true,
    store,
    name: 'CodeListModule',
    namespaced: true,
})
class CodeListModule extends VuexModule {
    public codeLists: KlipApi.CodeLists = null;

    public get holidays() {
        return this.codeLists ? this.codeLists.holidays : null;
    }

    public get countryCodes() {
        return this.codeLists ? this.codeLists.countryCodes : null;
    }

    public get isBeta(): boolean {
        return this.codeLists && this.codeLists.applicationInfo.environment === 'Beta';
    }

    public get applicationInfo(): KlipApi.IApplicationInfo {
        return this.codeLists ? this.codeLists.applicationInfo : null;
    }

    @Action
    public async updateCodeList() {
        const codeLists = this.codeLists;
        if (!codeLists && UserData.loggedOn) {
            return klipApiProxy.codeLists_GetAll()
                .then((data) => {
                    this.mutationCodeList(data.result);
                });
        }
        return { codeLists };
    }

    @Mutation
    public mutationCodeList(codeLists: KlipApi.CodeLists) {
        this.codeLists = codeLists;
    }
}

export const CodeLists = getModule(CodeListModule);
