import { Component, Prop, Vue } from 'vue-property-decorator';
import KlUpload from '@/app/shared/components/kl-upload/kl-upload.vue';
import { ImklTest } from '@/app/imkltest/imkltest-routes';
import { EnvelopeOfString, EnvelopeOfUploadZoneFile, IUploadZoneFile } from '@/api/klip-api.proxy';
import { IConfig } from '@/app/shared/components/kl-upload/config';

@Component({
    components: {
        KlUpload,
    },
})
export default class KlUploadImkl extends Vue {
    public success: boolean = false;
    public error: boolean = false;
    public processing: boolean = false;
    public errorMessages: string[] = [];

    @Prop({ type: String })
    imklUploadUrl: string;

    @Prop({ type: Boolean })
    modRevertable: boolean;

    @Prop({ type: Boolean, required: false, default: false })
    modDisabled: boolean;

    @Prop({ type: Boolean })
    modBordered: boolean;

    @Prop({ type: Boolean })
    isValidating: boolean;

    @Prop({ type: Boolean })
    isImklTest: boolean;

    config: IConfig = null;

    get revertable() {
        return this.modRevertable;
    }

    public mounted() {
        this.config = {
            url: this.imklUploadUrl,
            timeout: 600000,
            title: 'Laad uw IMKL-pakket op',
            uploadLabel: 'Bestand toevoegen',
            uploadDragText: 'Sleep het bestand naar hier om toe te voegen.',
            processingMessage: 'De gegevens worden gevalideerd. U kan even wachten of deze pagina sluiten en het resultaat hier later terugvinden.',
            guidelines: [
                {
                    value: 'Enkel .zip-bestanden',
                }, {
                    value: 'Maximale bestandsgrootte: 500 MB',
                }, {
                    value: `Enkel bestanden volgens de <a href="${import.meta.env.VITE_LINKS_IMKLINFO}" target="_blank">IMKL-vereisten</a>`,
                },
            ],
            files: {
                amount: 1,
                size: 500,
                message: 'De grootte van het bestand mag maximaal 500 MB zijn.',
                types: '.zip',
            },
        };

        if (!this.isImklTest) {
            this.config.guidelines.push({ value: `<a href="${ImklTest.path}" target="_blank">Test uw IMKL-pakket</a>` });
        }
    }

    public onFileUploaded() {
        this.processing = true;
    }

    public onUploadSuccess(file: object, response: EnvelopeOfUploadZoneFile) {
        if (this.isImklTest) {
            this.$emit('response', response.result);
        }

        if (!response.result) {
            return;
        }
        const fileZoneResponse: IUploadZoneFile = { ...response.result };
    }

    public onUploadError(file: object, message: EnvelopeOfString, xhr: object) {
        if (xhr) {
            this.processing = false;
            this.error = true;
            if (message && message.messages) {
                this.errorMessages = this.errorMessages.concat(message.messages);
            }
        }
    }

    public revert() {
        this.success = true;
        this.$emit('revert');
    }
}
