import { Component, Prop, Vue } from 'vue-property-decorator';
import userLocalStorage from '@/storage/user-local-storage';

@Component
export default class KlMaprequestDownload extends Vue {
    public downloadPreferenceLocalStorageKey: string = 'download_preference';

    // TODO: this should move to the localstorage...
    public downloadFiletype: string = userLocalStorage.getItem(this.downloadPreferenceLocalStorageKey) || 'GeoJson';

    @Prop({ type: Object, required: false })
    public downloadUrls: { [key: string]: string };

    get downloadFileUri() {
        return this.downloadUrls && this.downloadFiletype ?
            this.downloadUrls[this.downloadFiletype] : null;
    }

    public downloadFiletypeChanged() {
        userLocalStorage.setItem(this.downloadPreferenceLocalStorageKey, this.downloadFiletype);
    }
}
