import { Component, Prop, Vue, Inject } from 'vue-property-decorator';
import { NormalizedScopedSlot, VNode } from 'vue/types/vnode';


@Component
export default class KlValidationWrapper extends Vue {
    public id: string = '';
    public names: string[] = [];

    @Prop({ type: String, default: '', required: true })
    public label: string;

    @Prop({ type: String, default: '' })
    public rulesLabelOverride: string;

    @Prop({ type: [Object, String], required: false })
    public rules: object | string;

    @Prop({ type: String, default: 'aggressive', required: false })
    public mode: object | string;

    @Prop({ type: String, required: false })
    public annotation: string;

    @Prop({ type: Boolean, default: false, required: false })
    public modHiddenLabel: boolean;

    @Prop({ type: Boolean, default: false, required: false })
    public modRequired: string;

    @Prop({ type: Boolean, default: false, required: false })
    public modNoWrapError: boolean;

    @Prop({ type: Boolean, default: false, required: false })
    public modNoWrapAnnotation: boolean;

    private acceptedFormFieldComponents = [
        'select',
        'vl-select',
        'vl-input-field',
        'vl-checkbox',
        'vl-checkbox-tile',
        'vl-radio',
        'vl-radio-tile',
        'textarea',
        'vl-textarea',
        'vl-datepicker',
        'kl-datepicker',
        'vl-pill-input',
        'kl-autocomplete',
        'vl-button',
        'multiselect',
    ];

    get rulesLabel(): string {
        // label = label bij het veld in de ui
        // rulesLabel = naam van het veld in de error text + deze moet uniek zijn binnen de form
        if (this.rulesLabelOverride) {
            return this.rulesLabelOverride;
        }
        return this.label ? this.label : this.id;
    }
    get parsedLabel(): string {
        return `${this.label}${this.modRequired ? '&nbsp;*' : ''}`;
    }

    /**
     * Traverse search inside an element
     * to find form field component and return
     * their id and names
     */
    public findFormFieldDetails(container: VNode[]) {
        let data: { id: string, names: string[] } = {
            id: '',
            names: [],
        };
        container.forEach((vnode: VNode) => {
            const componentOptions = vnode.componentOptions;
            const tag = componentOptions ? componentOptions.tag : vnode.tag;
            const isSupportedTag = this.acceptedFormFieldComponents.indexOf(tag) >= 0;
            if (isSupportedTag) {
                if (tag === 'vl-select') {
                    const propsData = componentOptions.propsData as any;
                    if (!data.id) {
                        data.id = propsData.id;
                    }
                    data.names.push(propsData.name);
                } else {
                    if (!data.id) {
                        data.id = vnode.data.attrs.id;
                    }
                    data.names.push(vnode.data.attrs.name ? vnode.data.attrs.name : (componentOptions.propsData as any).name);
                }

            } else if (componentOptions && componentOptions.children) {
                data = this.findFormFieldDetails(componentOptions.children);
            }
        });
        return data;
    }

    public mounted() {
        /**
         * Search the default slot for form fields and
         * get the id and names as soon as one is found
         */
        if (this.$scopedSlots && this.$scopedSlots.default({})) {
            const fields = this.findFormFieldDetails(this.$scopedSlots.default({}));
            this.id = fields.id;
            this.names = fields.names;
        }
    }
}
