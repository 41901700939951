import { Component, Vue, Watch } from 'vue-property-decorator';
import FunctionalHeader from './shared/components/kl-functional-header/kl-functional-header.vue';
import { AlertModule, IAlertNotification } from './shared/state/AlertModule';
import { UserData, UserDataModule } from './shared/state/UserDataModule';
import { CodeLists } from './shared/state/CodeListModule';
import { ErrorModule } from './shared/state/ErrorModule';
import userLocalStorage from '@/storage/user-local-storage';
import { IApplicationInfo } from '@/api/klip-api.proxy';
import { settings } from './settings/settings-routes';
import * as widgetClient from '@govflanders/vl-widget-client'
import { Widget } from '@govflanders/vl-widget-core/types/plugin'
import "reflect-metadata"
import '@govflanders/vl-widget-polyfill'
import { Getter } from 'vuex-class';
import axios from 'axios';
import { klipApiProxy } from '@/plugins/proxy-client';
import { AlertHandler } from '@/plugins/alert';
import { MetaHandler } from '@/plugins/meta';
import {signalrHub} from "@/plugins/signalr";

@Component({
    components: {
        FunctionalHeader,
    },
    provide: {
        axiosClient: axios,
        klipApiProxy: klipApiProxy,
        signalrHub: signalrHub,
        alerts: AlertHandler,
        meta : MetaHandler
    }
})
export default class App extends Vue {
    private sessionTimeoutLocalStorageKey: string = 'sessiontimeout_redirect';
    public acmHeaderUrl: string = import.meta.env.VITE_ACM_HEADER_URL;
    public acmFooterUrl: string = import.meta.env.VITE_ACM_FOOTER_URL;

    @Getter('authenticated', { namespace: UserDataModule.namespace })
    public authenticated!: number;

    @Watch('alerts', { immediate: false, deep: true })
    public onAlertsChanged(alerts: IAlertNotification[], oldAlerts: IAlertNotification[]) {
        const autoCloseAlerts = alerts.filter((alert) => alert.autoClose);
        const autoCloseAlertsOld = oldAlerts.filter((alert) => alert.autoClose);
        if (JSON.stringify(autoCloseAlerts) !== JSON.stringify(autoCloseAlertsOld)) {
            if (autoCloseAlerts) {
                autoCloseAlerts.forEach((alert) => {
                    setTimeout(() => {
                        this.$alertHandler.removeAlert(alert.id);
                    }, alert.autoClose);
                });
            }
        }
    }

    @Watch('authenticated', { immediate: true, deep: true })
    public onAuthenticatedChanged() {
        if (!this.authenticated) {
            return;
        }
        this.widgetCapture();
        this.widgetActivity()
    }

    get applicationInfo(): IApplicationInfo {
        return CodeLists && CodeLists.applicationInfo;
    }

    get isCheckingAuthentication(): boolean {
        return UserData && UserData.isCheckingAuthentication;
    }

    get userLoggedOn(): boolean {
        return UserData && UserData.loggedOn;
    }

    get alerts(): IAlertNotification[] {
        const pageSpecificAlerts = AlertModule.pageAlerts(this.$route.name);
        const globalAlerts = AlertModule.globalAlerts;

        return [...pageSpecificAlerts, ...globalAlerts];
    }

    get hasErrors(): boolean {
        return ErrorModule.hasErrors || (!!UserData.user && (UserData.user.roleError || !!UserData.user.validationError));
    }

    get acmAuthentication(): boolean {
        return import.meta.env.VITE_ACM_AUTHENTICATION === 'true';
    }

    get absoluteUrl(): string {
        return import.meta.env.VITE_BASE_URI;
    }

    get routeSettings() {
        return settings;
    }

    close(id: string) {
        this.$alertHandler.removeAlert(id);
    }

    async mounted() {
        this.generateHeader();
        this.generateFooter();
        this.addApplicationInfo();
    }

    destroyed() {
        this.$signalrHub.stop();
    }

    private generateHeader() {
        if (this.acmAuthentication) {
            widgetClient.bootstrap(this.acmHeaderUrl).then((widget: Widget) => {
                widget.setMountElement(document.getElementsByClassName('global-header')[0]);
                widget.mount();
                widget.getExtension('citizen_profile.session').then((session: any) => {
                    session.configure({
                        endpoints: {
                            loginUrl: `${this.absoluteUrl}/auth/login`,
                            switchCapacityUrl: `${this.absoluteUrl}/auth/switch`,
                            loginRedirectUrl: `${this.absoluteUrl}/`,
                            logoutUrl: `${this.absoluteUrl}/auth/logout`
                        }
                    });
                });
            })

        } else {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', `https://widgets.vlaanderen.be/widget/live/0839942423b142f997d405c86a16170f`);
            xhr.send();
            xhr.onload = () => {
                const script = (xhr.response as string).replace(
                    /document\.write\((.*?)\);/,
                    'document.getElementsByClassName("global-header")[0].innerHTML = $1;',
                );
                eval(script);
            };
        }
    }

    private async generateFooter() {
        if (this.acmAuthentication) {
            const footer = await widgetClient.bootstrap(this.acmFooterUrl)
            footer.setMountElement(document.getElementsByClassName('global-footer')[0])
            footer.mount()
        } else {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', `https://widgets.vlaanderen.be/widget/live/f826ff458f734342afc3590c226893cd`);
            xhr.send();
            xhr.onload = () => {
                const script = (xhr.response as string).replace(
                    /document\.write\((.*?)\);/,
                    'document.getElementsByClassName("global-footer")[0].innerHTML = $1;',
                );
                eval(script);
            };
        }
    }

    @Watch('applicationInfo', { immediate: false, deep: true })
    private addApplicationInfo() {
        if (!this.applicationInfo) {
            return;
        }

        // check if the user's session timed out, redirect to last location
        const routePath = userLocalStorage.getItem(this.sessionTimeoutLocalStorageKey);
        if (routePath) {
            userLocalStorage.removeItem(this.sessionTimeoutLocalStorageKey);
            this.$router.push({ path: routePath });
        }

        const buildNumberElement = document.querySelector('head meta[name="klip_buildnumber"]');
        const versionElement = document.querySelector('head meta[name="klip_version"]');
        const environmentElement = document.querySelector('head meta[name="klip_environment"]');

        buildNumberElement.setAttribute('content', this.applicationInfo.buildNumber);
        versionElement.setAttribute('content', this.applicationInfo.version);
        environmentElement.setAttribute('content', this.applicationInfo.environment);

        if (this.applicationInfo.environment === 'Beta') {
            const favicon = document.querySelector('link[rel="icon"]');
            favicon.setAttribute('href', import.meta.env.BASE_URL + 'favicon.beta.ico');
            document.title = 'KLIP BETA - Kabel- en Leidinginformatieportaal | Digitaal Vlaanderen';
        }
    }

    private widgetCapture() {
        widgetClient.capture((widget: Widget) => {
            const userActive = UserData.isAuthenticated;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            widget.getExtension('citizen_profile.session').then((session: any) => {
                session.configure({ active: userActive });
            });
        });
    }

    private widgetActivity() {
        widgetClient.capture((widget: Widget) => {
            if (widget.getPluginTypeId() === 'global_header') {
                // Get the Citizen Profile Session extension from the global header widget.
                widget.getExtension('citizen_profile.session').then((session: any) => {
                    /**
                     * Event handler which extends a Citizen Profile session.
                     */
                    function activityEventHandler() {
                        // Inform the Citizen Profile Session extension about activity.
                        session.extend()
                    }

                    // Build a list of event names which should be used for activity tracking.
                    const eventNames = [
                        'mousedown',
                        'mousemove',
                        'mousewheel',
                        'DOMMouseScroll',
                        'scroll',
                        'wheel',
                        'keydown',
                        'keypress',
                        'touchmove',
                        'touchstart'
                    ]
                    // Iterate through the events names.
                    for (const eventName of eventNames) {
                        // Register our event handler given event name.
                        window.addEventListener(eventName, activityEventHandler)
                    }
                })
            }
        });
    }
}
