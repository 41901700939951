import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { EnvelopeOfListOfString } from '@/api/klip-api.proxy';
import { validate, extend } from 'vee-validate';
import validations from '@/plugins/validations';
import {ValidationResult} from 'vee-validate/dist/types/types';

extend('tags', validations.klipRules.tags);
extend('tag', validations.klipRules.tag);

@Component
export default class KlTags extends Vue {
    public tags: any = null;
    public tagSuggestions: string[] = [];

    @Prop({ type: Boolean, required: false, default: false })
    public modDisabled: boolean;
    
    @Watch('tags', { immediate: false, deep: true })
    public onTagsChanged(newTags: string[], oldTags: string[]) {
        // update validation message
        validate(newTags, 'tags').then();
    }

    public onAddSelect(tag: string) {
        // save (emit) tag selection when tag is valid
        validate(tag, 'tag').then((result: ValidationResult) => {
            if (result.valid) {
                this.$emit('input', this.tags);
                this.$emit('add-tag', tag);
            }
        });
    }

    public onRemoveSelect(tag: string) {
        this.$emit('input', this.tags);
        this.$emit('delete-tag', tag);
    }

    public async onTagAdded(tag: string) {
        // add to possible tags
        this.tagSuggestions.push(tag);
        // auto select
        (this.$refs.multiselect as any).select(tag);
    }

    mounted() {
        this.tags = this.$attrs.value;

        // update possible tags
        this.$client.mri_GetTags().then((response: EnvelopeOfListOfString) => {
            this.tagSuggestions = response.result;
        });
    }
}
