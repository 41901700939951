import {computed, defineComponent, inject, onMounted, onUnmounted, ref} from "vue";
import * as KlipApi from "@/api/klip-api.proxy";
import {CurrentInvoiceRound, IKlipApiProxy, InvoiceStates, LogTypes} from "@/api/klip-api.proxy";
import {ISelectedOrganisation} from "@/app/admin/components/kl-search-organisation/kl-search-organisation";
import debounce from "lodash-es/debounce";
import Guid from "@/app/shared/helpers/guid";
import dayjs from "dayjs";

export default defineComponent({
    setup() {
        const yesterday = dayjs().add(-1, "day").format('DD/MM/YYYY');

        let invoiceRoundintervalId: NodeJS.Timer = null;
        const roundSuccessfullyDone = ref<boolean>(false);
        const startingNewRound = ref<boolean>(false);
        const klipApiProxy = inject<IKlipApiProxy>('klipApiProxy');
        const isSending = ref<boolean>(false);
        const organisationDataFetching = ref<boolean>(false);
        const selectedOrganisationTpe = ref<string>('all');
        const dateFrom = ref<string[]>([yesterday]);
        const dateFromMaxDate = ref<string>(yesterday);
        const selectedOrganisations = ref<ISelectedOrganisation[]>([]);
        const organisationSuggestion = ref<ISelectedOrganisation[]>([]);
        const currentInvoiceRound = ref<KlipApi.CurrentInvoiceRound>(null);
        const currentInvoiceRoundId = ref<string>('');
        const loading = ref<boolean>(true);
        const error = ref<string>('');
        const nothingToInvoice = ref<boolean>(false);
        const organisationSelection = computed((): boolean => {
            return selectedOrganisationTpe.value === 'select';
        });

        const debouncedOrganisationSearch = debounce((searchValue) => {
            if (!searchValue) {
                return;
            }

            organisationDataFetching.value = true;
            klipApiProxy.organisation_FilterOrganisationsByName(searchValue, false)
                .then((filterResult) => {
                    organisationSuggestion.value = filterResult.result.map((item) => {
                        return {
                            organisationId: item.organisationId,
                            name: item.name,
                            parentId: item.parentId,
                            namePath: item.namePath
                        }

                    });
                })
                .finally(() => {
                    organisationDataFetching.value = false;
                });
        }, 200);

        const onOrganisationInputChange = (searchQuery: string, id) => {
            debouncedOrganisationSearch(searchQuery);
        };

        const title = computed(() => {
            if (noActiveRoundRunning.value) {
                return 'Start Facturatieronde';
            } else {
                return `Facturatieronde van ${formatDate(currentInvoiceRound.value.startTime)} - ${currentInvoiceRoundId.value}`
            }
        });

        const pollInvoiceRound = async () => {
            const response = await klipApiProxy.invoice_CurrentInvoiceRound();
            if (response.isSuccessStatusCode && response.result !== null) {
                if (response.result.state === InvoiceStates.InvoicesSent) {
                    roundSuccessfullyDone.value = true;
                }
                currentInvoiceRound.value = response.result;
            }
        };

        const canStartNewRound = computed(() => {
            return currentInvoiceRound.value && (
                currentInvoiceRound.value.state === InvoiceStates.NoInvoicesToGenerate ||
                currentInvoiceRound.value.state === InvoiceStates.NotStarted ||
                currentInvoiceRound.value.state === InvoiceStates.NoOrganisations ||
                currentInvoiceRound.value.state === InvoiceStates.Canceled
            );
        });

        const noActiveRoundRunning = computed(() => {
            return (currentInvoiceRound.value === null || currentInvoiceRound.value === undefined) ||
                currentInvoiceRound.value.state === InvoiceStates.InvoicesSent || currentInvoiceRound.value.state === InvoiceStates.Completed ||
                startingNewRound.value;
        });

        const uniqueFieldId = (id: string) => {
            return id + Guid.randomGuid();
        };

        const formatDate = (timestamp: string) => {
            return dayjs(timestamp).format('DD/MM/YYYY - HHumm');
        };

        const startNewRound = () => {
            const invoiceRound = new CurrentInvoiceRound();
            invoiceRound.incorrectVatNumbers = [];
            invoiceRound.log = [];
            currentInvoiceRound.value = new CurrentInvoiceRound();
            startingNewRound.value = true;
        };

        const onFormSubmit = async () => {
            startingNewRound.value = true;
            error.value = '';

            const invoiceRoundData = {
                startDate: dayjs(dateFrom.value[0]).format('YYYY/MM/DD'),
                organisations: selectedOrganisations.value.map(s => s.organisationId)
            } as KlipApi.StartInvoicingRoundRequest

            const startInvoiceRoundResult = await klipApiProxy.invoice_StartInvoiceRound(invoiceRoundData);

            if (startInvoiceRoundResult.result && startInvoiceRoundResult.result.success) {
                if (!!startInvoiceRoundResult.result.invoiceRoundId) {
                    currentInvoiceRoundId.value = startInvoiceRoundResult.result.invoiceRoundId;
                    currentInvoiceRound.value = new CurrentInvoiceRound();
                    startingNewRound.value = false;
                    invoiceRoundintervalId = setInterval(pollInvoiceRound, 5000);
                } else {
                    nothingToInvoice.value = true;
                }
            } else {
                error.value = startInvoiceRoundResult.result.failure.errors[0].message;
            }

            startingNewRound.value = false;
        };

        const logClass = (log: KlipApi.InvoicingLogMessage) => {
            return [
                'kl-invoice-alert', {
                    'kl-invoice-alert--error': log.logTypes === LogTypes.Error,
                    'kl-invoice-alert--warning': log.logTypes === LogTypes.Warning,
                    'kl-invoice-alert--success': log.logTypes === LogTypes.Info,
                }];
        }

        onMounted(async () => {
            const currentRoundResult = await klipApiProxy.invoice_CurrentInvoiceRound();

            if (currentRoundResult.isSuccessStatusCode) {
                currentInvoiceRound.value = currentRoundResult.result;
            }

            if (currentInvoiceRound.value && currentInvoiceRound.value.state !== InvoiceStates.InvoicesSent) {
                invoiceRoundintervalId = setInterval(pollInvoiceRound, 5000);
            }

            loading.value = false;
        });

        onUnmounted(() => {
            clearInterval(invoiceRoundintervalId);
        });

        return {
            isSending,
            dateFrom,
            formatDate,
            dateFromMaxDate,
            organisationSuggestion,
            selectedOrganisationTpe,
            selectedOrganisations,
            organisationSelection,
            organisationDataFetching,
            currentInvoiceRound,
            currentInvoiceRoundId,
            noActiveRoundRunning,
            roundSuccessfullyDone,
            startingNewRound,
            canStartNewRound,
            title,
            loading,
            logClass,
            uniqueFieldId,
            onOrganisationInputChange,
            onFormSubmit,
            startNewRound,
            nothingToInvoice,
            error
        };
    }
});
