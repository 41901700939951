import { Component, Prop, Vue } from 'vue-property-decorator';
import {Address, MapRequestInitiatorContactData} from '@/api/klip-api.proxy';
import {escape} from 'html-escaper';

@Component
export default class KlMaprequestContact extends Vue {
    @Prop({ type: String, default: '', required: false })
    public title: string;

    @Prop({ type: Object, default: null, required: false })
    public contact: MapRequestInitiatorContactData;

    @Prop({ type: Boolean, default: false, required: false })
    public modSmallTitle: boolean;

    get titleTag() {
        return this.modSmallTitle ? 'h4' : 'h3';
    }

    get contactCardTitle() {
        return this.contact ? `${this.contact.firstName}${this.contact.firstName && this.contact.lastName ? ' ' : ''}${this.contact.lastName}` : null;
    }

    get contactCardSubtitle() {
        return this.contact ? `${this.contact.role ? this.contact.role : ''}${this.contact.role &&
            this.contact.company ? ' - ' : ''}${this.contact.company ? this.contact.company : ''}` : '';
    }

    public addressToContactCard(address: Address): string {
        if (!address) {
            return '';
        }

        const additionalLine = address.additionalLine ? `${escape(address.additionalLine)}<br>` : '';
        const street = `${address.street ? escape(address.street) : ''}${address.street && address.streetNumber ? ` ${escape(address.streetNumber)}` : ''}${address.box ? ` ${escape(address.box)}` : ''}<br>`;
        const cityCountry = `${address.zip && address.city ? `${escape(address.zip)} ` : ''}${address.city ? escape(address.city) : ''}${address.city && address.countryCode ? ` - ` : ''}${address.countryCode ? escape(address.countryCode) : ''}`;

        return `${additionalLine}${street}${cityCountry}`;
    }
}
