import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { CodeLists } from '@/app/shared/state/CodeListModule';
import { Mri } from '@/app/mri/shared/services/services';
import {isEmpty} from 'lodash-es';

@Component
export default class KlAddressForm extends Vue {

    @Prop({ required: false, default: () => ({}), type: Object })
    value: Mri.IAddress;

    public streetData: AutoCompleteResult[] = [];
    public streetMinLength: number = 3;
    public streetDataFetching: boolean = false;
    public streetNumberData: AutoCompleteResult[] = [];
    public streetNumberDataFetching: boolean = false;

    public address: Mri.IAddress = {
        box: '',
        city: '',
        countryCode: 'BE',
        street: '',
        streetNumber: '',
        zip: '',
    };

    get autocompleteActive() {
        return this.address.countryCode === 'BE';
    }

    get countries() {
        return CodeLists.countryCodes;
    }

    public mounted() {
        if (this.value) {
            this.address = this.value;
        }
    }

    @Watch('value', { immediate: true, deep: true })
    public onValueChanged(val: object) {
        if (val) {
            this.address = (val as unknown as Mri.IAddress);
        }
    }

    @Watch('address', { immediate: false, deep: true })
    public onAddressChanged(val: object) {
        this.$emit('input', val);
    }

    @Watch('autocompleteActive', { immediate: false, deep: false })
    public onAutocompleteActiveChanged(val: boolean, oldVal: boolean) {
        if (!val) {
            this.streetData = [];
            this.streetNumberData = [];
        }
    }

    public onStreetInputChange() {
        if (this.autocompleteActive && this.address.street.length > this.streetMinLength) {
            this.fetchStreet(this.address.street);
        }
    }

    public onStreetNumberInputChange() {
        if (this.autocompleteActive && this.address.street && this.address.city) {
            this.fetchStreetNumbers(this.address.street, this.address.city);
        }
    }

    public onStreetSelect(street: AutoCompleteResult) {
        const address = this.explodedAddressString(street.value);
        if (!address.street) {
            return;
        }
        this.address.street = address.street;
        if (address.streetNumber) {
            this.address.streetNumber = address.streetNumber;
        } else {
            if (!this.address.streetNumber) {
                (((this.$refs.streetNumber as Vue).$refs.inputField as Vue).$el as HTMLElement).focus();
            }
        }
        if (address.box) { this.address.box = address.box; }
        if (address.zip) {
            this.address.zip = address.zip;
        } else {
            if (address.city && address.street) {
                this.fetchZipCodes(address.street, address.city);
            }
        }
        if (address.city) { this.address.city = address.city; }
    }

    public onStreetNumberSelect(streetNumber: AutoCompleteResult) {
        if (streetNumber.value.length) {
            const parts = streetNumber.value.match(/[a-z]+|[^a-z]+/gi);
            if (parts[0]) {
                this.address.streetNumber = parts[0];
            }
            if (parts[1]) {
                this.address.box = parts[1];
            }
        }
    }

    public fetchStreet(street: string) {
        this.streetDataFetching = true;
        this.$client.location_GetAddressSuggestion(street)
            .then((response) => {
                this.streetData = response.result
                    .map((item) => {
                        const parts = item.split(',');
                        return {
                            title: parts[0],
                            subtitle: parts[1],
                            value: item,
                        };
                    });
            }).finally(() => {
                this.streetDataFetching = false;
            });
    }

    public fetchStreetNumbers(street: string, city: string) {
        if (street && city) {
            this.streetNumberDataFetching = true;
            this.$client.location_GetStreetNumbers(street, city)
                .then((response) => {
                    if (isEmpty(response.result)) {
                        return;
                    }
                    this.streetNumberData = response.result
                        .filter((streetNumber: string) => {
                            if (isEmpty(streetNumber)) {
                                return false;
                            }
                            if (isEmpty(this.address?.streetNumber)) {
                                return true;
                            }
                            return streetNumber.startsWith(this.address.streetNumber);
                        })
                        .map((streetNumber: string) => {
                            return {
                                title: streetNumber,
                                value: streetNumber,
                            };
                        });
                }).finally(() => {
                    this.streetNumberDataFetching = false;
                });
        }
    }

    public fetchZipCodes(street: string, city: string) {
        this.$client.location_GetZipCodes(street, city)
            .then((response) => {
                if (response.result.length !== 0) {
                    this.address.zip = response.result[0];
                }
            });
    }

    public explodedAddressString(address: string) {
        const explodedAddress: Mri.IAddress = {};
        if (address.length) {
            if (address.includes(', ')) {
                const addressParts = address.split(', ');
                const streetDetails = addressParts[0];
                const cityDetails = addressParts[1];
                if (streetDetails.includes(' ')) {
                    const lastSpace = streetDetails.lastIndexOf(' ');
                    const firstPart = streetDetails.substr(0, lastSpace);
                    const secondPart = streetDetails.substr(lastSpace + 1);
                    explodedAddress.street = streetDetails;
                    if (parseInt(secondPart, 10)) {
                        explodedAddress.street = firstPart;
                        explodedAddress.streetNumber = secondPart;
                    }
                }
                if (!explodedAddress.street) { explodedAddress.street = streetDetails; }

                if (cityDetails.includes(' ')) {
                    const cityParts = cityDetails.split(' ');
                    // There are some cities with spaces in the name (ex: De Haan, De Pinte), se we need to check if the possible zipcode is a number.
                    const isInvalidZipCode = isNaN(+cityParts[0]);
                    explodedAddress.zip = isInvalidZipCode ? '' : cityParts[0];
                    explodedAddress.city = isInvalidZipCode ? cityDetails : cityParts[1];
                } else {
                    explodedAddress.city = cityDetails;
                }
            } else {
                explodedAddress.street = address;
            }
        }
        return explodedAddress;
    }

    public mapStreetNumberSuggestions(data: number[]) {
        return data
            .filter((item: number) => {
                return !!(!this.address.streetNumber || item.toString().startsWith(this.address.streetNumber));
            })
            .map((item: number) => {
                return {
                    title: item,
                    value: item,
                };
            });
    }
}
