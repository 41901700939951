import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Collection from 'ol/Collection';
import Draw from 'ol/interaction/Draw';
import Select, { SelectEvent } from 'ol/interaction/Select';
import Modify, { ModifyEvent } from 'ol/interaction/Modify';
import MultiPoint from 'ol/geom/MultiPoint';
import { Vector as LayerVector } from 'ol/layer';
import { shiftKeyOnly, singleClick } from 'ol/events/condition';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import {clone, isEmpty} from 'lodash-es';
import { Extent, createEmpty, extend } from "ol/extent";
import KlDrawZoneMapUtils from '@/app/shared/components/kl-draw-zone-map/kl-draw-zone-map-utils';
import ImageWMS from 'ol/source/ImageWMS';
import ImageLayer from 'ol/layer/Image';
import KlDrawZoneSidebar from '@/app/shared/components/kl-draw-zone-map/components/kl-draw-zone-sidebar/kl-draw-zone-sidebar.vue';
import { IDrawZone, EDrawZoneMapState } from '@/app/shared/components/kl-draw-zone-map/components/kl-draw-zone-sidebar/kl-draw-zone-sidebar';
import {useDrawZoneStore} from '@/app/shared/components/kl-draw-zone-map/kl-draw-zone-store';
import Vue, {computed, defineComponent, getCurrentInstance, onMounted, ref, watch} from 'vue';

export default defineComponent({
    name: 'KlDrawZoneMap',
    components: { KlDrawZoneSidebar },
    emits: ['input', 'zone-price', 'state'],
    props: {
        value: {
            type: Array as () => IDrawZone[],
            default: (): IDrawZone[] => [],
        },
        modMaprequestZone: {
            type: Boolean,
            default: !!import.meta.env.VITE_DEV_ENABLE_MAPREQUESTZONE_BYDEFAULT,
        },
        modDisabled: {
            type: Boolean,
            default: false,
        },
        reference: {
            type: String,
            default: '',
        },
        rules: {
            type: [Object, String],
            default: '',
        },
        includeImkl: {
            type: Boolean,
            default: !!import.meta.env.VITE_DEV_ENABLE_MAPREQUESTZONE_BYDEFAULT,
        },
        forMaprequest: {
            type: Boolean,
            default: false,
        },
        noConfirmation: {
            type: Boolean,
            default: false,
        },
        modShowKmHmToggle: {
            type: Boolean,
            default: true,
        },
        modEnableMultiZones: {
            type: Boolean,
            default: !!import.meta.env.VITE_DEV_ENABLE_MAPREQUESTZONE_BYDEFAULT,
        },
        showMap: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, {emit}) {
        // const sidebar = ref(null); // TO VERIFY: doesn't get the component ref
        // const map = ref(null); // TO VERIFY: doesn't get the component ref
        let sidebar: any = null;
        let map: any = null;

        let _mapInstance: any = null;

        let zones: IDrawZone[] = null;

        const isLoading = ref<boolean>(false);
        const loadingMessage = ref<string>('Zone wordt gevalideerd');

        const drawLayer = new LayerVector({
            source: useDrawZoneStore().source,
            style: [
                new Style({
                    stroke: new Stroke({
                        color: 'rgba(78, 79, 112, 0.8)',
                        width: 1,
                    }),
                    fill: new Fill({
                        color: 'rgba(137, 138, 156, 0.4)',
                    }),
                }),
            ],
        });

        let kmhmLayer: ImageLayer = null;
        const kmhmLoadCounter = ref<number>(0);

        const kmhmEnabled = ref<boolean>(false);
        const kmhmHasError = ref<boolean>(false);

        const kmhmIsLoading = computed((): boolean => {
            return kmhmLoadCounter.value > 0;
        });

        let drawInteraction: Draw = null;
        let selectInteraction: Select = null;
        let modifyInteraction: Modify = null;

        watch(
            () => props.showMap,
            (showMap: boolean) => {
                if (showMap) {
                    Vue.nextTick(() => {
                        _mapInstance.updateSize();
                        if (useDrawZoneStore().currentFeature) {
                            _getMap().zoomToFeature(useDrawZoneStore().currentFeature, 1);
                        }
                    });
                }
            },
            {immediate: false, deep: false});

        watch(
            () => props.modDisabled,
            (disabled: boolean) => {
                loadingMessage.value = disabled ? '' : 'Zone wordt gevalideerd';
            },
            { immediate: false, deep: true }
        )

        watch(
            kmhmEnabled,
            (checked: boolean) => {
                toggleKmHmLayer(checked);
            },
        )


        const _createDrawInteraction = (): Draw => {
            return new Draw({
                type: 'Polygon',
                stopClick: true,
                style: [
                    new Style({
                        stroke: new Stroke({
                            color: 'rgba(78, 79, 112, 0.8)',
                            width: 1,
                        }),
                        fill: new Fill({
                            color: 'rgba(137, 138, 156, 0.4)',
                        }),
                    }),
                    new Style({
                        image: new Circle({
                            radius: 6,
                            fill: new Fill({
                                color: 'rgba(137, 138, 156, 0.4)',
                            }),
                            stroke: new Stroke({
                                color: 'rgba(78, 79, 112, 0.8)',
                                width: 1,
                            }),
                        }),
                    }),
                ],
                geometryFunction: (coordinates, geometry) => {
                    // Only used to show the 'opp=xx' while drawing a new polygon.
                    // can't find a proper openlayers way to listen to changes on the add-interaction

                    const newCoordinates = clone(coordinates[0]);
                    newCoordinates.push(coordinates[0][0]); // auto-close polygon

                    if (!geometry) {
                        geometry = new Polygon([newCoordinates]);
                    }
                    else {
                        geometry.setCoordinates([newCoordinates]);
                    }

                    _updateDrawingPolygon(geometry);
                    return geometry;
                }
            });
        }

        const _createSelectInteraction = (): Select => {
            return new Select({
                features: useDrawZoneStore().selectedFeatures,
                condition: (event) => {
                    if (shiftKeyOnly(event)) {
                        // Do NOT use this event for selecting/deselecting. Will also trigger while deleting points with 'shift' during modify.
                        return false;
                    }
                    return singleClick(event);
                },
                style: [
                    new Style({
                        stroke: new Stroke({
                            color: 'rgba(21, 155, 170, 0.8)',
                            width: 1,
                        }),
                        fill: new Fill({
                            color: 'rgba(21, 155, 170, 0.4)',
                        }),
                    }),
                    new Style({
                        image: new Circle({
                            radius: 6,
                            fill: new Fill({
                                color: 'rgba(137, 138, 156, 0.4)',
                            }),
                            stroke: new Stroke({
                                color: 'rgba(78, 79, 112, 0.8)',
                                width: 1,
                            }),
                        }),
                        geometry: (feature) => {
                            if (feature.getGeometry().flatCoordinates?.length < 8) {
                                // this is not a polygon (probably because we remove a point from a triangle)
                                return null;
                            }
                            // return the coordinates of the first ring of the polygon
                            const coordinates = (feature.getGeometry() as Polygon).getCoordinates()[0];
                            return new MultiPoint(coordinates);
                        },
                    }),
                ],
            });
        }

        const _createModifyInteraction = (): Modify => {
            return new Modify({
                features: useDrawZoneStore().selectedFeatures,
                deleteCondition: (event) => {
                    return shiftKeyOnly(event);
                },
            });
        }


        const _addKmHmLayer = () => {
            const source = new ImageWMS({
                url: 'https://opendata.apps.mow.vlaanderen.be/opendata-geoserver/awv/wms',
                params: {'LAYERS': 'Referentiepunten'},
            });

            source.on('imageloaderror', () => {
                kmhmLoadCounter.value--;
                kmhmHasError.value = true;
            });
            source.on('imageloadstart', () => {
                kmhmLoadCounter.value++;
                kmhmHasError.value = false;
            });
            source.on('imageloadend', () => {
                kmhmLoadCounter.value--;
                kmhmHasError.value = false;
            });

            kmhmLayer = new ImageLayer({
                source: source,
                visible: false,
            });

            _mapInstance.addLayer(kmhmLayer);
        }

        const _getSidebar = (): any => {
            // return this.$refs.sidebar;
            //return getCurrentInstance().proxy.$refs['sidebar'];
            return sidebar;
        }
        const _getMap = (): any => {
            return map;
        }

        const _addDrawingLayer = () => {
            _mapInstance.addLayer(drawLayer);
        }

        const _addDrawInteraction = () => {
            if (drawInteraction) {
                _mapInstance.removeInteraction(drawInteraction);
            }
            drawInteraction = _createDrawInteraction();

            drawInteraction.setActive(false);
            _mapInstance.addInteraction(drawInteraction);

            drawInteraction.on('drawend', async (e) => {
                useDrawZoneStore().selectFeature(e.feature);

                await _getSidebar().onDrawInteraction_DrawEnd(e.feature);
            });
        }

        const _addSelectInteraction = () => {
            if (selectInteraction) {
                _mapInstance.removeInteraction(selectInteraction);
            }
            selectInteraction = _createSelectInteraction();

            selectInteraction.setActive(false);
            _mapInstance.addInteraction(selectInteraction);

            selectInteraction.on('select', async (selectEvent: SelectEvent) => {
                await _getSidebar().onSelectInteraction_Select(selectEvent.selected, selectEvent.deselected);
            });
        }

        const _addModifyInteraction = () => {
            if (modifyInteraction) {
                _mapInstance.removeInteraction(modifyInteraction);
            }
            modifyInteraction = _createModifyInteraction();

            modifyInteraction.setActive(false);
            _mapInstance.addInteraction(modifyInteraction);

            if (props.noConfirmation) {
                modifyInteraction.on('modifyend', async (event: ModifyEvent) => {
                    await _getSidebar().onModifyInteraction_ModifyEnd(event.features.item(0));
                });
            }
        }

        const _updateDrawingPolygon = (polygon: Polygon) => {
            const area = KlDrawZoneMapUtils.getPolygonArea(polygon);
            _getSidebar().updateCurrentPolygonFeatureArea(area);
        }
        const onStateChange = (state: EDrawZoneMapState) => {
            _setState(state);
        }

        const onZoomToFeature = (feature: Feature) => {
            if (feature) {
                _getMap().zoomToFeature(feature, 500);
            }
        }

        const onZoomToFeatures = (features: Collection<Feature>) => {
            if (isEmpty(features)) {
                return;
            }

            const extent: Extent = createEmpty();
            features.forEach((feature: Feature) => extend(extent, feature.getGeometry().getExtent()));

            _getMap().zoomToExtent(extent, 500);
        }

        const onLoading = (loading: boolean) => {
            isLoading.value = loading;
        }

        const onInput = (zones: IDrawZone[]) => {
            emit('input', zones);
        }

        const onZonePrice = (price: number) => {
            emit('zone-price', price);
        }

        const _setState = (state: EDrawZoneMapState) => {
            if (state === EDrawZoneMapState.overview) {
                drawInteraction?.setActive(false);
                modifyInteraction?.setActive(false);

                _addSelectInteraction();
                selectInteraction?.setActive(true);

            } else if (state === EDrawZoneMapState.create) {
                _addDrawInteraction();
                drawInteraction?.setActive(true);

                modifyInteraction?.setActive(false);
                selectInteraction?.setActive(false);

            } else if (state === EDrawZoneMapState.edit) {
                drawInteraction?.setActive(false);

                _addSelectInteraction();
                selectInteraction?.setActive(true);

                _addModifyInteraction();
                modifyInteraction?.setActive(true);

            } else if (state === EDrawZoneMapState.import) {
                drawInteraction?.setActive(false);
                modifyInteraction?.setActive(false);
                selectInteraction?.setActive(false);
            }

            emit('state', state);
        }

        const onSearchOnExtent = (extent: Extent) => {
            if (extent) {
                _getMap().zoomToExtent(extent, 500);
            }
        }

        const onSearchOnCoordinate = (coordinate: number[]) => {
            if (coordinate) {
                _getMap().zoomToCoordinates(coordinate, 500);
            }
        }

        onMounted(() => {
            useDrawZoneStore().clear();

            // getCurrentInstance only works during setup or Lifecycle Hooks
            map = getCurrentInstance().proxy.$refs['map'];
            sidebar = getCurrentInstance().proxy.$refs['sidebar'];
            _mapInstance = _getMap().instance;

            _addKmHmLayer();

            _addDrawingLayer();
            _addDrawInteraction();
            _addSelectInteraction();
            _addModifyInteraction();


            // const zones: IDrawZone[] = this.$attrs.value as unknown as IDrawZone[];
            zones = props.value;
            _getSidebar().initZones(zones);
        })

        const toggleKmHmLayer = (checked: boolean) => {
            kmhmLayer?.setVisible(checked);
        }

        return {
            zones,
            isLoading,
            loadingMessage,
            kmhmEnabled,
            kmhmHasError,
            kmhmIsLoading,

            toggleKmHmLayer,

            onStateChange,
            onZoomToFeature,
            onZoomToFeatures,
            onLoading,
            onInput,
            onZonePrice,
            onSearchOnExtent,
            onSearchOnCoordinate,
        }
    }
})
