import {defineStore} from "pinia";
import {computed, ref} from "vue";

export enum PaymentType {
    Online,
    Topup,
}

export interface IPaymentData {
    paymentType: PaymentType;
    numberOfMapRequests?: number;
    hasMapRequestExtras?: boolean;
    hasMapRequestsWithImkl?: boolean;
    totalPrice?: number;
}

export const usePaymentStatusStore = defineStore('payment-status-store', () => {
    const onlinePayment = ref<IPaymentData>(null);
    const onlinePaymentError = ref<boolean>(false);
    const topUpPaymentError = ref<boolean>(false);
    const unCertainTopUpPaymentError = ref<boolean>(false);
    const mapRequestPrice = ref<number>(null);

    const setOnlinePayment = (onlinePaymentData: IPaymentData) => {
        onlinePayment.value = onlinePaymentData;
        if (mapRequestPrice.value !== null) {
            onlinePayment.value.totalPrice += mapRequestPrice.value;
        }
    };

    const hasOnlinePaymentError = (value: boolean) => {
        onlinePaymentError.value = value;
    }

    const isTopUpPayment = computed<boolean>(() => {
        return onlinePayment.value !== null && onlinePayment.value.paymentType === PaymentType.Topup;
    });

    const isOnlinePayment = computed<boolean>(() => {
        return onlinePayment.value !== null && onlinePayment.value.paymentType === PaymentType.Online;
    });

    const hasTopUpPaymentError = (value: boolean) => {
        onlinePaymentError.value = value;
    };

    const hasUncertainPrepaidError = (value: boolean) => {
        unCertainTopUpPaymentError.value = value;
    };

    return {
        onlinePayment,
        onlinePaymentError,
        topUpPaymentError,
        isTopUpPayment,
        isOnlinePayment,
        setOnlinePayment,
        hasOnlinePaymentError,
        hasTopUpPaymentError,
        hasUncertainPrepaidError
    };
});