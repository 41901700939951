
import { Component, Vue } from 'vue-property-decorator';
import { mixin as focusMixin } from 'vue-focus';
import { UserData } from '@/app/shared/state/UserDataModule';
import { CodeLists } from '@/app/shared/state/CodeListModule';
import { mriInvoices } from '@/app/mri/mri-routes';
import { settings } from '@/app/settings/settings-routes';
import { klipIndex } from '@/router/router';
import { Prepaid } from '@/app/prepaid/prepaid-routes';
import { PaymentTypes, LogEventLevel, LogImpact, LogEntry } from '@/api/klip-api.proxy';

@Component({
    mixins: [focusMixin],
})
export default class KlProfileDropdown extends Vue {
    public isOpen: boolean = false;
    public toggleFocus: boolean = false;
    public prepaidSaldo: number = null;

    get user() {
        return this.userData.user;
    }

    get organisationName() {
        let name = this.user.organisation;
        if (this.userData.hasRole(['una_manager', 'una_operator', 'una_reader']) &&
            this.user.displayName
        ) {
            name = this.user.displayName;
        }
        return name;
    }

    get userData() {
        return UserData;
    }

    get routeMriInvoices() {
        return mriInvoices;
    }

    get routeSettings() {
        return settings;
    }

    get routeKlipIndex() {
        return klipIndex;
    }

    get routePrepaid() {
        return Prepaid;
    }

    get acmAuthentication(): boolean {
        return import.meta.env.VITE_ACM_AUTHENTICATION === 'true';
    }


    get userScope() {
        return this.userData.scopes.find((scope) => this.userData.scope === scope.key);
    }

    get userHasMultipleRoles() {
        return Object.keys(this.userData.scopes).length > 1;
    }

    get showRolepicker() {
        return this.userHasMultipleRoles;
    }

    get rolePickerItems() {
        return this.user.roles.map((role) => {
            return {
                key: role,
                label: CodeLists.codeLists ? CodeLists.codeLists.userRoleCodes[role] : role,
            };
        });
    }

    get showSettings() {
        //hide settings if the user has only the map request reader role.
        if (this.user.roles.length > 1) {
            return true;
        }
        return !this.userData.hasRole(['mrr']);
    }

    get applyMinWidth() {
        return !this.showRolepicker;
    }

    get showBalance() {
        return UserData.user.paymentType !== PaymentTypes.Invoice && !UserData.user.isCitizen && UserData.scope === 'mri' && this.userData.hasRole(['mri']);
    }

    get hasPrepaidSaldo() {
        return this.prepaidSaldo != null;
    }

    public close() {
        this.isOpen = false;
    }

    public toggle() {
        this.isOpen = !this.isOpen;
        if (this.isOpen && this.showBalance) {
            this.$client.payment_GetPrepaidAmount()
                .then((response) => {
                    this.prepaidSaldo = response.result;
                });
        }
    }

    public async switchScope(scope: string) {
        this.userData.setScope(scope);
        this.close();
        await this.$router.push('/' + scope);
    }

    public logout() {
        this.$signalrHub.stop(`${window.location.origin}/geosecure_auth/logout`);
    }
}
