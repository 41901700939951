import { KlRouteConfig } from '@/router/router-vue';
import { klipIndex } from '@/router/router';

export const pdaIndex: KlRouteConfig = {
    path: '/pda',
    name: 'pda',
    component: () => import(/* webpackChunkName: "pdazone" */ './index.vue'),
    meta: {
        authorize: { roles: ['pda_manager'] },
        breadcrumb: { title: '', parent: klipIndex },
    },
};

export const pdaZoneCreate: KlRouteConfig = {
    path: '/pda/zone/create',
    name: 'pdazonecreate',
    component: () => import(/* webpackChunkName: "pdazone" */ './zone-create_edit.vue'),
    meta: {
        authorize: { roles: ['pda_manager'] },
        breadcrumb: { title: 'Nieuwe zone', parent: pdaIndex },
    },
};

export const pdaZoneId: KlRouteConfig = {
    path: '/pda/zone/:zoneId',
    name: 'pdazone',
    component: () => import(/* webpackChunkName: "pdazone" */ './zone.vue'),
    meta: {
        authorize: { roles: ['pda_manager'] },
        breadcrumb: { title: 'Zone detail', parent: pdaIndex },
    },
};

export const pdaMapRequest: KlRouteConfig = {
    path: '/pda/zone/:zoneId/maprequest/:id',
    name: 'pdamaprequest',
    component: () => import(/* webpackChunkName: "pdazone" */ './map-request.vue'),
    meta: {
        authorize: { roles: ['pda_manager'] },
        breadcrumb: { title: 'Planaanvraag', parent: pdaZoneId },
    },
};

export const pdaZoneEdit: KlRouteConfig = {
    path: '/pda/zone/:id/edit',
    props: true,
    name: 'pdazoneedit',
    component: () => import(/* webpackChunkName: "pdazone" */ './zone-create_edit.vue'),
    meta: {
        authorize: { roles: ['pda_manager'] },
        breadcrumb: { title: 'Bewerk zone', parent: pdaZoneId },
    },
};

export const pdaZoneOverview: KlRouteConfig = {
    path: '/pdazoneoverview',
    redirect: pdaIndex,
};

export const newPdaZone: KlRouteConfig = {
    path: '/newpdazone',
    redirect: pdaZoneCreate,
};

export default [pdaIndex, pdaZoneCreate, pdaZoneId, pdaZoneEdit, pdaZoneOverview, pdaMapRequest, newPdaZone];
