import { UserData } from '../shared/state/UserDataModule';
import { ErrorModule } from '../shared/state/ErrorModule';
import {computed, defineComponent, onMounted, ref} from 'vue';
import {useSignalrHub} from '@/plugins/signalr';
import {useRoute} from '@/plugins/routes';

export default defineComponent({
    name: 'Error',
    props: {},
    setup(props) {

        const isError403 = ref<boolean>(false);
        const isError400 = ref<boolean>(false);

        const correlationId = computed(() => {
            return ErrorModule.getCorrelationId;
        });

        const validationError = computed(() => {
            return UserData?.user?.validationError;
        });

        const username = computed((): string => {
            return UserData.fullName + (UserData.user.organisation ? ' - ' + UserData.user.organisation : '');
        });

        const acmAuthentication = computed((): boolean => {
            return import.meta.env.VITE_ACM_AUTHENTICATION === 'true';
        });

        const logout = () => {
            if (acmAuthentication.value) {
                useSignalrHub().stop(`${window.location.origin}/auth/logout`);
            } else {
                useSignalrHub().stop(`${window.location.origin}/geosecure_auth/logout`);
            }

        }

        const copyToClipBoard = () => {
            navigator.clipboard.writeText(ErrorModule.getCorrelationId);
        }

        onMounted(() => {
            const errorCode = useRoute().path.substring(1);
            isError403.value = errorCode === '403';
            isError400.value = errorCode === '400';
        });

        return {
            isError403,
            isError400,
            correlationId,
            validationError,
            username,
            acmAuthentication,
            logout,
            copyToClipBoard,
        }
    }
})
