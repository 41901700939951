import { computed, defineComponent, onMounted, ref, watch } from "vue";
import * as KlipApi from "@/api/klip-api.proxy";
import { useRoute } from "@/plugins/routes";
import QueryFilter from "../../helpers/queryFilter";
import { escape } from 'html-escaper';
import { KlRouteConfig } from "@/router/router-vue";

export default defineComponent({
    name: 'KlPdaZoneList',
    props: {
        zones: {
            type: Array<KlipApi.ZoneListItem>,
            default: [],
            required: true
        },
        fetching: {
            type: Boolean,
            default: false,
            required: true
        },
        pdaZoneDetailRoute: {
            type: Object as () => KlRouteConfig,
            default: null,
            required: true
        },
        organisationId: {
            type: String,
            default: null,
            required: false
        }
    },
    setup(props) {
        const meta = ref<{ totalRows: number, resultsPerPage: number }>({ totalRows: 0, resultsPerPage: 50 });
        const queryFilter = new QueryFilter();
        const route = useRoute();
        const pdaZones = ref<KlipApi.IZoneListItem[]>([]);

        const pdaOrder: any = { 1: 'name', name: 1 };

        const columnClicked = (column: KlipApi.IColumn) => {
            queryFilter.mapQuery(route, {
                orderDirection: column.direction,
                orderField: pdaOrder[column.key]
            })
        };

        const routePdaZoneId = computed((): KlRouteConfig => {
            return props.pdaZoneDetailRoute;
        });

        const parseZoneName = (row: IRow, column: IColumn) => {
            if (row.id) {
                const zoneName = escape(row.name);
                const routeParams = {
                    zoneId: row.id,
                    zoneName: escape(zoneName),
                    organisationId: props.organisationId
                }
                return {
                    template: `<kl-router-link :to='${JSON.stringify(routePdaZoneId.value)}' :params='${JSON.stringify(routeParams)}' >${zoneName}</kl-router-link>`,
                };
            } else {
                return { template: `<div>${row.name}</div>` };
            }
        };

        const columns = ref<IColumn[]>([
            {
                key: 'name',
                type: 'String',
                label: 'Zone',
                parser: parseZoneName,
                sortable: true,
                direction: 1,
                isVisible: true,
            }
        ]);

        const sortZones = () => {
            pdaZones.value = props.zones;
            if (columns.value[0].direction === 2) {

                pdaZones.value = pdaZones.value.reverse()
            }
        }

        onMounted(() => {
            queryFilter.mapColumnsFromQuery(route, columns.value, pdaOrder)
        });

        watch(() => props.zones, sortZones, { immediate: true });

        return {
            columnClicked,
            meta,
            columns,
            pdaZones
        };
    }
});
