import { computed, defineComponent, getCurrentInstance, inject, ref, watch } from 'vue';
import * as KlipApi from "@/api/klip-api.proxy";
import { escape } from "html-escaper";
import { ActorDto } from "@/api/klip-api.proxy";

export default defineComponent({
    props: {
        organisation: {
            type: KlipApi.OrganisationDetail,
            default: null,
            required: true
        }
    },
    emits: ['saved'],
    setup(props, { emit }) {
        const root = getCurrentInstance().proxy;
        const klipApiProxy = inject<KlipApi.IKlipApiProxy>('klipApiProxy');

        const uuidValidationRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
        const isSending = ref<boolean>(false);
        const fetchingActor = ref<boolean>(false);
        const athumiIdModal = "athumi-id";
        const newAthumiId = ref<string>(null);
        const athumiValidKlipDataConsumer = ref<boolean>(true);
        const athumiExistingActor = ref<boolean>(true);
        const organisationDetail = ref<KlipApi.OrganisationDetail>(null);
        const actor = ref<ActorDto>(null);
        
        const organisationChanged = (value: KlipApi.OrganisationDetail) => {
            organisationDetail.value = value;
        };

        const actorInvalid = computed((): boolean => {
            return !athumiExistingActor.value || !athumiValidKlipDataConsumer.value;
        });

        const athumiOrganisation = computed((): string => {
            return `${actor.value.name} - ${actor.value.kboNumber}`;
        });

        const athumiOrganisationVat = computed((): string => {
            return actor.value.vatNumber;
        });

        const athumiOrganisationContactAddress = computed((): string => {
            const address = actor.value.invoiceAddress;
            const street = `${address.street ? escape(address.street) : ''}${address.street && address.streetNumber ? ` ${escape(address.streetNumber)}` : ''}${address.bus ? ` ${escape(address.bus)}` : ''}<br>`;
            const city = `${address.postCode && address.city ? `${escape(address.postCode)} ` : ''}${address.city ? escape(address.city) : ''}`;
            return `${street}${city}`;
        });

        const saveAthumiId = async () => {
            isSending.value = true;

            const input = new KlipApi.UpdateOrganisationAthumiIdInput();
            input.organisationId = organisationDetail.value.organisationId;
            input.athumiId = newAthumiId.value;

            await klipApiProxy.operations_UpdateOrganisationAthumiId(input);

            isSending.value = false;
            emit('saved');
            root.$vnode.context.$root.$emit('modal-toggle', athumiIdModal);
        }

        const fetchActor = async () => {
            if (!uuidValidationRegex.test(newAthumiId.value)) {
                return;
            }

            fetchingActor.value = true;
            const actorDtoEnvelope = await klipApiProxy.operations_GetActorByAthumiId(newAthumiId.value);
            const isValidResult = !!(actorDtoEnvelope && actorDtoEnvelope.result);
            athumiExistingActor.value = isValidResult;

            if (isValidResult) {
                actor.value = actorDtoEnvelope.result;
                athumiValidKlipDataConsumer.value = actorDtoEnvelope && actorDtoEnvelope.result ? actorDtoEnvelope.result.isKlipConsumer : false;
            }
            fetchingActor.value = false;
        };

        watch(props.organisation, organisationChanged, { immediate: true, deep: true });

        return {
            uuidValidationRegex,
            isSending,
            fetchingActor,
            athumiIdModal,
            newAthumiId,
            athumiValidKlipDataConsumer,
            athumiExistingActor,
            athumiOrganisation,
            athumiOrganisationVat,
            athumiOrganisationContactAddress,
            actorInvalid,
            saveAthumiId,
            fetchActor,
            actor,
        }
    }
});
