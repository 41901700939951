import { UserData } from '@/app/shared/state/UserDataModule';
import { ISettingsView, UpdateMriMailSettingsInputData, UpdateMailSettingsInput } from '@/api/klip-api.proxy';
import {computed, defineComponent, ref, watch} from 'vue';
import {useKlipApiProxy} from '@/plugins/proxy-client';

export default defineComponent({
    name: 'KlContactSettings',
    props: {
        settings: {
            type: Object as () => ISettingsView,
            default: undefined,
        }
    },
    setup(props) {

        const userData = UserData;

        const isSending = ref<boolean>(false);
        const hasChanged = ref<boolean>(false);

        const mriSettings = computed(() => {
            return props.settings ? props.settings.mriSettings : null;
        });

        const showMriSettings = computed(() => {
            return !!(userData.hasRole(['mri', 'klim_admin']) && mriSettings.value);
        });

        const submit = () => {
            const settings = {
                updateMriMailSettingsInput: null,
                updateUnaMailSettingsInput: null,
            } as UpdateMailSettingsInput;
            if (mriSettings.value) {
                settings.updateMriMailSettingsInput = {
                    confirmation: mriSettings.value.mailConfirmation,
                    incompletePackageAvailable: mriSettings.value.mailIncompletePackageAvailable,
                    soonToBeArchived: mriSettings.value.mailSoonToBeArchived
                } as UpdateMriMailSettingsInputData;
            }
            if (settings) {
                isSending.value = true;
                return useKlipApiProxy().settings_UpdateEmailSettings(settings)
                    .then(() => {
                        hasChanged.value = false;
                    })
                    .finally(() => {
                        isSending.value = false;
                    });
            }
        }

        watch(
            () => props.settings,
            (newSettings: ISettingsView, oldSettings: ISettingsView) => {
                if (newSettings && oldSettings) {
                    hasChanged.value = true;
                }
            },
            { immediate: false, deep: true });

        return {
            isSending,
            hasChanged,
            mriSettings,
            showMriSettings,
            submit,
        }
    }
})
