import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { KlRouteConfig } from '@/router/router-vue';
import { klipIndex } from '@/router/router';
import { CodeLists } from '../../state/CodeListModule';

interface IBreadCrumb {
    label: string;
    route: KlRouteConfig;
}

@Component
export default class KlBreadcrumb extends Vue {
    // strange display problem when we start with an empty array ([]). ref: bug #180590
    public breadcrumbs: IBreadCrumb[] = [{
        label: '',
        route: null,
    }];

    get isBeta() {
        return CodeLists.isBeta;
    }

    @Watch('$route.meta.breadcrumb', { immediate: true, deep: true })
    public breadcrumbsChanged() {
        if (this.$route.meta && this.$route.meta.breadcrumb) {
            const breadcrumbs: IBreadCrumb[] = [];

            if (this.$route.meta.breadcrumb.title || this.$route.meta.title) {
                breadcrumbs.push({
                    route: null, label: this.$route.meta.breadcrumb.title ||
                        this.$route.meta.title,
                });
            }

            let parent = this.$route.meta.breadcrumb.parent;
            let breakClause = false;
            while (parent && !breakClause) {
                const parentRoute = this.$router.resolve(parent);
                if (
                    parentRoute.href !== '/'
                    && parentRoute.resolved.meta
                    && parentRoute.resolved.meta.breadcrumb
                    && (parentRoute.resolved.meta.breadcrumb.title || parentRoute.resolved.meta.title)
                ) {
                    breadcrumbs.push({
                        route: parentRoute.resolved,
                        label: parentRoute.resolved.meta.breadcrumb.title || parentRoute.resolved.meta.title,
                    });
                    parent = parentRoute.route.meta.breadcrumb.parent;
                } else {
                    breakClause = true;
                    const klipTitle = this.isBeta ? 'KLIP BETA' : 'KLIP';
                    breadcrumbs.push({ route: klipIndex, label: klipTitle });
                }
            }

            const pageTitle = breadcrumbs.map((b) => b.label).join(' | ');
            this.breadcrumbs = breadcrumbs.reverse();

            document.title = `${pageTitle} - Kabel- en Leidinginformatieportaal | Digitaal Vlaanderen`;
            this.$set(this.breadcrumbs, 'breadcrumbs', breadcrumbs.reverse);
        }
    }

    to(breadcrumb: IBreadCrumb) {
        return this.routeName(breadcrumb) ? {
            name: this.routeName(breadcrumb),
            hash: breadcrumb.route && breadcrumb.route.hash ? breadcrumb.route.hash : '',
        } : null;
    }

    routeName(breadcrumb: IBreadCrumb) {
        return (breadcrumb.route ? breadcrumb.route.name : '');
    }
}
