import { defineComponent, ref, getCurrentInstance, inject } from "vue";
import * as KlipApi from "@/api/klip-api.proxy";
import {ISelectedOrganisation} from "@/app/admin/components/kl-search-organisation/kl-search-organisation";

export interface IZoneToMove {
    zoneId: string;
    destinationKlipOrganisationId: string;
    reason: string;
    mailTo: string[];
}

export default defineComponent({
    emits: ['move'],
    props: {
        zoneId: {
            type: String,
            required: true
        },
        sourceKlipOrganisationId:  {
            type: String,
            required: true
        }
    },
    setup(props, { emit }) {
        const proxy = inject<KlipApi.IKlipApiProxy>('klipApiProxy');
        
        const root = getCurrentInstance().proxy;
        const movingZone = ref<boolean>(false);
        const selectedDestinationOrganisation = ref<string>('');
        const reason = ref<string>('');
        const mailTo = ref<string>('');
        const hasDestinationOrganisation = ref<boolean>(false);
        const destinationOrganisation = ref<ISelectedOrganisation>(null);
        
        const onCloseMoveUnaZone = () => {
            destinationOrganisation.value = null;
            hasDestinationOrganisation.value = false;
            reason.value = '';
            mailTo.value = '';
            selectedDestinationOrganisation.value = '';
            root.$vnode.context.$root.$emit('modal-toggle', 'una-zone-move-modal');
        };
        
        const move = () => {
            emit('move', {
                zoneId: props.zoneId,
                destinationKlipOrganisationId: destinationOrganisation.value.organisationId,
                reason: reason.value,
                mailTo: mailTo.value.split(";").map(m => m.trim())
            } as IZoneToMove)
            
            onCloseMoveUnaZone();
        }

        const onOrganisationSelect = (selectedOrganisation: ISelectedOrganisation) => {
            if (!selectedOrganisation || !selectedOrganisation.organisationId) return;
            
            hasDestinationOrganisation.value = !!selectedOrganisation;
            destinationOrganisation.value = selectedOrganisation;
            selectedDestinationOrganisation.value = selectedOrganisation.name

            // get the una emailaddresses
            const request = new KlipApi.GetUnaManagerEmailAddressesQuery();
            request.klipOrganisationIds = [props.sourceKlipOrganisationId, selectedOrganisation.organisationId];
            proxy.unaSettings_GetUnaManagerEmailAddresses(request).then((r) => {
                mailTo.value = r.result.join("; ");
            });
        };

        return {
            reason,
            mailTo,
            movingZone,
            destinationOrganisation,
            hasDestinationOrganisation,
            selectedDestinationOrganisation,
            move,
            onOrganisationSelect,
            onCloseMoveUnaZone
        };
    }
});
