
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  inheritAttrs: false,
})
export default class KlAutocomplete extends Vue {
  public results: AutoCompleteResult[] = [];
  public resultsVisible: boolean = true;

  @Prop({ type: Array, required: true })
  public data: AutoCompleteResult[];

  @Prop({ type: Boolean, default: false })
  public fetching: boolean;

  @Prop({ type: String, required: false, default: 'Gebruik de pijltjes (boven en onder) om, na het invoeren van X karakters, door de suggesties van de suggestiebox te navigeren. Bevestig uw keuze met &quot;enter&quot; of gebruik de &quot;escape&quot; knop om te suggestiebox te sluiten.' })
  public helpText: string;

  @Prop({ type: Boolean, default: false, required: false })
  public modRequired: string;

  @Prop({ type: String, required: false, default: 'off' })
  public autocompleteInputField: string;

  @Watch('data', { immediate: true, deep: true })
  public onFetchingChanged() {
    this.results = this.data.map((obj) => ({ ...obj, selected: false }));
  }

  @Watch('results', { immediate: true, deep: true })
  public onResultsChanged() {
    if (this.hasResults) {
      this.showResults();
    }
  }

  get hasResults() {
    return this.results.length;
  }

  public reset() {
    this.results = [];
  }

  public hideResults() {
    this.resultsVisible = false;
  }

  public showResults() {
    this.resultsVisible = true;
  }

  public navigateResults(direction: string) {
    if (this.results.length) {
      const currentResult = this.results.findIndex((result) => result.selected);
      const newResult = direction === 'down' ? currentResult === (this.results.length - 1) ? 0 : currentResult + 1 :
        !currentResult || currentResult === 0 ? (this.results.length - 1) : currentResult - 1;
      this.results = this.results.map((obj) => ({ ...obj, selected: false }));
      this.results[newResult].selected = true;
    }
  }

  public arrowDown() {
    if (this.results && !this.resultsVisible) {
      this.resultsVisible = true;
    } else {
      this.navigateResults('down');
    }
  }

  public arrowUp() {
    this.navigateResults('up');
  }

  public selectFocussedResult() {
    const focussedResult = this.results.filter((obj) => obj.selected);
    if (this.results && this.resultsVisible && focussedResult[0]) {
      this.select(focussedResult[0]);
      event.preventDefault();
    }
  }

  public IsAutoCompleteResult(result: AutoCompleteResult): result is AutoCompleteResult {
    return result.value !== undefined;
  }

  public select(item: AutoCompleteResult) {
    if (this.IsAutoCompleteResult(item)) {
      this.$emit('select', item);
      this.reset();
    }
  }
}
